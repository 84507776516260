/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { EditPractice } from "./EditPractice";
import { Alert } from "react-bootstrap";
import labels from "../../../../Static/Dictionary.json";
import moment from "moment";
import closeIcon from "../images/close-icon.svg";
import updatedIcon from "../images/updatedIcon.svg";
import cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useFetch } from "Components/Hooks/Fetch";

const EditPracticeInfo = ({ selectedPracticeIdByAdmin }) => {

  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const decodeToken1 = jwt_decode(token);

  const [editMode, setEditMode] = useState(false);

  const [success, setSuccess] = useState(false);
  const [careManagerData, setCareManagerData] = useState([]);
  const [practiceInformationData, setPracticeInformationData] = useState({});

  const [updatedPracticeInfo, setUpdatedPracticeInfo] = useState({});

  const { data: markets } = useFetch(
    "/api/v1/contact/GetRSOData"
  );

  const { data: GetAllSuperGroups } = useFetch(
    "/api/v1/practices/GetAllSuperGroups"
  );

  
  const GetPracticeInfo = () => {
    fetch(base_url + `/api/v1/practices/${selectedPracticeIdByAdmin}`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {

setPracticeInformationData(data);
      });
  };

  const GetCareManagers = () => {
    fetch(
      base_url +
        `/api/v1/practices/GetCareManagerforPractice?practiceId=${selectedPracticeIdByAdmin}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCareManagerData(data);
        GetPracticeInfo();
      });
  };

  // useEffect(() => {
  // }, [careManagerData]);


  const getSuperGroupCode=(supergroupId)=>{
    if(GetAllSuperGroups?.length > 0 && supergroupId){
    let supergroup = GetAllSuperGroups.filter((item) => {
      return item.id == supergroupId;
    });
    if(supergroup?.length > 0){
      return supergroup[0].supergroupCode;
    }
  }
  }

  useEffect(() => {
      GetCareManagers();
   
  }, []);


  useEffect(() => {

    if(success){
    GetCareManagers();
    }
  }, [success]);

  const getRegionNameById = (id) => {
    if (markets?.length > 0 && id) {
      let region = markets.filter((item) => {
        return item.id == id;
      });
      if (region?.length > 0) {
        return region[0].rso;
      }
      return "";
    }
    return "";
  };

  const setSpecialtyDropdown = (specialtyId) => {
    if (
      practiceInformationData?.specialtySpecialtyType &&
      practiceInformationData?.specialtySpecialtyType.length > 0
    ) {
      const specialty = practiceInformationData.specialtySpecialtyType.filter(
        (item) => item.specialtyTypeId === specialtyId
      );
      if (specialty.length > 0) {
        return specialty[specialty.length - 1]?.specialty?.specialtyName;
      }
    }
    return "";
  };

  let initialValues = {

    ...practiceInformationData,
    id: selectedPracticeIdByAdmin,
    practiceGroupId: practiceInformationData?.practiceGroupId || "",
    emRid: practiceInformationData?.emRid || "",
    practiceNameLegal: practiceInformationData?.practiceNameLegal || "",
    reportingGroupId: practiceInformationData?.reportingGroupId || "",
    primaryNpi: practiceInformationData?.primaryNpi || "",
    secondaryNpi: practiceInformationData?.secondaryNpi || "",
    isSqcn: Boolean(practiceInformationData?.isSqcn) || false,
    isPrimaryCare:
    Boolean(practiceInformationData?.isPrimaryCare),
    isAco: Boolean(practiceInformationData?.isAco)||false,
    isAffiliated:
    Boolean(practiceInformationData?.isAffiliated)||false,
    practiceManagementSystem:
      practiceInformationData?.practiceManagementSystem || "",
    claimsClearingHouse: practiceInformationData?.claimsClearingHouse || "",
    takingNewPatients:
    Boolean(practiceInformationData?.takingNewPatients),
    takingMedicaidPatients:
    Boolean(practiceInformationData?.takingMedicaidPatients),
    lowerAgeLimit: practiceInformationData?.lowerAgeLimit || "",
    directMessageAddress: practiceInformationData?.directMessageAddress || "",
    telehealth: practiceInformationData?.telehealth || "",
    telehealthOption: {label:practiceInformationData?.telehealth || "", value:practiceInformationData?.telehealth},
    hasEpicCareLinkAccess:
    Boolean(practiceInformationData?.hasEpicCareLinkAccess),
    uses837: Boolean(practiceInformationData?.uses837),
    adt: Boolean(practiceInformationData?.adt),
    ccda: Boolean(practiceInformationData?.ccda),
    oru: Boolean(practiceInformationData?.oru),
    additionalCommentsForAudits:
      practiceInformationData?.additionalCommentsForAudits || "",
    payments: practiceInformationData?.payments || "",
    emr2015cert: practiceInformationData?.emr2015cert || "",
    tin: practiceInformationData?.practiceGroup?.tin || "",
    tinLocal: practiceInformationData?.practiceGroup?.tin || "",
    tinOption: {label:practiceInformationData?.practiceGroup?.tin || "", value:practiceInformationData?.practiceGroup?.tin},
    emr: practiceInformationData?.emr?.emr || "",
    emrLocal:  practiceInformationData?.emr?.emr || "",

    emrOption: {label:practiceInformationData?.emr?.emr, value:practiceInformationData?.emr?.emr},
    startDate: practiceInformationData?.startDate && new Date(practiceInformationData?.startDate) || "",
    termDate: practiceInformationData?.termDate && new Date(practiceInformationData?.termDate) || "",
    aetnaBROnly:
    Boolean(practiceInformationData?.aetnaBROnly),
    johnHopkinsUSFamilyHealthPlan:
    Boolean(practiceInformationData?.johnHopkinsUSFamilyHealthPlan),
    optimaCommercial:
    Boolean(practiceInformationData?.optimaCommercial),
    optimaMedicaid:
    Boolean(practiceInformationData?.optimaMedicaid),
    humanaMA:
    Boolean(practiceInformationData?.humanaMA),
    optimaMedicareAdvantage:
    Boolean(practiceInformationData?.optimaMedicareAdvantage),
    specialty: setSpecialtyDropdown(3),
    specialty1: setSpecialtyDropdown(4),
    specialty2: setSpecialtyDropdown(5),
    specialty3: setSpecialtyDropdown(6),
    rsoId: practiceInformationData?.rsoId,
    supergroup:
      getSuperGroupCode(practiceInformationData?.practiceGroup?.supergroupId) ||
      "",
    supergroupId: practiceInformationData?.practiceGroup?.supergroupId || "",
    supergroupName: getSuperGroupCode(practiceInformationData?.practiceGroup?.supergroupId) || "",
    location1:
      practiceInformationData?.location?.length > 0
        ? practiceInformationData?.location[0]?.locationName
        : "",
    InnovaccerDesignatedName:
      practiceInformationData?.practiceGroup?.innovaccerTinname || "",
    notesRegardingTermDates: practiceInformationData?.notesRegardingTermDates,
    practiceNameBeforeEditing:
      practiceInformationData?.practiceNameBeforeEditing,
    careManagers: careManagerData,
    rsoName: getRegionNameById(practiceInformationData?.rsoId) || "",
  };

  useEffect(() => {
    if (careManagerData) {
      setPracticeInformationData({...practiceInformationData,...initialValues,...{careManagers:careManagerData}});
    }
  }, [careManagerData]);

  const handleEditClick = () => {
    setEditMode(true);
    setSuccess(false);
  };

  // let updatedPracticeInfo = { ...initialValues, ...practiceInformationData };

  useEffect(() => {
    if (practiceInformationData) {
      setUpdatedPracticeInfo({ ...initialValues, ...practiceInformationData });
    }
  }, [practiceInformationData]);




  console.log("-------------practiceInformationData------------------",practiceInformationData);
  return (
    <>
      {success && (
        <div>
          <Alert variant="success" data-testid="successProvider">
            <img
              src={updatedIcon}
              alt="Updated Icon"
              className="updated-icon"
            />
            Practice information updated successfully
            <button
              onClick={() => setSuccess(false)}
              className="close-icon-button"
              data-testid="providerProfileupdatedclose"
            >
              <img
                src={closeIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
            </button>
          </Alert>
        </div>
      )}
      <React.Fragment>
        {editMode ? (
          <div>
            <EditPractice
              selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
              initialValues={initialValues}
              practiceInformationData={practiceInformationData}
              setEditMode={setEditMode}
              setSuccess={setSuccess}
              careManagers={careManagerData}
              setPracticeInformationData={setPracticeInformationData}
              updateUser={decodeToken1.id}
            />
          </div>
        ) : (
          <div>
            <div>
              <Row className="mb-4">
                <Col md={10}></Col>
                <Col md={2}>
                  <button
                    className="edit-contact-button"
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                </Col>
              </Row>
              <div>
                <div className="main-block">
                  <div className="block">
                    <div className="title" data-testid="title">
                      {labels.Practice_ID}
                    </div>
                    {updatedPracticeInfo?.id ? (
                      <div className="info" data-testid="NameData">
                        {updatedPracticeInfo?.id}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoNameData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Practice_Reporting_ID} </div>
                    {practiceInformationData?.reportingGroupId ? (
                      <div className="info" data-testid="TinData">
                        {practiceInformationData.reportingGroupId}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoTinData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Start_Date}</div>
                    {updatedPracticeInfo?.startDate ? (
                      <div className="info" data-testid="NpiData">
                        {moment(updatedPracticeInfo.startDate).format(
                          "MM-DD-YYYY"
                        )}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoNpiData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Term_Date}</div>
                    {updatedPracticeInfo?.termDate ? (
                      <div className="info" data-testid="termDate">
                        {moment(updatedPracticeInfo.termDate).format(
                          "MM-DD-YYYY"
                        )}
                      </div>
                    ) : (
                      <div className="info" data-testid="termDate">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.SQCN}</div>

                    <div className="info" data-testid="EmrData">
                      {updatedPracticeInfo.isSqcn === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.ACO}</div>

                    <div className="info" data-testid="TelehealthData">
                      {updatedPracticeInfo.isAco === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Affiliated} </div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo.isAffiliated === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Market_Region} </div>
                    {updatedPracticeInfo.rsoName ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.rsoName}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Supergroup}</div>
                    {updatedPracticeInfo.supergroup ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.supergroup}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.TIN}</div>
                    {updatedPracticeInfo?.tinLocal ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.tinLocal}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">
                      {labels.Innovaccer_Designated_Name}
                    </div>
                    {updatedPracticeInfo?.InnovaccerDesignatedName ? (
                      <div
                        className="info"
                        data-testid="InnovaccerDesignatedName"
                      >
                        {updatedPracticeInfo?.InnovaccerDesignatedName}
                      </div>
                    ) : (
                      <div
                        className="info"
                        data-testid="InnovaccerDesignatedName"
                      >
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Practice_Name_Legal}</div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo?.practiceNameLegal}
                    </div>
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Name_Location1}
                    </div>
                    {updatedPracticeInfo?.location1 ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo.location1}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Primary_NPI}</div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo?.primaryNpi}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Secondary_NPI}</div>

                    <div className="info" data-testid="secondaryNpi">
                      {updatedPracticeInfo?.secondaryNpi}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.EMR_Vendor}</div>
                    {updatedPracticeInfo?.emrLocal ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.emrLocal}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.TeleHealth}</div>
                    {updatedPracticeInfo?.telehealth ? (
                      <div className="info" data-testid="TeleHealth">
                        {updatedPracticeInfo?.telehealth}
                      </div>
                    ) : (
                      <div className="info" data-testid="TeleHealth">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Management_System}
                    </div>
                    {updatedPracticeInfo?.practiceManagementSystem ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.practiceManagementSystem}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Practice_Specialty}</div>
                    {updatedPracticeInfo?.specialty ? (
                      <div className="info" data-testid="secondaryNpi">
                        {updatedPracticeInfo?.specialty}
                      </div>
                    ) : (
                      <div className="info" data-testid="secondaryNpi">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty1}
                    </div>
                    {updatedPracticeInfo?.specialty1 ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.specialty1}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty2}
                    </div>
                    {updatedPracticeInfo?.specialty2 ? (
                      <div className="info" data-testid="specialty2">
                        {updatedPracticeInfo.specialty2}
                      </div>
                    ) : (
                      <div className="info" data-testid="specialty2">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty3}
                    </div>
                    {updatedPracticeInfo?.specialty3 ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.specialty3}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Primary_Care}</div>

                    <div className="info" data-testid="secondaryNpi">
                      {updatedPracticeInfo?.isPrimaryCare === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Previous_Practice_Name}</div>
                    {updatedPracticeInfo?.previousPracticeName ? (
                      <div className="info" data-testid="previousPracticeName">
                        {updatedPracticeInfo?.previousPracticeName}
                      </div>
                    ) : (
                      <div className="info" data-testid="previousPracticeName">
                        --
                      </div>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Accepting_New_Patients}</div>

                    <div className="info" data-testid="takingNewPatients">
                      {updatedPracticeInfo?.takingNewPatients === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Accepting_New_Medicaid_Patients}
                    </div>
                    
                      <div className="info" data-testid="SpecialtyData">
                  
                        {updatedPracticeInfo?.takingMedicaidPatients === true
                        ? "Yes"
                        : "No"}
                      </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Lower_Age_Limit}</div>
                    {updatedPracticeInfo?.lowerAgeLimit ? (
                      <div className="info" data-testid="lowerAgeLimit">
                        {updatedPracticeInfo?.lowerAgeLimit}
                      </div>
                    ) : (
                      <div className="info" data-testid="lowerAgeLimit">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Payments}
                    </div>
                    {practiceInformationData?.payments ? (
                      <div className="info" data-testid="payments">
                        {updatedPracticeInfo?.payments}
                      </div>
                    ) : (
                      <div className="info" data-testid="takingNewPatients">
                        --
                      </div>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Additional_Comments_For_Audits}
                    </div>
                    {updatedPracticeInfo?.additionalCommentsForAudits ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.additionalCommentsForAudits}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Term_Dates}
                    </div>
                    {updatedPracticeInfo?.notesRegardingTermDates ? (
                      <div
                        className="info"
                        data-testid="notesRegardingTermDates"
                      >
                        {updatedPracticeInfo?.notesRegardingTermDates}
                      </div>
                    ) : (
                      <div
                        className="info"
                        data-testid="notesRegardingTermDates"
                      >
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Claims_Clearing_House}</div>
                    {updatedPracticeInfo?.claimsClearingHouse ? (
                      <div className="info" data-testid="claimsClearingHouse">
                        {updatedPracticeInfo?.claimsClearingHouse}
                      </div>
                    ) : (
                      <div className="info" data-testid="claimsClearingHouse">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Care_Manager_Name}</div>

                    {updatedPracticeInfo?.careManagers?.length > 0 ? (
                      <>
                        <p className="info" data-testid="CareManagerName">
                          {" "}
                          {updatedPracticeInfo?.careManagers
                            .map((item) => {
                              return `${item.firstName} ${item.lastName}`;
                            })
                            .join()}
                        </p>
                      </>
                    ) : (
                      <p className="info" data-testid="NoCareManagerName">
                        --
                      </p>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels._837}</div>

                    <div className="info" data-testid="claimsClearingHouse">
                      {updatedPracticeInfo?.uses837 === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.CCDA}</div>

                    <div className="info" data-testid="ccda">
                      {updatedPracticeInfo?.ccda === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block"/>
                  <div className="block"/>
                  <div className="block">
                    </div>
                    </div>

                  <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Aetna_BR_Only}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.aetnaBROnly === true ? "Yes" : "No"}
                    </div>
                    </div>
                 

                  <div className="block">
                    <div className="title">{labels.Humana_MA}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.humanaMA === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.John_Hopkins_US}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.johnHopkinsUSFamilyHealthPlan ===
                      true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Optima_Commercial}</div>

                    <div className="info" data-testid="claimsClearingHouse">
                      {updatedPracticeInfo?.optimaCommercial
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Optima_Medicaid}</div>

                    <div className="info" data-testid="optimaMedicaid">
                      {updatedPracticeInfo?.optimaMedicaid
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Optima_MA}</div>

                    <div className="info" data-testid="optimaMedicareAdvantage">
                      {updatedPracticeInfo?.optimaMedicareAdvantage === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </>
  );
};

export default EditPracticeInfo;
