/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Breadcrumb,
  Button,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import { firstLetterOfTwo } from "utils";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import addIcon from "./add-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import practiceIcon from "./images/Icon-b-practice.svg";
import providerListIcon from "./images/Icon-b-provider-list.svg";
import userIcon from "./images/Icon-b-user.svg";
import editIcon from "./images/Icon-b-edit.svg";
import labels from "Static/Dictionary.json";
import sortIcon from "./images/Sort_arrow.svg";
import "react-datepicker/dist/react-datepicker.css";
import AddLocationModalPage from "../DataPages/AddLocationModalPage";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import cookies from "js-cookie";
import { prefixData } from "utils";
import ChangePrimaryLocationModal from "./ChangePrimaryLocatiomodal";
import { formatDateByTimeZone } from "Static/format";

function convertLocalToUTCDate(date) {
  return date ? new Date(date) : null;
}
const AddProvider = ({
  practiceTitle,
  providerSummary,
  selectedPracticeId,
  practiceManagerId,
  id,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  const [addLocationList, setAddLocationListData] = useState([]);
  const [changePrimaryData, setChangePrimaryData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [primaryPracticeId, setPrimaryPracticeId] = useState(null);
  const [primaryLocData, setPrimayLocData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPracticeId1 =
    localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const navigation = useNavigate();

  const providerLocationGetUpdate = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetLocationByPracticeManagerId?practiceManagerId=${practiceManagerId}&personid=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.practiceAssignment?.length > 0) {
          const primaryLocations = data?.practiceAssignment
            ?.filter(
              (assignment) =>
                assignment?.practiceId === parseInt(selectedPracticeId1)
            )
            .map((item) => ({
              ...item,
              isProviderTrue: item?.termDate === null ? true : false,
              isProviderFalse: item?.termDate === null ? false : true,
              assignmentTypeId: 2,
              isDefaultId: item?.assignmentTypeId === 1 ? true : false,
            }));
          const providerLocations = providerSummary?.practiceAssignment
            ?.filter(
              (assignment) =>
                assignment?.practiceId !== parseInt(selectedPracticeId1)
            )
            .map((item) => ({
              ...item,
              isProviderTrue: item?.termDate === null ? true : false,
              isProviderFalse: item?.termDate === null ? false : true,
              isDefaultId: item?.assignmentTypeId === 1 ? true : false,
            }));
          const locValue = [...primaryLocations, ...providerLocations];
          setLocationListData(locValue);
          const primaryAddLocations = data?.practiceAssignment?.filter(
            (assignment) =>
              assignment?.practiceId !== parseInt(selectedPracticeId1)
          );
          const providerAddLocations = primaryAddLocations?.filter(
            (assignment) =>
              !providerSummary?.practiceAssignment
                ?.map((loc) => loc?.practiceId)
                .includes(assignment?.practiceId)
          );
          setAddLocationListData(providerAddLocations);
          console.log(
            "primaryLocations",
            providerAddLocations,
            selectedPracticeId1
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPrimaryLocChangeRecords = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetSQCNApprovalFlag?providerId=${id}&practiceaManagerId=${practiceManagerId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error("API request failed with status: " + response.status);
        }
      })
      .then((data) => {
        const trimmedData = data?.trim();
        setChangePrimaryData(trimmedData);
        if (trimmedData.toLowerCase() === "false") {
          setUpdateModalShow(false);
          setPrimayLocData(false);
        } else if (trimmedData.toLowerCase() === "true") {
          setUpdateModalShow(true);
          setPrimayLocData(true);
        } else if (trimmedData.toLowerCase() === "pending") {
          setUpdateModalShow(true);
          setPrimayLocData(false);
        } else {
          setUpdateModalShow(false);
          setPrimayLocData(false);
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      providerLocationGetUpdate();
    };
    fetchData();
  }, [selectedPracticeId1]);

  const specialtyDetailsByspecialtype =
    providerSummary?.specialtySpecialtyType?.find(
      (item) => item?.specialtyType?.typeSpecialty === "Board Specialty1"
    );
  const prefferedEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Preferred"
  );
  const sentaraEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Sentara"
  );
  const firstLetters = firstLetterOfTwo(
    providerSummary?.firstName,
    providerSummary?.lastName
  );
  const clickHandler = (locations) => {
    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
    }));
    const value = [...locationListData, ...fData];
    setLocationListData(value);
    if (locations?.length > 0) {
      const updatedData = addLocationList?.filter(
        (assignment) =>
          !locations.some((selectedAssignment) =>
            assignment?.practice && selectedAssignment?.practice
              ? assignment?.practice?.id === selectedAssignment?.practice?.id
              : false
          )
      );
      setAddLocationListData(updatedData);
    }
  };

  const validationSchema = yup.object({
    preferredEmailAddress: yup
      .string()
      .email("Invalid email format")
      .required("Email required."),
    sentaraEmailAddress: yup.string().email("Invalid email format"),
  });

  const formik = useFormik({
    initialValues: {
      preferredEmailAddress: prefferedEmailByemailtype?.emailAddress || "",
      sentaraEmailAddress: sentaraEmailByemailtype?.emailAddress || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const payLoad = {
        updateUser: practiceManagerId,
        prefixId: providerSummary?.prefixId,
        PersonId: providerSummary?.id,
        firstName: providerSummary?.firstName || "",
        middleName: providerSummary?.middleName || "",
        lastName: providerSummary?.lastName || "",
        title: providerSummary?.titleType[0]?.title || "",
        boardSpeciality:
          specialtyDetailsByspecialtype?.specialty?.specialtyName || "",
        npi: providerSummary?.npi || "",
        mdofficeId: providerSummary?.mdofficeId || "",
        preferredEmail: values?.preferredEmailAddress,
        sentaraEmail: values?.sentaraEmailAddress,
        practiceAssignment: locationListData?.map((item) => ({
          startDate: item?.startDate
            ? moment(item?.startDate).format("YYYY-MM-DD")
            : "",
          termDate: item?.termDate
            ? moment(item?.termDate).format("YYYY-MM-DD")
            : undefined,
          practiceId: item?.practiceId,
          roleTypeId: 2,
          assignmentTypeId: item?.assignmentTypeId,
        })),
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "access-control-allow-credentials": "true",
          "ocp-apim-subscription-key": subscriptionKey,
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(payLoad),
      };
      let status = "";
      fetch(
        base_url +
          `/api/v1/providers/CreateExistingProviderForApproval?practiceId=${selectedPracticeId}&primaryLocation=${primaryLocData}`,
        requestOptions
      )
        .then((response) => {
          status = response?.status;
          return response.json();
        })
        .then((res) => {
          console.log("ress", res, status);
          if (Number(status) === 200) {
            setErrorMessage();
            setIsLoading(false);
            navigation("/", {
              state: { isCreate: true },
            });
          } else {
            setErrorMessage(res?.message || res?.error || res?.title);
            console.log(
              "repm",
              res?.message,
              res,
              primaryLocData,
              errorMessage
            );
            setIsLoading(false);
          }
        });
    },
  });

  const handleCheckYes = (e, itemId) => {
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isProviderTrue: e.target.checked,
          isProviderFalse: !e.target.checked,
          termDate: null,
          assignmentTypeId:
            item?.isPrimarySelect === true || item?.isDefaultId === true
              ? 1
              : 2,
          assignmentType: {
            ...item.assignmentType,
            id:
              item?.isPrimarySelect === true || item?.isDefaultId === true
                ? 1
                : 2,
          },
        };
      }
      return item;
    });
    setLocationListData(updatedData);
  };

  console.log("pedit", locationListData);
  const handleCheckNo = (e, itemId) => {
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isProviderTrue: !e.target.checked,
          isProviderFalse: e.target.checked,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2,
          },
        };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  console.log("ll", locationListData);
  const handleRadioSelection = (pid) => {
    setPrimaryPracticeId(pid);
    fetchPrimaryLocChangeRecords();
    // const updatedData = locationListData?.map((item) => {
    //   if (item?.isDefaultId && isSelect) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2
    //       }
    //     };
    //   } else if (!item?.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2
    //       }
    //     };
    //   } else if (item.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2
    //       }
    //     };
    //   }
    //   return {
    //     ...item,
    //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //     ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2
    //   };
    // });
    const updatedData = locationListData?.map((item) => {
      if (item?.practiceId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          termDate: null,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === pid ? 1 : 2,
          },
          isPrimarySelect: true,
          isDefaultId: false,
        };
      } else {
        return {
          ...item,
          assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            id: 2,
          },
          isPrimarySelect: false,
          isDefaultId: false,
        };
      }
    });
    setLocationListData(updatedData);
    console.log("upd", updatedData);
  };
  const handlePrimaryCancel = () => {
    const updatedData = locationListData?.map((item) => {
      if (item?.isDefaultId) {
        return {
          ...item,
          assignmentTypeId: item?.practiceId === primaryPracticeId ? 2 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === primaryPracticeId ? 2 : 2,
          },
        };
      } else if (!item?.isDefaultId) {
        return {
          ...item,
          assignmentTypeId: item?.practiceId === primaryPracticeId ? 2 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === primaryPracticeId ? 2 : 2,
          },
        };
      }
      return {
        ...item,
        assignmentTypeId: item?.practiceId === primaryPracticeId ? 2 : 2,
        ["assignmentType.id"]: item?.practiceId === primaryPracticeId ? 2 : 2,
      };
    });
    setLocationListData(updatedData);
  };
  const startDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        console.log("item", item.id);
        return { ...item, startDate: isoDateString };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  const endDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    const updatedData = locationListData?.map((item) => {
      if (item.id === itemId) {
        return { ...item, termDate: isoDateString };
      }
      return item;
    });
    setLocationListData(updatedData);
  };
  const handleClickStorage = () => {
    localStorage.removeItem("tab");
  };
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchInput);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);

    const locationMatches = item?.practice?.location?.some((location) => {
      const locationName = location?.locationName
        ?.toLowerCase()
        .replace(/\s/g, "");
      const address = `${location?.street1 || ""} ${location?.street2 || ""} ${
        location?.city || ""
      } ${location?.state || ""} ${location.zipCode || ""}`
        .toLowerCase()
        .replace(/\s/g, "");
      const startDate = item?.startDate
        ? moment(item.startDate)
            .format("MM/DD/YYYY")
            .toLowerCase()
            .replace(/\s/g, "")
        : "";
      const endDate = item?.termDate
        ? moment(item.termDate)
            .format("MM/DD/YYYY")
            .toLowerCase()
            .replace(/\s/g, "")
        : "";

      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address) &&
        searchStartDatePattern.test(startDate) &&
        searchEndDatePattern.test(endDate)
      );
    });

    return locationMatches;
  };

  useEffect(() => {
    const newFilteredData = locationListData?.filter(filterData);
    setFilteredAllData(newFilteredData);
    console.log("newFilteredData");
  }, [
    searchInput,
    searchAddress,
    searchStartDate,
    searchEndDate,
    locationListData,
  ]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortDataByStartDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.startDate).diff(moment(b.startDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const sortDataByEndDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.termDate).diff(moment(b.termDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };
  return (
    <div>
      <Container className="mt-40">
        <Breadcrumb>
          <Breadcrumb.Item data-testid="practiceName">
            <Link
              to={"/"}
              data-testid="practicetitlelink"
              onClick={handleClickStorage}
            >
              <img src={practiceIcon} alt="Practice Icon" />{" "}
              {locationListData[0]?.practice?.practiceNameLegal ||
                practiceTitle}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" data-testid="providerlistpage">
            <Link to={"/"} data-testid="providerlist">
              <img src={providerListIcon} alt="Practice Icon" /> Provider List
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-testid="providerNameDisplay">
            <img src={userIcon} alt="Practice Icon" />{" "}
            {providerSummary?.firstName} {providerSummary?.lastName}
          </Breadcrumb.Item>
          <Breadcrumb.Item active data-testid="AddProvider">
            <img src={editIcon} alt="Edit Icon" /> Add Provider
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="edit-provider">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={2} xs={12}>
                <div className="provider-profile">
                  <div
                    className="firstletter justify-content-center  d-flex align-items-center"
                    data-testid="providerNamefirstletters"
                  >
                    {firstLetters}
                  </div>
                </div>
              </Col>
              <Col md={10} xs={12}>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.PREFIX}</Form.Label>
                    <div className="info" data-testid="prefixdata">
                      {prefixData(providerSummary?.prefixId)}
                    </div>
                  </div>
                  <div className="block">
                    <Form.Label>{labels.FIRST_NAME}</Form.Label>
                    {providerSummary?.firstName ? (
                      <div className="info" data-testid="firstNamedata">
                        {providerSummary?.firstName}
                      </div>
                    ) : (
                      <div className="info" data-testid="firstNamenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.MIDDLE_INTITIAL}</Form.Label>
                    {providerSummary?.middleName ? (
                      <div className="info" data-testid="midlleNamedata">
                        {providerSummary.middleName}
                      </div>
                    ) : (
                      <div className="info" data-testid="middleNamenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.LAST_NAME}</Form.Label>
                    {providerSummary?.lastName ? (
                      <div className="info" data-testid="lastNamedata">
                        {providerSummary?.lastName}
                      </div>
                    ) : (
                      <div className="info" data-testid="lastNamenodata">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.TITLE}</Form.Label>
                    {providerSummary?.titleType[0]?.title ? (
                      <div className="info" data-testid="titledata">
                        {providerSummary?.titleType[0]?.title}
                      </div>
                    ) : (
                      <div className="info" data-testid="titlenodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.BOARD_SPECIALITY}</Form.Label>
                    {specialtyDetailsByspecialtype?.specialty?.specialtyName ? (
                      <div className="info" data-testid="specialtydata">
                        {
                          specialtyDetailsByspecialtype?.specialty
                            ?.specialtyName
                        }
                      </div>
                    ) : (
                      <div className="info" data-testid="specialtynodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.NPI}</Form.Label>
                    {providerSummary?.npi ? (
                      <div className="info" data-testid="npidata">
                        {providerSummary?.npi}
                      </div>
                    ) : (
                      <div className="info" data-testid="npinodata">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.MDOFFICE_ID}</Form.Label>
                    {providerSummary?.mdofficeId ? (
                      <div className="info" data-testid="npidata">
                        {providerSummary?.mdofficeId}
                      </div>
                    ) : (
                      <div className="info" data-testid="npinodata">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <Form.Label>{labels.PREFERED_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      placeholder="---"
                      name="preferredEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.preferredEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providerPreferredEmailAddress"
                      className={
                        formik.touched.preferredEmailAddress &&
                        formik.errors.preferredEmailAddress
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.preferredEmailAddress &&
                    formik.errors.preferredEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providerPreferredEmailAddressError"
                      >
                        {formik.errors.preferredEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <Form.Label>{labels.SENTARA_EMAIL_ADDRESS}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="---"
                      name="sentaraEmailAddress"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sentaraEmailAddress}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      data-testid="providersentaraEmailAddress"
                      className={
                        formik.touched.sentaraEmailAddress &&
                        formik.errors.sentaraEmailAddress
                          ? "error-field nb-input"
                          : "nb-input"
                      }
                    />
                    {formik.touched.sentaraEmailAddress &&
                    formik.errors.sentaraEmailAddress ? (
                      <div
                        className="form-error"
                        data-testid="providersentaraEmailAddressError"
                      >
                        {formik.errors.sentaraEmailAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="location-banner" data-testid="locationBanner">
                  <Row>
                    <Col
                      md={8}
                      xs={12}
                      className="practice-loc-title"
                      data-testid="practicelocationTitle"
                    >
                      <h4>Provider Locations</h4>
                    </Col>
                    <Col md={4} xs={12} className="ta-right">
                      {addLocationList?.length > 0 && (
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="add-location"
                          data-testid="AddLocationbutton"
                        >
                          <span>
                            <img src={addIcon} alt="add icon" />
                          </span>
                          Add Location
                        </Button>
                      )}
                      {modalShow && (
                        <AddLocationModalPage
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          clickHandler={clickHandler}
                          practiceManagerId={practiceManagerId}
                          data-testid="modal"
                          locationListData={locationListData}
                          id={id}
                          addLocationList={addLocationList}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="provider-location-table">
                    <Table data-testid="providerLocationtable">
                      <thead>
                        <tr>
                          <th>Location Number - Name</th>
                          <th>Address</th>
                          <th className="phone-head5">Provider at Location</th>
                          <th className="phone-head4">Is Primary?</th>
                          <th className="phone-head6">
                            <span className="date_text">Start Date</span>
                            <button
                              className="sort_icon_arrow"
                              type="button"
                              onClick={sortDataByStartDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                          <th className="phone-head6">
                            <span className="date_text">End Date </span>
                            <button
                              className="sort_icon_arrow"
                              type="button"
                              onClick={sortDataByEndDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody data-testid="ProviderData">
                        <tr>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchInput}
                                onChange={handleSearchChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchAddress}
                                onChange={handleSearchAddressChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchStartDate}
                                onChange={handleSearchStartDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column1">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchEndDate}
                                onChange={handleSearchEndDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        {filteredAllData?.map((item) => {
                          const formattedStartDate = item?.startDate
                            ? new Date(item?.startDate)
                            : null;
                          const formattedTermDate = item?.termDate
                            ? new Date(item?.termDate)
                            : null;
                          return (
                            <React.Fragment key={item?.id}>
                              {item.practice.location.map((locationItem) => (
                                <tr key={locationItem.id}>
                                  <td
                                    className={
                                      locationItem.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {locationItem.locationNumber}&nbsp;-&nbsp;
                                    {locationItem.locationName}
                                  </td>
                                  <td
                                    className={
                                      locationItem.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {`${locationItem.street1} ${
                                      locationItem.street2
                                        ? locationItem.street2
                                        : ""
                                    }, ${locationItem.city}, ${
                                      locationItem.state
                                    } ${locationItem.zipCode}`}
                                  </td>
                                  <td
                                    className={
                                      locationItem.locationNumber === 1
                                        ? "Primary1 text-center"
                                        : "Secondary1 text-center"
                                    }
                                  >
                                    <div className="info">
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="Yes"
                                        data-testid="providerAtLocationYes"
                                        value={item.isProviderTrue}
                                        checked={item.isProviderTrue}
                                        onChange={(e) =>
                                          handleCheckYes(e, item?.id)
                                        }
                                      />
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="No"
                                        data-testid="providerAtLocationNo"
                                        value={item.isProviderFalse}
                                        checked={item.isProviderFalse}
                                        onChange={(e) =>
                                          handleCheckNo(e, item?.id)
                                        }
                                      />
                                    </div>
                                  </td>
                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div className="info">
                                        <Form.Check
                                          inline
                                          type="radio"
                                          data-testid="providerPrimary"
                                          value="yes"
                                          checked={item?.assignmentTypeId === 1}
                                          onChange={() =>
                                            handleRadioSelection(
                                              item?.practiceId
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    ></td>
                                  )}
                                  {locationItem.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editstartDateInput"
                                      >
                                        <DatePicker
                                          dateFormat="MM/dd/yyyy"
                                          selected={formattedStartDate}
                                          onChange={(date) =>
                                            startDateFn(date, item.id)
                                          }
                                          placeholderText="MM/DD/YYYY"
                                          data-testid="editproviderStartDate"
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item.startDate
                                        ? moment(item.startDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                  {locationItem.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editendDateInput"
                                      >
                                        <DatePicker
                                          disabled={item.isProviderTrue}
                                          dateFormat="MM/dd/yyyy"
                                          data-testid="editproviderEndDate"
                                          selected={formattedTermDate}
                                          placeholderText="MM/DD/YYYY"
                                          onChange={(date) =>
                                            endDateFn(date, item.id)
                                          }
                                          required
                                          minDate={formattedStartDate}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item.termDate
                                        ? moment(item.termDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="add-location-footer">
                  {errorMessage && (
                    <p className="Error-message" data-testid="Errormessage">
                      {errorMessage}
                    </p>
                  )}
                  <p data-testid="providerStartDateconfirmText">
                    Please confirm that all information is correct
                  </p>
                  <div className="add-location-buttons">
                    <Link to={"/"}>
                      <button className="white-btn" data-testid="CancelButton">
                        Cancel
                      </button>
                    </Link>
                    <button
                      className="blue-btn"
                      type="submit"
                      disabled={isLoading}
                      data-testid="ConfirmButton"
                    >
                      {isLoading ? "Saving..." : "Confirm and Save"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <Modal
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        backdrop="static"
        keyboard={false}
        data-testid="bulkupdateprovidermodal"
      >
        <ChangePrimaryLocationModal
          providerSummary={providerSummary}
          handlePrimaryCancel={handlePrimaryCancel}
          modalClose={() => setUpdateModalShow(false)}
          changePrimaryData={changePrimaryData}
        />
      </Modal>
    </div>
  );
};

export default AddProvider;
