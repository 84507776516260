/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  Form,
  Table,
  Button,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import addIcon from "./add-icon.svg";
import AddIcon from "../PracticeInformation/AddIcon.svg";
import icon from "./Icon.svg";
import AddProviderModal from "./AddProviderModal";
import UpdateRequired from "./images/Icon-update-required.svg";
import Updated from "./images/Icon-updated.svg";
import Inactive from "./images/Icon-inactive.svg";
import tooltipIcon from "./images/tooltipIcon.svg";
import "./_style.scss";
import sortIcon from "./images/Sort_arrow_new.svg";
import moment from "moment/moment";
import Pagination2 from "../../Admin/Components/UI/Atoms/Pagination2";
import ErrorServer from "Components/Hooks/Error404";
import Checkbox from "Components/UI/Atoms/Checkbox/Checkbox";
import cookies from "js-cookie";
import SqcnApprovalIcon from "Components/UI/Atoms/SqcnApprovalIcon";

export const providerStatus = (givenDate, inactiveProvider) => {
  var lastConfirmedDate = givenDate;
  var today = new Date();
  var termDate = new Date(inactiveProvider);
  var termDiff = termDate - today;
  var diff = moment(today).diff(lastConfirmedDate, "days");

  if (termDiff < 0 && inactiveProvider !== null) {
    return <img src={Inactive} alt="Inactive" />;
  } else if (diff > 90) {
    return <img src={UpdateRequired} alt="Update Required" />;
  } else {
    return <img src={Updated} alt="Updated" />;
  }
};

export const stillAtLocation = (termDate) => {
  if (termDate === null) {
    return "Yes";
  } else {
    const today = new Date();
    const termDateObj = new Date(termDate);

    if (termDateObj > today) {
      return "Yes";
    } else {
      return "No";
    }
  }
};

const ProviderList = ({
  providerList = [],
  primaryLocationPopover,
  stillAtPracticePopover,
  isError,
  selectedPracticeId,
  providerListUpdate,
  PersonRole,
  providerCount
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [prevSelectedPractice, setPrevSelectedPractice] =
    useState(selectedPracticeId);
  const [updateModal, setUpdateModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedAllItems, setSelectAllItems] = useState([]);
  const [updateBox, setUpdateBox] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [activeProviders, setActiveProviders] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");

  useEffect(() => {
    if (prevSelectedPractice !== selectedPracticeId) {
      setCurrentPage(1);
      setPrevSelectedPractice(selectedPracticeId);
      setIsCheckAll(false);
      setSelectAllItems([]);
      setIsCheck([]);
    }
  }, [
    selectedPracticeId,
    setCurrentPage,
    prevSelectedPractice,
    setIsCheckAll,
    setSelectAllItems,
    setIsCheck,
  ]);

  useEffect(() => {
    const activeAllProviders =
      filteredAllData != null && filteredAllData.length > 0
        ? filteredAllData?.filter((person) => {
            if (
              person?.practiceAssignment &&
              person?.practiceAssignment.length > 0
            ) {
              return person.practiceAssignment.some(
                (assignment) =>
                  assignment?.roleTypeId === 2 && assignment?.termDate === null
              );
            }
            return false;
          })
        : [];
    setActiveProviders(activeAllProviders);
  }, [selectedPracticeId, filteredAllData]);

  const sortedData = activeProviders?.sort((a, b) => {
    const statusA = a.practiceAssignment[0]?.status || a.status;
    const statusB = b.practiceAssignment[0]?.status || b.status;
    if (statusA === "Pending" && statusB !== "Pending") {
      return -1;
    } else if (statusA !== "Pending" && statusB === "Pending") {
      return 1;
    }
    return 0;
  });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(sortedData.length / recordsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  console.log("lic", currentRecords, updateBox);

  const handleSelectAll = () => {
    console.log("cal", isCheckAll);
    if (!isCheckAll) {
      setIsCheckAll(!isCheckAll);
      const selectedValues = providerList?.filter(
        (item) =>
          item?.practiceAssignment[0]?.termDate === null &&
          item?.practiceAssignment[0]?.status !== "Pending" &&
          item?.status !== "Pending"
      );
      setSelectAllItems(selectedValues);
      console.log("sv", selectedAllItems);
      setIsCheck(selectedValues.map((provider) => provider?.id));
    } else {
      setIsCheckAll(!isCheckAll);
      setSelectAllItems([]);
      setIsCheck([]);
    }
  };
  const handleSelectAllPage = () => {
    if (!isCheckAll) {
      setIsCheckAll(!isCheckAll);
      const selectedValues = currentRecords?.filter(
        (item) =>
          item?.practiceAssignment[0]?.termDate === null &&
          item?.practiceAssignment[0]?.status !== "Pending" &&
          item?.status !== "Pending"
      );
      setSelectAllItems(selectedValues);
      console.log("sv", selectedAllItems);
      setIsCheck(selectedValues.map((provider) => provider?.id));
    } else {
      setIsCheckAll(!isCheckAll);
      setSelectAllItems([]);
      setIsCheck([]);
    }
  };

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const onUpdateCancel = () => {
    setUpdateBox(false);
    setIsCheck([]);
    setIsCheckAll(false);
  };
  console.log(onUpdateCancel,"onUpdateCancel")
  const handleSubmit = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(isCheck),
    };
    fetch(
      base_url + `/api/v1/providers?practiceId=${selectedPracticeId}`,
      requestOptions
    )
      .then((response) => {
        response.json();
      })
      .then((res) => {
        console.log("respu", res);
        setUpdateModal(false);
        providerListUpdate(true);
        setIsCheck([]);
        setIsCheckAll(false);
      });
  };
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    setCurrentPage(1);
  };
  const handleSearchTitleChange = (e) => {
    const input = e.target.value;
    setSearchTitle(input);
    setCurrentPage(1);
  };
  const handleSearchEmailChange = (e) => {
    const input = e.target.value;
    setSearchEmail(input);
    setCurrentPage(1);
  };
  const handleSearchLocationChange = (e) => {
    const input = e.target.value;
    setSearchLocation(input);
    setCurrentPage(1);
  };
  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };
  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchInput);
    const searchTitlePattern = createSearchPattern(searchTitle);
    const searchEmailPattern = createSearchPattern(searchEmail);
    const searchLocationPattern = createSearchPattern(searchLocation);

    const providerName = `${item?.firstName || ""} ${item?.middleName || ""} ${
      item?.lastName || ""
    }`
      .toLowerCase()
      .replace(/\s/g, "");
    const providerTitle = (item?.titleType[0]?.title || "")
      .toLowerCase()
      .replace(/\s/g, "");
    const providerEmail = (
      item?.email?.find((email) => email?.emailTypeId === 1)?.emailAddress || ""
    )
      .toLowerCase()
      .replace(/\s/g, "");
    const locationString = getLocationString(item)
      .toLowerCase()
      .replace(/\s/g, "");

    return (
      searchLocationNamePattern.test(providerName) &&
      searchTitlePattern.test(providerTitle) &&
      searchEmailPattern.test(providerEmail) &&
      searchLocationPattern.test(locationString)
    );
  };
  const getLocationString = (provider) => {
    const providerPrimaryLocation = provider?.location;
    return `${providerPrimaryLocation?.street1 ?? ""} 
    ${providerPrimaryLocation?.street2 ?? ""}, 
    ${providerPrimaryLocation?.city ?? ""}, 
    ${providerPrimaryLocation?.state ?? ""} 
    ${providerPrimaryLocation?.zipCode ?? ""}`;
  };
  useEffect(() => {
    const newFilteredData = providerList?.filter(filterData);
    setFilteredAllData(newFilteredData);
  }, [searchInput, searchTitle, searchEmail, searchLocation, providerList]);

  const sortNameData = () => {
    const sortedProviderData = [...filteredAllData].sort((a, b) => {
      const fullNameA = `${(a?.lastName || "").trim()} ${(
        a?.middleName || ""
      ).trim()} ${(a?.firstName || "").trim()}`.toLowerCase();
      const fullNameB = `${(b?.lastName || "").trim()} ${(
        b?.middleName || ""
      ).trim()} ${(b?.firstName || "").trim()}`.toLowerCase();
      return sortOrder === "asc"
        ? fullNameB.localeCompare(fullNameA)
        : fullNameA.localeCompare(fullNameB);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setFilteredAllData(sortedProviderData);
  };
  const sortLocationName = () => {
    const sortedProviderData = [...filteredAllData].sort((a, b) => {
      const locationA = getLocationString(a);
      const locationB = getLocationString(b);
      return sortOrder === "asc"
        ? locationA.localeCompare(locationB)
        : locationB.localeCompare(locationA);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setFilteredAllData(sortedProviderData);
  };
  console.log("pl", providerList);
  const [providerCountNum, setProviderCount] = useState(providerCount);
  useEffect(() => {
    setProviderCount(providerCount);
  }, [providerCount]);

  console.log("pl---1", providerCountNum);
  return (
    <div>
      {isError && <ErrorServer />}
      {!isError && (
        <div>
          <div className="provider-actions">
            <div className="provider-status" data-testid="providerstatus">
              <ul>
                <li>
                  <img
                    src={UpdateRequired}
                    alt="Update Required"
                    data-testid="providerUpdatedReuired"
                  />{" "}
                  Update Required
                </li>
                <li>
                  <img
                    src={Updated}
                    alt="Updated"
                    data-testid="providerUpdated"
                  />
                  Updated
                </li>
                {/* <li>
                  <img
                    src={Inactive}
                    alt="Inactive"
                    data-testid="providerInactive"
                  />
                  Inactive
                </li> */}
              </ul>
            </div>
            <div className="provider-button">
              {PersonRole !== "SQCNAdmin" && (
                <Button
                  data-testid="addprovider"
                  variant="primary"
                  onClick={() => setModalShow(true)}
                  className="add-provider"
                >
                  {" "}
                  <span>
                    <img src={addIcon} alt="add icon" />
                  </span>{" "}
                  Add New Provider
                </Button>
              )}
              <AddProviderModal
                data-testid="addprovidermodal"
                show={modalShow}
                onHide={() => setModalShow(false)}
                providerList={providerList}
              />
            </div>
          </div>
          <div className="provider-list-table">
            <Table data-testid="providerlisttable">
              <thead>
                <tr>
                  <th className="checkhead">
                    <div className="dropdown1">
                      <Checkbox
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                        className="dropbtn"
                      />
                      <div className="dropdown-content">
                        <button onClick={handleSelectAll}>
                          Select all providers
                        </button>
                        <button onClick={handleSelectAllPage}>
                          Select all providers in this page
                        </button>
                      </div>
                    </div>
                  </th>
                  <th className="provider_Column">Provider Name</th>
                  <th>Title</th>
                  <th>Email</th>
                  <th className="provider_Column1">
                    Primary Location
                    <OverlayTrigger
                      trigger={["hover", "hover"]}
                      placement="bottom"
                      overlay={primaryLocationPopover}
                      data-testid="primaryLocationTooltip"
                    >
                      <img
                        src={tooltipIcon}
                        alt="Tooltip Icon"
                        className="tooltip-icon"
                      />
                    </OverlayTrigger>
                  </th>
                  <th>
                    Still at location
                    <OverlayTrigger
                      trigger={["hover", "hover"]}
                      placement="bottom"
                      overlay={stillAtPracticePopover}
                      data-testid="stillAtPracticeTooltip"
                    >
                      <img
                        src={tooltipIcon}
                        alt="Tooltip Icon"
                        className="tooltip-icon"
                      />
                    </OverlayTrigger>
                  </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody data-testid="ProviderData">
                <tr>
                  <td className="search_column"></td>
                  <td className="search_column">
                    <Form.Group
                      className="search-npi loc1"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchInput}
                        onChange={handleSearchChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                    <button
                      className="old_list sort_icon_arrow pl"
                      type="button"
                      onClick={sortNameData}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </td>
                  <td className="search_column">
                    <Form.Group
                      className="search-npi"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchTitle}
                        onChange={handleSearchTitleChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                  </td>
                  <td className="search_column">
                    <Form.Group
                      className="search-npi"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchEmail}
                        onChange={handleSearchEmailChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                  </td>
                  <td className="search_column">
                    <Form.Group
                      className="search-npi loc2"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchLocation}
                        onChange={handleSearchLocationChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                    <button
                      className="old_list sort_icon_arrow pl"
                      type="button"
                      onClick={sortLocationName}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </td>
                  <td className="search_column"></td>
                  <td className="search_column"></td>
                </tr>
                {currentRecords?.map((provider) => {
                  return (
                    <tr key={provider?.id}>
                      <td>
                        {provider?.practiceAssignment[0]?.termDate === null &&
                        provider?.practiceAssignment[0]?.status !== "Pending" &&
                        provider?.status !== "Pending" ? (
                          <Form.Check
                            key={provider?.id}
                            type="checkbox"
                            id={provider?.id}
                            onChange={(e) => handleClick(e, provider?.id)}
                            checked={isCheck.includes(provider?.id)}
                          />
                        ) : (
                          ""
                        )}
                        {provider?.practiceAssignment[0]?.status ===
                          "Pending" || provider?.status === "Pending" ? (
                          <span>
                            <SqcnApprovalIcon
                              removeText="New provider to be approved by SQCN Admin"
                              RemoveIcon={AddIcon}
                            />
                          </span>
                        ) : null}
                      </td>

                      <td data-testid="providerName">
                        {provider?.practiceAssignment[0]?.status ===
                          "Pending" || provider?.status === "Pending" ? (
                          <>
                            {provider?.firstName}&nbsp;{provider?.middleName}
                            &nbsp;
                            {provider?.lastName}
                          </>
                        ) : (
                          <Link
                            key={provider?.id}
                            to={`/provider-list/${provider?.id}`}
                          >
                            {provider?.firstName}&nbsp;{provider?.middleName}
                            &nbsp;
                            {provider?.lastName}
                          </Link>
                        )}
                      </td>
                      {provider?.titleType[0]?.title ? (
                        <td data-testid={`providerTitle${provider?.id}`}>
                          {provider?.titleType[0]?.title}
                        </td>
                      ) : (
                        <td data-testid="NoproviderTitle">--</td>
                      )}
                      {provider?.email?.find(
                        (email) => email?.emailTypeId === 1
                      )?.emailAddress ? (
                        <td data-testid={`providerEmail${provider?.id}`}>
                          {
                            provider?.email?.find(
                              (email) => email?.emailTypeId === 1
                            )?.emailAddress
                          }
                        </td>
                      ) : (
                        <td data-testid="NoproviderEmail">--</td>
                      )}
                      {provider?.location?.street1 ? (
                        <td data-testid="providerPrimaryLocation">
                          {`${provider?.location?.street1 ?? ""} ${
                            provider?.location?.street2 ?? ""
                          }, ${provider?.location?.city ?? ""}, 
                          ${provider?.location?.state ?? ""} ${
                            provider?.location?.zipCode ?? ""
                          }`}
                        </td>
                      ) : (
                        <td data-testid="providerPrimaryLocation">--</td>
                      )}
                      <td
                        className="still-at-locations"
                        data-testid="providerStillAtLocation"
                      >
                        {stillAtLocation(
                          provider?.practiceAssignment?.find(
                            (role) => role?.roleTypeId === 2
                          )?.termDate
                        )}
                      </td>
                      <td className="text-center" data-testid="providerStatus">
                        {providerStatus(
                          provider?.lastConfirmed,
                          provider?.practiceAssignment?.find(
                            (role) => role?.roleTypeId === 2
                          )?.termDate
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {activeProviders?.length > 0 && (
              <Pagination2
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                data-testid="PaginationPage2"
              />
            )}
          </div>
          <div className={`bulk-update-box ${isCheck.length > 0}`}>
            <Row>
              <Col md={8}>
                <p className="update-text">
                  By clicking &quot;Update&quot; you are confirming that all the
                  provider profiles you have selected are up to date.
                </p>
              </Col>
              <Col md={4} className="update-button">
                <button
                  className="white-btn-update"
                  onClick={onUpdateCancel}
                  data-testid="bulkupdateCancelButton"
                >
                  Cancel
                </button>
                <button
                  className="blue-btn-update"
                  onClick={() => setUpdateModal(true)}
                  type="submit"
                  data-testid="bulkupdateConfirmButton"
                >
                  Update
                </button>
              </Col>
            </Row>
          </div>
          {(activeProviders.length === 0 && providerCount !== 0) && (
            <div className="error-message" data-testid="noProviderData">
              <p>
                <img src={icon} alt="error-icon" className="error-icon"></img>
                No results found
              </p>
            </div>
          )}{providerCount === 0 && (
            <div className="error-message" data-testid="noProviderData">
              <p>
                <img src={icon} alt="error-icon" className="error-icon"></img>
                Currently there are no providers at this Practice
              </p>
            </div>
          )}
        </div>
      )}

      <Modal
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        data-testid="bulkupdateprovidermodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Bulk Update
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="">
            You are about to update <b>{isCheck.length}</b> providers.
          </p>
          <p>This action can&#039;t be undone. Are you sure to proceed</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => setUpdateModal(false)}
            className="white-btn"
            data-testid="close-button"
          >
            Cancel
          </button>

          <button
            className="blue-btn"
            onClick={handleSubmit}
            type="submit"
            data-testid="createnewproviderbutton"
          >
            Yes, Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProviderList;
