/* eslint-disable indent */
import React from "react";
import moment from "moment/moment";
import { Table } from "react-bootstrap";
import ApproveIcon from "Components/UI/Atoms/ApproveIcon";

export const stillAtLocation = (termDate) => {
  if (termDate === null) {
    return "Yes";
  } else {
    const today = new Date();
    const termDateObj = new Date(termDate);

    if (termDateObj > today) {
      return "Yes";
    } else {
      return "No";
    }
  }
};

const AddNewPracticeLocation = ({
  providerSummaryData,
  selectedPracticeId1,
  providerPrimaryLocationData
}) => {
  console.log("ppd", selectedPracticeId1, providerPrimaryLocationData);
  const withoutTermedProviders = providerSummaryData?.practiceAssignment.filter(item => {
    return item.termDate === null;
  });
  return (
    <div className="provider-location-table">
      <Table data-testid="providerLocationtable">
        <thead>
          <tr>
            <th>Location Number - Name</th>
            <th>Address</th>
            <th className="phone-head5 text-center">Provider at Location</th>
            <th className="phone-head4 text-center">Is Primary?</th>
            <th className="phone-head6 text-center">Start Date</th>
            <th className="phone-head6 text-center">End Date</th>
          </tr>
        </thead>
        <tbody data-testid="ProviderData">
          {withoutTermedProviders?.map((item) => (
            <React.Fragment key={item?.id}>
              {item?.practice?.location.map((locationItem) => (
                <tr key={locationItem?.id}>
                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                    {locationItem?.locationNumber} - {locationItem?.locationName}
                  </td>
                  <td className={locationItem?.locationNumber === 1 ? "Primary1" : "Secondary1"}>
                    {`${locationItem?.street1} ${locationItem?.street2 ? locationItem?.street2 : ""}, ${locationItem?.city}, ${locationItem?.state} ${locationItem?.zipCode}`}
                  </td>
                  <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                    {stillAtLocation(item?.termDate)}
                  </td>
                  {
                    locationItem?.locationNumber === 1 ? (
                      <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                        {(item?.status === "Pending" && item?.assignmentTypeId === 1) ? (
                          <>
                            <ApproveIcon />&nbsp;&nbsp;Yes
                          </>
                        ) : (
                          providerPrimaryLocationData ? (
                            providerPrimaryLocationData?.newValue?.toLowerCase() === locationItem?.locationName?.toLowerCase() ? (
                              <>
                                <ApproveIcon />&nbsp;&nbsp;Yes
                              </>
                            ) : "No"
                          ) : (
                            item?.assignmentTypeId === 1 ? "Yes" : "No"
                          )
                        )}
                      </td>
                    ) : (
                      <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                        No
                      </td>
                    )
                  }

                  <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                    {item?.startDate ? moment(item?.startDate).format("MM/DD/YYYY") : "--"}
                  </td>
                  <td className={locationItem?.locationNumber === 1 ? "Primary1 text-center" : "Secondary1 text-center"}>
                    {item?.termDate ? moment(item?.termDate).format("MM/DD/YYYY") : "--"}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AddNewPracticeLocation;
