import React, { useRef, useState } from "react";
import dropIcon from "./dropdownIcon.svg";
import ListItem from "Components/UI/Atoms/ListItem";
import { Form } from "react-bootstrap";

function DropdownList({ dataList, selectedItemId, onItemSelected }) {
  const dropdownRef = useRef(null);

  const handleDropdownScroll = (event) => {
    dropdownRef.current.scrollTop += event.deltaY;
  };
  const [searchData, setSearchData] = useState(dataList);
  const handlePractice = (e) => {
    setSearchData(() => {
      return dataList?.filter((data) =>
        data?.practiceName?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
  };
  return (
    <div className="practice-dropdown">
      <div className="practice-change">
        My Practices{" "}
        <span>
          <img src={dropIcon} className="practice-drop-icon" alt="dropdown" />
        </span>
      </div>
      <div
        className="practice-list-block"
        ref={dropdownRef}
        onWheel={handleDropdownScroll}
      >
        <div className="practice-search-dashboard">
          <Form.Group controlId="street3">
            <Form.Control
              type="text"
              id="street3"
              autoComplete="off"
              className="nb-input dashboard-search"
              onChange={handlePractice}
              placeholder="Search"
              controlId="practiceSearch"
            />
          </Form.Group>
        </div>
        <ul className="practice-list">
          {searchData?.map((data) => (
            <ListItem
              key={data?.practiceId}
              id={data?.practiceId}
              name={data?.practiceName || ""}
              selected={data?.practiceId === selectedItemId}
              onItemClicked={onItemSelected}
              data-testid="child"
              controlId="practiceId"
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DropdownList;
