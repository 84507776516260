/* eslint-disable indent */
import React, { useRef, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import Pagination from "../ProviderList/Pagination";
import addIcon from "../ProviderList/add-icon.svg";
import deleteIcon from "./deleteIcon.svg";
import RemoveIcon from "./locationRemove.svg";
import AddIcon from "./AddIcon.svg";
import AddPracticeLocationModal from "./AddPracticeLocationModal";
import cookies from "js-cookie";
import SqcnApprovalIcon from "Components/UI/Atoms/SqcnApprovalIcon";
import { useEffect } from "react";

const AddPracticeLocation = ({
  selectedPracticeId,
  AddNewLocationInfo,
  handleAlertClick,
  removeLocation,
  setLocationBox,
  practiceManagerId,
  PersonRole
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState();
  const [locationListData, setLocationListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");

  const prevSelectedPracticeId = useRef(selectedPracticeId);
  useEffect(() => {
    if (prevSelectedPracticeId.current !== selectedPracticeId) {
      setLocationBox(false);
    }
    prevSelectedPracticeId.current = selectedPracticeId;
  }, [selectedPracticeId, setLocationBox]);

  const fetchLocationRecords = () => {
    fetch(
      base_url +
      `/api/v1/practices/${selectedPracticeId}/location?includePending=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLocationListData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchLocationRecords();
    };
    fetch();
  }, [selectedPracticeId]);

  const sortedData = locationListData.sort((a) => {
    if (a.status === "Pending") {
      return -1;
    } else {
      return 0;
    }
  });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(sortedData?.length / recordsPerPage);
  console.log("lic", currentRecords);

  const handleClick = (e, id) => {
    console.log("id", id);
    setUpdateModal(true);
    setDeleteLocationId(id);
    handleAlertClick();
  };
  console.log("lic", currentRecords, handleClick);
  const handleAddClick = () => {
    setModalShow(true);
    handleAlertClick();
  };

  const deleteLocationName = locationListData?.find(
    (item) => item?.id === deleteLocationId
  );

  const handleSubmit = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(),
    };
    fetch(
      base_url +
      `/api/v1/practices/${selectedPracticeId}/location?LocationId=${deleteLocationId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200) {
        setUpdateModal(false);
        fetchLocationRecords();
        removeLocation();
      }
    });
  };

  return (
    <div>
      <div>
        <div className="provider-actions">
          <div className="provider-button-location">
            <Button
              data-testid="backToPracticeInfo"
              onClick={() => setLocationBox(false)}
              className="back-button"
            >
              Back
            </Button>
            {PersonRole !== "SQCNAdmin" &&
              <Button
                data-testid="addprovider"
                onClick={handleAddClick}
                className="add-provider"
              >
                <span>
                  <img src={addIcon} alt="add icon" />
                </span>
                Add Location
              </Button>
            }
            <AddPracticeLocationModal
              data-testid="addprovidermodal"
              show={modalShow}
              selectedPracticeId={selectedPracticeId}
              practiceManagerId={practiceManagerId}
              onHide={() => setModalShow(false)}
              fetchAddRecords={fetchLocationRecords}
              AddNewLocationInfo={AddNewLocationInfo}
            />
          </div>
        </div>
        <div className="manage-location-table">
          <Table data-testid="providerlisttable">
            <thead>
              <tr>
                {sortedData?.length > 0 &&
                  <th className="no-border"></th>
                }
                <th>Location Name</th>
                <th>Address</th>
                <th className="phone-head">Phone Number (Main)</th>
                <th className="phone-head1">Fax Number</th>
                <th className="phone-head2">Scheduling Number</th>
                <th>Action</th>
              </tr>
            </thead>
            {sortedData?.length > 0 && (
              <tbody data-testid="ProviderData">
                {currentRecords?.map((location) => (
                  <tr key={location?.id}>
                    {location?.status === "Pending" && location?.isDeleted === true ? (
                      <td className="action-status">
                        <span>
                          <SqcnApprovalIcon
                            removeText="Location removal from practice to be approved by SQCN Admin"
                            RemoveIcon={RemoveIcon}
                          />
                        </span>
                      </td>
                    ) : (
                      <td className="action-status">
                        {location?.status === "Pending" && location?.isDeleted === false && (
                          <span>
                            <SqcnApprovalIcon
                              removeText="New location to practice to be approved by SQCN Admin"
                              RemoveIcon={AddIcon}
                            />
                          </span>
                        )}
                      </td>
                    )}
                    <td data-testid="locationName">{location?.locationName}</td>
                    <td data-testid="PrimaryLocation">{`${location?.street1} ${location?.street2 ? location?.street2 : ""
                      } ${location?.street3 ? location?.street3 : ""
                      }, ${location?.city}, ${location?.state} ${location?.zipCode
                      }`}</td>
                    <td>{location?.phoneNumber.find((phone) => phone.phoneTypeId === 1)?.number}</td>
                    <td>{location?.phoneNumber.find((phone) => phone.phoneTypeId === 4)?.number}</td>
                    <td>{location?.phoneNumber.find((phone) => phone.phoneTypeId === 5)?.number}</td>
                    <td className="delete-action">
                      {location?.status !== "Pending" && location?.isDeleted !== true &&
                        <img src={deleteIcon} alt="add icon" />
                      }
                    </td>

                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {locationListData?.length > 0 && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data-testid="PaginationPage"
            />
          )}
        </div>
      </div>

      <Modal
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-provider-modal"
        data-testid="bulkupdateprovidermodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Remove Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to remove{" "}
            <b>
              &quot;
              {deleteLocationName?.locationName}
              &quot;
            </b>
            from your practice. This action can&#039;t be undone.<br></br>Are
            you sure to proceed
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => setUpdateModal(false)}
            className="white-btn"
            data-testid="close-button"
          >
            Cancel
          </button>

          <button
            className="blue-btn"
            onClick={handleSubmit}
            type="submit"
            data-testid="createnewproviderbutton"
          >
            Yes, Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPracticeLocation;
