/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Form, Table, Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import icon from "./Icon.svg";
import deleteIcon from "./images/delete.svg";
import "./_style.scss";
import sortIcon from "./images/Sort_arrow_new.svg";
import plusIcon from "./add-icon.svg";
import ErrorServer from "Components/Hooks/Error404";
import cookies from "js-cookie";
import AddProviderModal from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/ProviderAccordion/AddProviderModal";
import ViewProvider from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/ProviderAccordion/ViewProvider";
import EditPracticeNewProviderAdmin from "../DataPages/EditPracticeNewProviderAdmin";
import EditProviderPageAdmin from "../DataPages/EditProviderPageAdmin";
import closeIcon from "../../../images/close-icon.svg";
import updatedIcon from "../../../images/updatedIcon.svg";
import { Alert } from "react-bootstrap";
import Pagination2 from "Components/Admin/Components/UI/Atoms/Pagination2";
import Spinner from "Components/Hooks/Spinner";

export const stillAtLocation = (termDate) => {
  if (termDate === null) {
    return "Yes";
  } else {
    const today = new Date();
    const termDateObj = new Date(termDate);

    if (termDateObj > today) {
      return "Yes";
    } else {
      return "No";
    }
  }
};

const ProviderListAdmin = ({
  providerList = [],
  isError,
  selectedPracticeId,
  PersonRole,
  practiceTitle,
  practiceManagerId,
  providerListGetUpdate,
  setUpdatedProviderVal,
  setRefreshData,
  refreshData,
  titleData,
  prefixData
}) => {
  const [prevSelectedPractice, setPrevSelectedPractice] =
    useState(selectedPracticeId);
  const [updatedProviderList, setUpdatedProviderList] = useState(providerList);
  const [isCheck, setIsCheck] = useState([]);
  const [updateBox, setUpdateBox] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [searchNpi, setSearchNpi] = useState("");
  const [searchFullName, setSearchFullName] = useState("");
  const [searchProviderLoc, setSearchProviderLoc] = useState("");
  const [searchPrimaryLoc, setSearchPrimaryLoc] = useState("");
  const [viewProviderId, setViewProviderId] = useState(null);
  const [updateModalNew, setUpdateModalNew] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");

  useEffect(() => {
    if (prevSelectedPractice !== selectedPracticeId) {
      setCurrentPage(1);
      setPrevSelectedPractice(selectedPracticeId);
      setIsCheck([]);
    }
  }, [selectedPracticeId, setCurrentPage, prevSelectedPractice, setIsCheck]);

  useEffect(() => {
    setUpdatedProviderList(providerList);
  }, [providerList]);
  useEffect(() => {
    const activeAllProviders =
      updatedProviderList != null && updatedProviderList.length > 0
        ? updatedProviderList?.filter((person) => {
            if (
              person?.practiceAssignment &&
              person?.practiceAssignment.length > 0
            ) {
              return person.practiceAssignment.some(
                (assignment) =>
                  assignment?.roleTypeId === 2 && assignment?.termDate === null
              );
            }
            return false;
          })
        : [];
    setUpdatedProviderList(activeAllProviders);
    // localStorage.setItem("providerCount", activeAllProviders.length || 0);
  }, []);

  // const sortedData = updatedProviderList?.sort((a, b) => {
  //   const statusA = a.practiceAssignment[0]?.status || a.status;
  //   const statusB = b.practiceAssignment[0]?.status || b.status;
  //   if (statusA === "Pending" && statusB !== "Pending") {
  //     return -1;
  //   } else if (statusA !== "Pending" && statusB === "Pending") {
  //     return 1;
  //   }
  //   return 0;
  // });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = [...updatedProviderList]?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(updatedProviderList?.length / recordsPerPage);
  useEffect(() => {
    if (
      currentPage > 1 &&
      updatedProviderList.length <= (currentPage - 1) * recordsPerPage
    ) {
      setCurrentPage(1);
    }
  }, [updatedProviderList, currentPage, recordsPerPage]);
  console.log(
    "lic",
    currentRecords,
    updateBox,
    updateModalNew,
    "updateModalNew"
  );
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= nPages) {
      setCurrentPage(pageNumber);
    }
  };

  const onUpdateCancel = () => {
    setUpdateBox(false);
    setIsCheck([]);
  };
  const handleSubmit = async () => {};

  const deleteProvider = (providerId) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(),
    };
    console.log(requestOptions, "requestOptions");
    fetch(
      base_url +
        `/api/v1/providers/RemoveProvider?practiceId=${
          window.localStorage.getItem("selectedPracticeIdByAdmin") ||
          selectedPracticeId
        }&providerId=${providerId}`,
      requestOptions
    )
      .then((response) => {
        console.log(response, "response");
        response.json();
      })
      .then((res) => {
        console.log("------------respu---------", res);
        // setUpdateModalNew(false);
        // providerListUpdate(true);
        // setIsCheckAll(false);
      });
  };

  const handleSearchFullNameChange = (e) => {
    const input = e.target.value;
    setSearchFullName(input);
  };
  const handleSearchProviderLocChange = (e) => {
    const input = e.target.value;
    setSearchProviderLoc(input);
  };
  const handleSearchNpiChange = (e) => {
    const input = e.target.value;
    setSearchNpi(input);
  };
  const handleSearchPrimaryLocChange = (e) => {
    const input = e.target.value;
    setSearchPrimaryLoc(input);
  };
  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };
  const filterData = (item) => {
    const searchNpiPattern = createSearchPattern(searchNpi);
    const searchFullNamePattern = createSearchPattern(searchFullName);
    const searchProviderLocPattern = createSearchPattern(searchProviderLoc);
    const searchPrimaryLocPattern = createSearchPattern(searchPrimaryLoc);

    const fullName = `${item?.firstName || ""} ${item?.middleName || ""} ${
      item?.lastName || ""
    }`
      .toLowerCase()
      .replace(/\s/g, "");
    const providerLocName = stillAtLocation(
      item?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
    );
    const providerLocationName = providerLocName
      .toLowerCase()
      .replace(/\s/g, "");
    const npi = (item?.npi || "").toLowerCase().replace(/\s/g, "");
    const primaryLocationName = getLocationString(item)
      .toLowerCase()
      .replace(/\s/g, "");

    return (
      searchFullNamePattern.test(fullName) &&
      searchProviderLocPattern.test(providerLocationName) &&
      searchNpiPattern.test(npi) &&
      searchPrimaryLocPattern.test(primaryLocationName)
    );
  };
  const getLocationString = (provider) => {
    const providerPrimaryLocation = provider?.location;
    return `${providerPrimaryLocation?.street1 ?? ""}
    ${providerPrimaryLocation?.street2 ?? ""}, 
    ${providerPrimaryLocation?.city ?? ""}, 
    ${providerPrimaryLocation?.state ?? ""} 
    ${providerPrimaryLocation?.zipCode ?? ""}`;
  };
  useEffect(() => {
    const newFilteredData = providerList?.filter(filterData);
    setUpdatedProviderList(newFilteredData);
  }, [searchNpi, searchFullName, searchProviderLoc, searchPrimaryLoc]);

  const sortNameData = () => {
    const sortedProviderData = [...updatedProviderList].sort((a, b) => {
      const fullNameA = `${(a?.lastName || "").trim()} ${(
        a?.middleName || ""
      ).trim()} ${(a?.firstName || "").trim()}`.toLowerCase();
      const fullNameB = `${(b?.lastName || "").trim()} ${(
        b?.middleName || ""
      ).trim()} ${(b?.firstName || "").trim()}`.toLowerCase();
      return sortOrder === "asc"
        ? fullNameB.localeCompare(fullNameA)
        : fullNameA.localeCompare(fullNameB);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setUpdatedProviderList(sortedProviderData);
  };
  const sortLocationName = () => {
    const sortedProviderData = [...updatedProviderList].sort((a, b) => {
      const locationA = getLocationString(a);
      const locationB = getLocationString(b);
      return sortOrder === "asc"
        ? locationA.localeCompare(locationB)
        : locationB.localeCompare(locationA);
    });
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setUpdatedProviderList(sortedProviderData);
  };

  const sortDataYes = () => {
    const sorted = [...updatedProviderList].sort((a, b) => {
      const providerLocNameA = stillAtLocation(
        a?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
      );
      const locationA = providerLocNameA.toLowerCase().replace(/\s/g, "");
      const providerLocNameB = stillAtLocation(
        b?.practiceAssignment?.find((role) => role?.roleTypeId === 2)?.termDate
      );
      const locationB = providerLocNameB.toLowerCase().replace(/\s/g, "");
      return sortOrder === "desc"
        ? locationA.localeCompare(locationB)
        : locationB.localeCompare(locationA);
    });
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setUpdatedProviderList(sorted);
  };

  const providerIdList = updatedProviderList.map((item) => item?.id);
  const firstValue = providerIdList[0];
  localStorage.setItem("providerIdList", firstValue);

  const handleViewProviderClick = (providerId) => {
    setViewProviderId(providerId); // Set the provider ID to render ViewProvider component
    window.localStorage.setItem("providerIdByAdmin", providerId);
  };

  // const [updatedProviderList, setUpdatedProviderList] = useState([]);

  // useEffect(() => {
  //   if (currentRecords.length > 0 && updatedProviderList.length === 0) {
  //     setUpdatedProviderList(currentRecords);
  //   }
  // }, [currentRecords]);
  // console.log(updatedProviderList, "updatedProviderList");
  const handleDelete = (providerId) => {
    //  filteredAllData.filter(
    //   (item) => item?.id !== providerId
    // );
    console.log(providerId, "providerIdeee");
    const remainingRecords = updatedProviderList.filter(
      (item) => item?.id !== providerId
    );
    setUpdatedProviderList(remainingRecords);
    localStorage.setItem("providerCount", remainingRecords.length);

    deleteProvider(providerId);
    setUpdatedProviderVal(remainingRecords);
    setRefreshData(!refreshData);
    // console.log(remainingRecords,"remainingRecords")
    // setFilteredAllData(updatedProviderList);
  };
  const [modalShow, setModalShow] = useState(false);
  const [viewProviderIdModal, setViewProviderIdModal] = useState(); // State to hold the provider ID for ViewProvider component
  const [creatNpi, setCreatNpi] = useState();
  console.log(currentRecords, updatedProviderList, "updatedProviderList");
  if (refreshData) {
    return <Spinner />;
  }
  return (
    <div>
      {isError && <ErrorServer />}
      {!isError && (
        <div>
          <div className="provider-actions">
            <div className="provider-button">
              {PersonRole !== "SQCNAdmin" && (
                <>
                  <div className="w-100 d-flex position-relative">
                    <div className="w-100 text-end">
                      <Button
                        data-testid="addprovider"
                        variant="primary"
                        onClick={() => {
                          setModalShow(true);
                          setViewProviderId(null);
                        }}
                        className="rounded-pill add_new_practice btn btn-primary"
                        disabled={viewProviderIdModal ? true : false}
                      >
                        <img src={plusIcon} alt="Search Icon" />
                        Add New Provider
                      </Button>
                    </div>
                    {modalShow && (
                      <AddProviderModal
                        data-testid="addprovidermodal"
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        isAdmin={true}
                        setViewProviderId={setViewProviderIdModal}
                        showCreatNpi={setCreatNpi}
                        providerList={updatedProviderList}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="provider-list-table">
            <Table data-testid="providerlisttable">
              <thead>
                <tr>
                  <th className="provider_Column">NPI</th>
                  <th className="provider_Column">Full name</th>
                  <th>Provider Location</th>
                  <th className="provider_Column1">Primary Location Name</th>
                  <th>Action</th>
                </tr>

                <tr className="provider_wrapper">
                  <td className="search_column">
                    <Form.Group
                      className="search-npi"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchNpi}
                        onChange={handleSearchNpiChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                  </td>

                  <td className="search_column">
                    <Form.Group
                      className="search-npi loc1"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchFullName}
                        onChange={handleSearchFullNameChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                    <button
                      className="sort_icon_arrow pl"
                      type="button"
                      onClick={sortNameData}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </td>
                  <td className="search_column">
                    <Form.Group
                      className="search-npi"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchProviderLoc}
                        onChange={handleSearchProviderLocChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                    <button
                      className="sort_icon_arrow pl"
                      type="button"
                      onClick={sortDataYes}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </td>

                  <td className="search_column">
                    <Form.Group
                      className="search-npi loc2"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Search"
                        autoComplete="off"
                        className="search-input new"
                        value={searchPrimaryLoc}
                        onChange={handleSearchPrimaryLocChange}
                        data-testid="LocationName"
                      />
                    </Form.Group>
                    <button
                      className="sort_icon_arrow pl"
                      type="button"
                      onClick={sortLocationName}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </td>
                </tr>
              </thead>

              {currentRecords?.length > 0 && (
                <tbody data-testid="ProviderData">
                  {currentRecords?.map((provider) => {
                    return (
                      <tr
                        className={
                          viewProviderId === provider.id
                            ? "tr_border_color"
                            : "tr_table"
                        }
                        key={provider?.id}
                      >
                        <td data-testid="providerPrimaryNpi">
                          {provider?.npi}
                        </td>

                        <td data-testid="providerName">
                          {provider?.practiceAssignment[0]?.status ===
                            "Pending" || provider?.status === "Pending" ? (
                            <>
                              <Link
                                key={provider?.id}
                                onClick={() =>
                                  handleViewProviderClick(provider.id)
                                }
                              >
                                {provider?.firstName}&nbsp;
                                {provider?.middleName}
                                &nbsp;
                                {provider?.lastName},{" "}
                                {provider?.titleType[0]?.title}
                              </Link>
                            </>
                          ) : (
                            <Link
                              key={provider?.id}
                              onClick={() =>
                                handleViewProviderClick(provider.id)
                              }
                            >
                              {provider?.firstName}&nbsp;{provider?.middleName}
                              &nbsp;
                              {provider?.lastName}
                            </Link>
                          )}
                        </td>
                        <td
                          className="still-at-locations"
                          data-testid="providerStillAtLocation"
                        >
                          {stillAtLocation(
                            provider?.practiceAssignment?.find(
                              (role) => role?.roleTypeId === 2
                            )?.termDate
                          )}
                        </td>

                        {provider?.location?.street1 ? (
                          <td data-testid="providerPrimaryLocation">
                            {`${provider?.location?.street1 ?? ""} ${
                              provider?.location?.street2 ?? ""
                            }, ${provider?.location?.city ?? ""}, 
                          ${provider?.location?.state ?? ""} ${
                              provider?.location?.zipCode ?? ""
                            }`}
                          </td>
                        ) : (
                          <td data-testid="providerPrimaryLocation">--</td>
                        )}
                        <>
                          <td
                            className="text-center"
                            data-testid="providerStatus"
                          >
                            <button
                              type="button"
                              className="icon_style"
                              onClick={() => handleDelete(provider.id)}
                            >
                              <img src={deleteIcon} alt="Sort Icon" />
                            </button>
                          </td>

                          <Modal
                            show={updateModalNew}
                            onHide={() => setUpdateModalNew(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="update-provider-modal"
                            data-testid="bulkupdateprovidermodal"
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <Row className="mt-3">
                                <h6 className="text-center">
                                  Are you sure you would like to proceed?
                                </h6>
                              </Row>
                              <Row className="mt-3">
                                {/* <p>{provider?.location}</p>
            <p>{provider?.address}</p> */}
                              </Row>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center modal-footer-transparent">
                              <button
                                onClick={() => setUpdateModalNew(false)}
                                className="white-btn"
                                data-testid="close-button"
                              >
                                Cancel
                              </button>

                              <button
                                className="blue-btn"
                                onClick={handleSubmit}
                                type="submit"
                                data-testid="createnewproviderbutton"
                              >
                                Yes, Confirm
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {currentRecords?.length > 0 && (
              <>
                <Pagination2
                  totalPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                  data-testid="admin_provider_list_pagination"
                />
              </>
            )}
          </div>

          {successMessage && (
            <div>
              <Alert variant="success" data-testid="successProvider">
                <img
                  src={updatedIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
                Provider information updated successfully
                <button
                  onClick={() => setSuccessMessage(false)}
                  className="close-icon-button"
                  data-testid="providerProfileupdatedclose"
                >
                  <img
                    src={closeIcon}
                    alt="Updated Icon"
                    className="updated-icon"
                  />
                </button>
              </Alert>
            </div>
          )}

          {viewProviderId && (
            <EditProviderPageAdmin
              practiceTitle={practiceTitle}
              selectedPracticeId={viewProviderId}
              practiceManagerId={practiceManagerId}
              providerId={window.localStorage.getItem("providerIdByAdmin")}
              setRefreshData={setRefreshData}
              setViewProviderId={setViewProviderId}
              viewProviderId={viewProviderId}
              setSuccessMessage={setSuccessMessage}
              refreshData={refreshData}
              providerListGetUpdate={providerListGetUpdate}
              titleData={titleData}
              prefixData={prefixData}
            />
          )}

          {viewProviderIdModal ? (
            <ViewProvider
              practiceTitle={practiceTitle}
              selectedPracticeId={viewProviderIdModal}
              // practiceManagerId={window.localStorage.getItem("practiceManagerId")}
              practiceManagerId={practiceManagerId}
              personRoleName={PersonRole}
              providerListGetUpdate={providerListGetUpdate}
              setRefreshData={setRefreshData}
              refreshData={refreshData}
              setViewProviderIdModal={setViewProviderIdModal}
              titleData={titleData}
              prefixData={prefixData}
            />
          ) : null}
          {creatNpi ? (
            <EditPracticeNewProviderAdmin
              practiceTitle={practiceTitle}
              practiceManagerId={practiceManagerId}
              selectedPracticeId={selectedPracticeId}
              providerListGetUpdate={providerListGetUpdate}
              creatNpi={creatNpi}
              setRefreshData={setRefreshData}
              refreshData={refreshData}
              setCreatNpi={setCreatNpi}
              titleData={titleData}
              prefixData={prefixData}
            />
          ) : null}

          <div className={`bulk-update-box ${isCheck.length > 0}`}>
            <Row>
              <Col md={8}>
                <p className="update-text">
                  By clicking &quot;Update&quot; you are confirming that all the
                  provider profiles you have selected are up to date.
                </p>
              </Col>
              <Col md={4} className="update-button">
                <button
                  className="white-btn-update"
                  onClick={onUpdateCancel}
                  data-testid="bulkupdateCancelButton"
                >
                  Cancel
                </button>
                <button
                  className="blue-btn-update"
                  //   onClick={() => setUpdateModal(true)}
                  type="submit"
                  data-testid="bulkupdateConfirmButton"
                >
                  Update
                </button>
              </Col>
            </Row>
          </div>
          {!(updatedProviderList?.length > 0) && (
            <div className="error-message" data-testid="noProviderData">
              <p>
                <img src={icon} alt="error-icon" className="error-icon"></img>
                Currently there are no providers at this Practice
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProviderListAdmin;
