import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useOutsideClick from "../../../Hooks/useOutsideClick";

function EditBoardSpecialty({
  specialtydata,
  specialtyDetailsByspecialtype,
  selectedSpecialtyIdfn,
  updatedSpltyName,
}) {
  const ref = useRef();
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    updatedSpltyName || ""
  );
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState();
  const [filterDropdown, setFilterDropdown] = useState(false);
  useOutsideClick(ref, () => {
    if (filterDropdown) {
      setFilterDropdown(false);
    }
  });

  useEffect(() => {
    if (updatedSpltyName) {
      setSelectedSpecialty(updatedSpltyName);
    } else if (specialtyDetailsByspecialtype) {
      setSelectedSpecialty(
        specialtyDetailsByspecialtype?.specialty?.specialtyName || ""
      );
    } else {
      setSelectedSpecialty("");
    }
  }, [specialtyDetailsByspecialtype]);

  const onChange = (event) => {
    const evValue = event.target.value;
    setSelectedSpecialty(evValue);
    setFilterDropdown(true);
    const spl = specialtydata?.filter(
      (item) =>
        item?.specialty?.specialtyName?.toLowerCase() === evValue?.toLowerCase()
    );
    if (spl.length > 0) {
      setSelectedSpecialty(spl[0]?.specialty?.specialtyName || "");
      setSelectedSpecialtyId(spl[0]?.specialty?.id);
    }
  };

  useEffect(() => {
    if (selectedSpecialtyId) {
      selectedSpecialtyIdfn(
        selectedSpecialtyId,
        selectedSpecialty.trim(),
        specialtydata
      );
    } else {
      selectedSpecialtyIdfn(null, selectedSpecialty.trim(), specialtydata);
    }
  }, [selectedSpecialty, selectedSpecialtyId, specialtydata]);

  return (
    <>
      <Form.Select
        as="select"
        value={selectedSpecialty}
        onChange={onChange}
        className="nb-input form-select form-select-sm"
        data-testid="specialty-input"
      >
        <option value="">---</option>
        {specialtydata?.map((item) => (
          <option
            value={item?.specialty?.specialtyName}
            key={item?.specialty?.id}
            data-testid={`dropdown-row${item?.specialty?.id}`}
          >
            {item?.specialty?.specialtyName}
          </option>
        ))}
      </Form.Select>
    </>
  );
}

export default EditBoardSpecialty;
