import React, { useEffect, useState } from "react";
import labels from "Static/Dictionary.json";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Button, Row, Col } from "react-bootstrap";
import cookies from "js-cookie";
import { formatDate } from "../../../../../../../Static/format.js";
import StateData from "Components/UI/Organisms/StateData";
import stateFieldData from "Static/state.json";

const EditLocation = (props) => {
  const {
    showEditData,
    handleAddClick,
    selectedPracticeIdByAdmin,
    setSuccess,
    setLocAdded,
    setLocDeleted,
    setEditView,
    passArrayToParent,
    updatedLocData,
  } = props;
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const [isSavedData, setIsSavedData] = useState(false);
  const [locationCount, setLocationCount] = useState(0);
  useEffect(() => {
    const largestLocationNumber = updatedLocData?.reduce((max, item) => Math.max(max, item.locationNumber), 0);
    setLocationCount(largestLocationNumber + 1);
  }, [updatedLocData]);
  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setStateName(name);
  };

  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  const validationSchema = Yup.object().shape({
    locationNumber: Yup.string().notRequired(),
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    // state: Yup.string().required("State Name cannot be empty"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    phoneNumber: Yup.string().required("Phone Number cannot be blank"),
    faxNumber: Yup.string().required("Fax Number cannot be blank"),
    startDate: Yup.string().notRequired(),
    termDate: Yup.string().notRequired(),
  });
  // const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const onSubmit = (values, { setSubmitting }) => {
    console.log("res-- Location Edit Form submitted:", values);
    // Set submitting to true to indicate form submission is in progress
    setSubmitting(true);
    const formatStartDate = new Date(values?.startDate);
    const formattedStartDate = formatDate(formatStartDate);
    const formatTermDate = new Date(values?.termDate);
    const formattedTermDate = formatDate(formatTermDate);
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    const editLocPayLoadData = {
      id: showEditData.id,
      locationName: values.locationName,
      locationNumber: values.locationNumber,
      street1: values.street1,
      street2: values.street2,
      street3: values.street3,
      city: values.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
      phoneNumber: [
        {
          number: values?.phoneNumber,
          phoneTypeId: 1,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 1
          )?.id,
        },
        {
          number: values?.faxNumber,
          phoneTypeId: 4,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 4
          )?.id,
        },
        {
          number: values?.schedulingNumber,
          phoneTypeId: 5,
          practiceId: selectedPracticeIdByAdmin,
          id: showEditData?.phoneNumber?.find(
            (phone) => phone.phoneTypeId === 5
          )?.id,
        },
      ],
      status: "Approved",
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      lastConfirmed: "2023-05-05T00:00:00",
      isDeleted: false,
    };
    const addLocPayLoadData = {
      locationReplicaId: 0,
      locationNumber: locationCount,
      locationName: values.locationName,
      street1: values.street1,
      street2: values.street2,
      street3: values.street3,
      city: values.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
      main_ContactNumber: values?.phoneNumber,
      fax_ContactNumber: values?.faxNumber,
      directScheduling_ContactNumber: values?.schedulingNumber,
      status: "Approved",
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      lastConfirmed: "2023-05-05T00:00:00",
      isDeleted: false,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(editLocPayLoadData),
    };
    const requestOptionsForAdd = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(addLocPayLoadData),
    };
    if(isEdit){
      fetch(
        base_url +
          `/api/v1/practices/${selectedPracticeIdByAdmin}/location/EditLocation`,
        requestOptions
      ).then((response) => {
        console.log(
          "Saved locationInfo editLocationInfo",
          response,
          response.status
        );
        if (response.status == 200) {
          setSuccess(true);
          setLocAdded(false);
          setLocDeleted(false);
          setEditView(false);
          setSubmitting(false);
          passArrayToParent(editLocPayLoadData);
          handleAddClick();
        }
      });
    } else {
      fetch(
        base_url +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location?isFromSqcn=true`,
        requestOptionsForAdd
      ).then((response) => {
        console.log(
          "Saved locationInfo AddLocationInfo",
          response,
          response.status
        );
        if (response.status == 200) {
          setSuccess(true);
          setLocAdded(true);
          setLocDeleted(false);
          setEditView(false);
          setSubmitting(false);
          passArrayToParent(addLocPayLoadData);
          handleAddClick();
        }
      });
    }
  };
  useEffect(() => {
    if (Object.keys(showEditData).length === 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [showEditData]);
  return (
    <Formik
      // key={showEditData.locationNumber}
      enableReinitialize
      initialValues={{
        locationNumber: showEditData.locationNumber || "",
        locationName: showEditData.locationName || "",
        street1: showEditData.street1 || "",
        street2: showEditData.street2 || "",
        street3: showEditData.street3 || "",
        city: showEditData.city || "",
        state: showEditData.state || "",
        zipCode: showEditData.zipCode || "",
        phoneNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 1)
            ?.number || "",
        faxNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 4)
            ?.number || "",
        schedulingNumber:
          showEditData?.phoneNumber?.find((phone) => phone?.phoneTypeId === 5)
            ?.number || "",
        status: "",
        updateUser: "",
        startDate:
          (showEditData?.startDate && new Date(showEditData?.startDate)) || "",
        termDate:
          (showEditData?.termDate && new Date(showEditData?.termDate)) || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, handleChange, values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="contact-section">
            <Row>
              <Col md={2}>
                <p className="details-label">{labels.LOCATION_NUMBER}</p>
                <Form.Group controlId="locationNumber">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="nb-input readonly-input"
                    value={isEdit ? values.locationNumber : locationCount}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <p className="details-label">{labels.LOCATION_NAME}</p>
                <Form.Group controlId="locationName">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="nb-input"
                    value={values.locationName}
                    onChange={handleChange}
                    isInvalid={!!errors.locationName}
                    placeholder={!values.locationName && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.locationName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <p className="details-label">{labels.ADDRESS}</p>
                <Form.Group controlId="street1">
                  <Form.Control
                    type="text"
                    value={values.street1}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!!errors.street1}
                    placeholder={!values.street1 && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.street1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4 d-flex flex-row-reverse">
              <Col md={6}>
                <Form.Group controlId="street2">
                  <Form.Control
                    type="text"
                    value={values.street2}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4 d-flex flex-row-reverse">
              <Col md={6}>
                <Form.Group controlId="street3">
                  <Form.Control
                    type="text"
                    value={values.street3}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <p className="details-label">{labels.CITY}</p>
                <Form.Group controlId="city">
                  <Form.Control
                    type="text"
                    value={values.city}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    placeholder={!values.city && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.STATE}</p>
                <StateData
                  placeholder={!values.state && isEdit && "--"}
                  id="practiceManagerMdId"
                  autoComplete="off"
                  className="nb-input"
                  stateFieldData={stateFieldData}
                  selectedStateIdfn={selectedStateIdfn}
                  validationError={
                    (values?.locationName?.length > 0 &&
                      !stateName?.length > 0) ||
                    (errors.locationName && !stateName?.length > 0)
                  }
                  validationError1={newState && stateName?.length > 1 && values.state === ""}
                  isSavedData={isSavedData}
                  setIsSavedData={setIsSavedData}
                  formState={values.state}
                />
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.ZIP_CODE}</p>
                <Form.Group controlId="zipCode">
                  <Form.Control
                    type="text"
                    value={values.zipCode}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!!errors.zipCode}
                    placeholder={!values.zipCode && isEdit && "--"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.PHONE_NUMBER_MAIN}</p>
                <Form.Group controlId="phoneNumber">
                  <Form.Control
                    type="text"
                    value={values.phoneNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!!errors.phoneNumber}
                    placeholder={!values.phoneNumber && isEdit && "--"}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <p className="details-label">{labels.FAX_NUMBER}</p>
                <Form.Group controlId="faxNumber">
                  <Form.Control
                    type="text"
                    value={values.faxNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    isInvalid={!!errors.faxNumber}
                    placeholder={!values.faxNumber && isEdit && "--"}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.faxNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.DIRECT_SCHEDULING_NUMBER}</p>
                <Form.Group controlId="schedulingNumber">
                  <Form.Control
                    type="text"
                    value={values.schedulingNumber}
                    autoComplete="off"
                    className="nb-input"
                    onChange={handleChange}
                    placeholder={!values.schedulingNumber && isEdit && "--"}
                    maxLength={12}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.START_DATE}</p>
                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "startDate", value: date },
                      })
                    }
                    placeholderText={!values.startDate && isEdit && "--"}
                    data-testid="startDate"
                    className="nb-input width-full"
                    defaultValue={new Date(values.startDate)}
                    isInvalid={!!errors.startDate}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={datePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </Col>
              <Col md={3}>
                <p className="details-label">{labels.TERM_DATE}</p>
                <div className="input-group search-container-date">
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={values.termDate}
                    minDate={values.startDate}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "termDate", value: date },
                      })
                    }
                    placeholderText={!values.termDate && isEdit && "--"}
                    data-testid="editproviderStartDate"
                    className="nb-input width-full"
                    value={new Date(values.termDate)}
                    isInvalid={!!errors.endDate}
                  />
                  <button
                    type="button"
                    className="search-button right cursor-auto"
                  >
                    <img src={datePickerIcon} alt="DatePicker Icon" />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mt-3 d-flex flex-row-reverse">
            <Col md={3} className="text-right">
              <Button
                type="submit"
                className="rounded-pill save_btn blue-btn"
                onSubmit={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EditLocation;
