/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import StateData from "Components/UI/Organisms/StateData";
import { formatDate } from "../../../../../../../Static/format.js";
import stateFieldData from "Static/state.json";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import * as Yup from "yup";

const AdminAddEditLocationForm = ({
  locationEditData,
  setShowForm,
  setEditView,
  setEditLocationPayLoad,
}) => {
  const AddPracticeLocationSchema = Yup.object().shape({
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    main_ContactNumber: Yup.string().required("Phone Number cannot be blank"),
    fax_ContactNumber: Yup.string().required("Fax Number cannot be blank"),
    startDate: Yup.string().notRequired(),
    endDate: Yup.string().notRequired(),
  });
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const [isSavedData, setIsSavedData] = useState(false);
  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setStateName(name);
  };

  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setIsSavedData(true);
    const formatStartDate = new Date(values?.startDate);
    const formattedStartDate = formatDate(formatStartDate);
    const formatTermDate = new Date(values?.termDate);
    const formattedTermDate = formatDate(formatTermDate);
    const locationData = {
      locationReplicaId: 1,
      locationNumber: values?.locationNumber,
      locationName: values?.locationName,
      street1: values?.street1,
      street2: values?.street2,
      street3: values?.street3,
      city: values?.city,
      state: stateCodeName?.stateCode ? stateCodeName?.stateCode : values.state,
      zipCode: values?.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
      main_ContactNumber: values.main_ContactNumber,
      fax_ContactNumber: values.fax_ContactNumber,
      directScheduling_ContactNumber: values.schedulingNumber,
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      status: "Approved",
    };
    setEditLocationPayLoad(locationData);
    // Prevent page reload
    setSubmitting(false); // Set submitting state to false
    setShowForm(false);
    // Clear the form data
    resetForm();


    setEditView(false);
  };
  // console.log("locationEditData==>", locationEditData);
  return (
    <>
      <Formik
        initialValues={locationEditData}
        enableReinitialize
        validationSchema={AddPracticeLocationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="contact-section">
              <Row>
                <Col md={2}>
                  <p className="details-label">{labels.LOCATION_NUMBER}</p>
                  <Form.Control
                    type="text"
                    id="practiceManagerMdId"
                    autoComplete="off"
                    className="nb-input readonly-input"
                    readOnly
                    value={values.locationNumber}
                  />
                </Col>
                <Col md={4}>
                  <p className="details-label">{labels.LOCATION_NAME}</p>
                  <Form.Control
                    type="text"
                    id="locationName"
                    autoComplete="off"
                    className="nb-input"
                    placeholder="--"
                    value={values.locationName}
                    onChange={handleChange}
                    isInvalid={!!errors.locationName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.locationName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <p className="details-label">{labels.ADDRESS}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street1"
                    autoComplete="off"
                    className="nb-input"
                    value={values.street1}
                    onChange={handleChange}
                    isInvalid={!!errors.street1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.street1}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-4 d-flex flex-row-reverse">
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street2"
                    autoComplete="off"
                    className="nb-input"
                    value={values.street2}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-4 d-flex flex-row-reverse">
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="street3"
                    autoComplete="off"
                    className="nb-input"
                    value={values.street3}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3}>
                  <p className="details-label">{labels.CITY}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="city"
                    autoComplete="off"
                    className="nb-input"
                    value={values.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.STATE}</p>
                  <StateData
                    placeholder="--"
                    id="practiceManagerMdId"
                    autoComplete="off"
                    className="nb-input"
                    stateFieldData={stateFieldData}
                    selectedStateIdfn={selectedStateIdfn}
                    validationError={
                      (values?.locationName?.length > 0 &&
                        !stateName?.length > 0) ||
                      (errors.locationName && !stateName?.length > 0)
                    }
                    validationError1={
                      newState && stateName?.length > 1 && values.state === ""
                    }
                    isSavedData={isSavedData}
                    setIsSavedData={setIsSavedData}
                    formState={values.state}
                  />
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.ZIP_CODE}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="zipCode"
                    autoComplete="off"
                    className="nb-input"
                    value={values.zipCode}
                    onChange={handleChange}
                    isInvalid={!!errors.zipCode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.PHONE_NUMBER_MAIN}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="phoneNumber"
                    autoComplete="off"
                    className="nb-input"
                    value={values.main_ContactNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.main_ContactNumber}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.main_ContactNumber}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3}>
                  <p className="details-label">{labels.FAX_NUMBER}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="faxNumber"
                    autoComplete="off"
                    className="nb-input"
                    value={values.fax_ContactNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.fax_ContactNumber}
                    maxLength={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fax_ContactNumber}
                  </Form.Control.Feedback>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.DIRECT_SCHEDULING_NUMBER}</p>
                  <Form.Control
                    type="text"
                    placeholder="--"
                    id="schedulingNumber"
                    autoComplete="off"
                    className="nb-input"
                    value={values.directScheduling_ContactNumber}
                    onChange={handleChange}
                    maxLength={12}
                  />
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.START_DATE}</p>
                  <div className="input-group search-container-date">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={values.startDate}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "startDate", value: date },
                        })
                      }
                      placeholderText="--"
                      data-testid="startDate"
                      className="nb-input width-full"
                      value={values.startDate || "--"}
                      isInvalid={!!errors.startDate}
                    />
                    <button
                      type="button"
                      className="search-button right cursor-auto"
                    >
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </button>
                  </div>
                </Col>
                <Col md={3}>
                  <p className="details-label">{labels.TERM_DATE}</p>
                  <div className="input-group search-container-date">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={values?.termDate}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "termDate", value: date },
                        })
                      }
                      placeholderText="--"
                      data-testid="editproviderStartDate"
                      className="nb-input width-full"
                      value={values?.termDate || "--"}
                      isInvalid={!!errors.endDate}
                      minDate={values?.startDate}
                    />
                    <button
                      type="button"
                      className="search-button right cursor-auto"
                    >
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="mt-3 d-flex flex-row-reverse">
              <Col md={3} className="text-right">
                <Button
                  variant="primary"
                  className="rounded-pill save_btn"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AdminAddEditLocationForm;
