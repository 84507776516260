/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import TabContent from "./TabContent";
import TabNavItem from "./TabNavItem";
import PracticeInformationPage from "../DataPages/PracticeInformationPage";
import ContactInformationPage from "../DataPages/ContactInformationPage";
import "./styles.css";
import { Alert, Container } from "react-bootstrap";
import closeIcon from "../ProviderList/images/closeIcon.svg";
import ApprovalcloseIcon from "./close-icon.svg";
import ApprovalIcon from "./approval-icon.svg";
import AddIcon from "../PracticeInformation/AddIcon.svg";
import RemoveIcon from "../PracticeInformation/locationRemove.svg";
import ProviderListPage from "../DataPages/ProviderListPage";
import updatedIcon from "../ProviderList/images/updatedIcon.svg";
import AlertWarning from "Components/UI/Atoms/AlertWarning";
import { useNavigate } from "react-router";

const HomePageTabs = ({
  locationListData,
  selectedPracticeId,
  practiceManagerId,
  isLocationServerError,
  isAdmin,
  PersonRole
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [bulkUpdateSuc, setBulkUpdateSuc] = useState(false);
  const [contactInfoEdit, setContactInfoEdit] = useState(false);
  const [practiceInfoEdit, setPracticeInfoEdit] = useState(false);
  const [practiceInfoFieldEdit, setPracticeInfoFieldEdit] = useState(false);
  const [addNewLocation, setAddNewLocation] = useState(false);
  const [addNewProvider, setAddNewProvider] = useState(false);
  const [removeLocation, setRemoveLocation] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    setAddNewProvider(addNewProvider)
  }, [addNewProvider]);

  useEffect(() => {
    const getData = localStorage.getItem("tab");
    console.log("gd", getData);
    setActiveTab(getData || "tab1");
  }, []);

  const handleClick = () => {
    setAddNewLocation(false);
    setRemoveLocation(false);
  };
  const handleProviderClick = (state) => {
    setAddNewProvider(state?.isCreate);
    navigation(
      "/",
      { state: { replace: true } }
    );
  }
  return (
    <React.Fragment>
      <Container>
        {bulkUpdateSuc && (
          <div>
            <Alert variant="success" data-testid="providerProfileupdated">
              <img
                src={updatedIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
              Provider profiles have been updated successfully
              <button
                onClick={() => setBulkUpdateSuc(false)}
                className="close-icon-button"
                data-testid="providerProfileupdatedclose"
              >
                <img
                  src={closeIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
              </button>
            </Alert>
          </div>
        )}
        {contactInfoEdit && (
          <div>
            <Alert variant="success" data-testid="providerProfileupdated">
              <img
                src={updatedIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
              Practice contact information updated successfully
              <button
                onClick={() => setContactInfoEdit(false)}
                className="close-icon-button"
                data-testid="providerProfileupdatedclose"
              >
                <img
                  src={closeIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
              </button>
            </Alert>
          </div>
        )}
        {practiceInfoFieldEdit && (
          <div>
            <Alert variant="success" data-testid="providerProfileupdated">
              <img
                src={updatedIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
              Practice information updated successfully
              <button
                onClick={() => setPracticeInfoFieldEdit(false)}
                className="close-icon-button"
                data-testid="providerProfileupdatedclose"
              >
                <img
                  src={closeIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
              </button>
            </Alert>
          </div>
        )}
        {practiceInfoEdit && (
          <div>
            <Alert variant="warning" data-testid="practiceInfoupdated">
              <img
                src={ApprovalIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
              Changes to practice information sent for SQCN Admin review
              <button
                onClick={() => setPracticeInfoEdit(false)}
                className="close-icon-button1"
                data-testid="practiceInfoupdatedclose"
              >
                <img
                  src={ApprovalcloseIcon}
                  alt="Updated Icon"
                  className="updated-icon"
                />
              </button>
            </Alert>
          </div>
        )}
        {addNewLocation && (
          <AlertWarning
            ApprovalIcon={AddIcon}
            ApprovalcloseIcon={ApprovalcloseIcon}
            AlertText="New location to practice sent for SQCN Admin review"
            ALertHandleClick={handleClick}
          />
        )}
        {addNewProvider && (
          <AlertWarning
            ApprovalIcon={AddIcon}
            ApprovalcloseIcon={ApprovalcloseIcon}
            AlertText="New Provider successfully submitted for SQCN review"
            ALertHandleClick={handleProviderClick}
          />
        )}
        {removeLocation && (
          <AlertWarning
            ApprovalIcon={RemoveIcon}
            ApprovalcloseIcon={ApprovalcloseIcon}
            AlertText="Removal of location sent for SQCN Admin review"
            ALertHandleClick={handleClick}
          />
        )}
      </Container>
      <div className="Tabs mb-4">
        <ul className="nav">
          <TabNavItem
            title="Practice Information"
            id="tab1"
            data-testid="practiceinformationtab"
            className="tab1"
            activeTab={activeTab}
            setActiveTab={() => {
              setActiveTab("tab1"),
                setContactInfoEdit(false),
                setBulkUpdateSuc(false),
                localStorage.setItem("tab", "tab1");
            }}
          />
          <TabNavItem
            title="Contact Information"
            id="tab2"
            data-testid="contactinformationtab"
            activeTab={activeTab}
            setActiveTab={() => {
              setActiveTab("tab2"),
                setBulkUpdateSuc(false),
                setPracticeInfoEdit(false),
                setAddNewLocation(false),
                setRemoveLocation(false),
                localStorage.setItem("tab", "tab2");
            }}
          />
          <TabNavItem
            title="Provider List"
            id="tab3"
            data-testid="providerlisttab"
            activeTab={activeTab}
            setActiveTab={() => {
              setActiveTab("tab3"),
                setContactInfoEdit(false),
                setPracticeInfoEdit(false),
                setAddNewLocation(false),
                setRemoveLocation(false),
                localStorage.setItem("tab", "tab3");
            }}
          />
        </ul>
        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <PracticeInformationPage
              selectedPracticeId={selectedPracticeId}
              locationListData={locationListData}
              isLocationServerError={isLocationServerError}
              practiceManagerId={practiceManagerId}
              handleClick={handleClick}
              practiceInfoUpdate={() => setPracticeInfoEdit(true)}
              practiceInformationUpdate={() => {
                setPracticeInfoEdit(false);
                setPracticeInfoFieldEdit(false);
              }}
              AddNewLocationInfo={() => setAddNewLocation(true)}
              removeLocation={() => setRemoveLocation(true)}
              practiceInfoFieldEdit={() => setPracticeInfoFieldEdit(true)}
              PersonRole={PersonRole}
            />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <ContactInformationPage
              selectedPracticeId={selectedPracticeId}
              practiceManagerId={practiceManagerId}
              contactInfoUpdate={() => setContactInfoEdit(true)}
              contactpracticeInfoUpdate={() => setContactInfoEdit(false)}
            />
          </TabContent>
          <TabContent id="tab3" activeTab={activeTab}>
            <ProviderListPage
              selectedPracticeId={selectedPracticeId}
              practiceManagerId={practiceManagerId}
              locationListData={locationListData}
              providerListUpdate={() => setBulkUpdateSuc(true)}
              addNewProviderList={setAddNewProvider}
              handleProviderClick={handleProviderClick}
              isAdmin={isAdmin}
              PersonRole={PersonRole}
            />
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePageTabs;
