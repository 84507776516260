import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import PracticeInformation from "../PracticeInformation/PracticeInformation";

const PracticeInformationPage = ({
  selectedPracticeId,
  locationListData,
  isLocationServerError,
  locationTitle,
  address,
  city,
  state,
  zipCode,
  phoneNumber,
  selectedLocationId,
  locationMap,
  practiceInfoUpdate,
  onItemSelected,
  practiceInformationUpdate,
  AddNewLocationInfo,
  handleClick,
  removeLocation,
  practiceInfoFieldEdit,
  practiceManagerId,
  PersonRole
}) => {
  const [isData, setIsData] = useState([]);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const fetchPracticeRecords = () => {
    fetch(
      base_url +
      `/api/v1/practices/${selectedPracticeId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeRecords();
    };
    fetch();
  }, [selectedPracticeId]);
  return (
    <>
      <PracticeInformation
        practiceInformationData={isData}
        // isServerError={error}
        practiceManagerId={practiceManagerId}
        locationTitle={locationTitle}
        selectedPracticeId={selectedPracticeId}
        practiceInfoUpdate={practiceInfoUpdate}
        AddNewLocationInfo={AddNewLocationInfo}
        practiceInfoFieldEdit={practiceInfoFieldEdit}
        fetchPracticeRecords={fetchPracticeRecords}
        removeLocation={removeLocation}
        practiceInformationUpdate={practiceInformationUpdate}
        address={address}
        city={city}
        state={state}
        zipCode={zipCode}
        phoneNumber={phoneNumber}
        locationListData={locationListData}
        isLocationServerError={isLocationServerError}
        selectedLocationId={selectedLocationId}
        locationMap={locationMap}
        onItemSelected={onItemSelected}
        handleAlertClick={handleClick}
        PersonRole={PersonRole}
      />
    </>
  );
};

export default PracticeInformationPage;
