/* eslint-disable indent */
import React, { useState } from "react";
import { Button } from "../../UI/Atoms/Button";
import ConfirmModal from "../../UI/Atoms/ConfirmModal";
import cookies from "js-cookie";

function SqcnAdminAccess(props) {
    const [SqcnConfirmModal, setSqcnConfirmModal] = useState(false);
    const [sqcnAccessError, setSqcnAccessError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = cookies.get("access");
    const base_url = process.env.REACT_APP_SERVER_URL1;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    // const sqcnRole = props?.personData?.[0]?.practiceAssignment?.filter(item =>
    //     [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.includes(item?.roleTypeId)
    // );
    const handleSubmit = async () => {
        setIsLoading(true);
        const payLoad = {
            personId: props?.personData?.[0]?.id,
            isAdminAccess: props?.personData &&
                props?.personData?.length > 0 &&
                props?.personData?.[0]?.accessType === 13 ? false : true
        };
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "access-control-allow-credentials": "true",
                "ocp-apim-subscription-key": subscriptionKey,
                "api-supported-versions": "1.0",
            },
            body: JSON.stringify(payLoad),
        };
        fetch(
            base_url + "/api/v1/providers/ProvideSQCNAdminAccess",
            requestOptions
        ).then((response) => {
            console.log("rep", response, response.status);
            if (response.status === 200) {
                setSqcnConfirmModal(false);
                props?.fetchMdofficeData();
                setIsLoading(false);
                if (
                    props?.personData &&
                    props?.personData?.length > 0 &&
                    props?.personData?.[0]?.accessType === 13
                ) {
                    props?.sqcnReviewRemove(true);
                } else if (
                    props?.personData &&
                    props?.personData?.length > 0 &&
                    props?.personData?.[0]?.accessType === null
                ) {
                    props?.sqcnAccessApprove(true);
                } else {
                    setSqcnAccessError(true);
                }
            } else {
                setSqcnAccessError(true);
                setIsLoading(false);
            }
        });

    };
    return (
        <React.Fragment>
            <Button
                text={props?.buttonText}
                className="manage-sqcn-button"
                disabled={props?.mdOfficevalue?.length < 1}
                type="submit"
                onClick={() => {
                    const hasValidRole = props?.personData[0]?.practiceAssignment?.some(
                        (role) => role?.isDeleted === false
                    );

                    if (props?.personData[0]?.accessType !== 13 && hasValidRole) {
                        // props?.sqcnReviewReject();
                        setSqcnConfirmModal(true);
                    } else {
                        setSqcnConfirmModal(true);
                        setSqcnAccessError(false);
                    }
                }}
            />

            {props?.personData &&
                props?.personData?.length > 0 &&
                props?.personData?.[0]?.accessType === 13
                ? (
                    <ConfirmModal
                        updateModal={SqcnConfirmModal}
                        updateModalClose={() => setSqcnConfirmModal(false)}
                        handleSubmit={handleSubmit}
                        bodyText="Are you sure we can remove SQCN admin access for this person"
                        headerText="Confirm Remove SQCN Admin Access"
                        serverError={sqcnAccessError}
                        isLoadingWaiting={isLoading}
                    />
                ) : (
                    <ConfirmModal
                        updateModal={SqcnConfirmModal}
                        updateModalClose={() => setSqcnConfirmModal(false)}
                        handleSubmit={handleSubmit}
                        bodyText="Are you sure we can provide SQCN admin access for this person"
                        headerText="Confirm Provide SQCN Admin Access"
                        serverError={sqcnAccessError}
                    />
                )
            }
        </React.Fragment>
    );
}

export default SqcnAdminAccess;
