/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import cookies from "js-cookie";
import "./style.css";
import labels from "Static/Dictionary.json";
import ErrorServer from "Components/Hooks/Error404";
import { Formik } from "formik";
import * as Yup from "yup";
// import AddIcon from "./AddIcon.svg";
import { Form } from "react-bootstrap";
import ApproveIcon from "Components/UI/Atoms/ApproveIcon";
// import SqcnApprovalIcon from "Components/UI/Atoms/SqcnApprovalIcon";
import PracticeSpecialty from "Components/UI/Organisms/PracticeSpecialty";
import LocationList from "./LocationList";
import { useFetch } from "Components/Hooks/Fetch";
import EditCareManager from "Components/Admin/Components/Templates/ManageSqcnPractice/PracticeInformation/EditPractice/EditCareManager";

const manageStyle = {
  display: "none",
};

const EditPracticeInformation = ({
  specialtyDetailsByspecialtype,
  specialtydata,
  // careManager,
  isServerError,
  locationListData,
  isLocationServerError,
  selectedPracticeId,
  setEditModeBox,
  practiceInfoUpdate,
  practiceNameL,
  tinData,
  emrData,
  practiceNpiData,
  practiceSpeialtyData,
  fetchPracticeRecords,
  practiceInfoFieldEdit,
  fetchPracticeChangeRecords,
  practiceManagerId,
  careManagers,
  fetchCareManagerRecords,
}) => {
  const [sltdSpltyId, setSltdSpltyId] = useState(null);
  const [spltyName, setSpltyName] = useState(null);
  const [newSpecialty, setNewSpecialty] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [practiceInformationData, setPracticeInformationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("sp", sltdSpltyId, spltyName, newSpecialty);
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const [practiceMasterData, setPracticeMasterData] = useState(null);
  const [teleHealthData, setTeleHealthData] = useState([]);

  const { data: masterData } = useFetch(
    "/api/v1/practices/GetPracticeMasterData"
  );

  const { data: teleHealth } = useFetch("/api/v1/contact/GetTeleHealthData");

  useEffect(() => {
    if (teleHealth) {
      setTeleHealthData(teleHealth);
    }
  }, [teleHealth]);
  useEffect(() => {
    if (masterData) {
      setPracticeMasterData(masterData);
    }
  }, [masterData]);

  const selectedSpecialtyIdfn = (id, name, data = []) => {
    console.log("spltyId", id, name, data);
    const fName = data?.filter(
      (item) => item?.name?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewSpecialty(false);
    } else {
      setNewSpecialty(true);
    }
    setSltdSpltyId(id);
    setSpltyName(name);
  };
  const fetchPracticeEditRecords = () => {
    fetch(base_url + `/api/v1/practices/${selectedPracticeId}`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPracticeInformationData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeEditRecords();
    };
    fetch();
  }, [selectedPracticeId]);

  const prevSelectedPracticeId = useRef(selectedPracticeId);
  useEffect(() => {
    if (prevSelectedPracticeId.current !== selectedPracticeId) {
      setEditModeBox(false);
    }
    prevSelectedPracticeId.current = selectedPracticeId;
  }, [selectedPracticeId, setEditModeBox]);

  const initialValues = {
    practiceName: practiceInformationData?.practiceNameLegal || "",
    tin: practiceInformationData?.practiceGroup?.tin || "",
    npi: practiceInformationData?.primaryNpi || "",
    emr: practiceInformationData?.emr?.emr || "",
    telemedicine: practiceInformationData?.telehealth || "",
    specialty:
      practiceInformationData?.specialtySpecialtyType[0]?.specialty
        ?.specialtyName || "",
    acceptingNewMedicaid:
      practiceInformationData?.takingMedicaidPatients === true
        ? "Yes"
        : "No" || false,
    acceptingNewpatients:
      practiceInformationData?.takingNewPatients === true
        ? "Yes"
        : "No" || false,
    lowerAge: practiceInformationData?.lowerAgeLimit || "",
  };
  console.log("iv", initialValues);
  const practiceInformationSchema = Yup.object().shape({
    practiceName: Yup.string().required("Name cannot be blank"),
    tin: Yup.string().required("Tax Identification Number cannot be blank"),
    npi: Yup.string()
      .min(10, "Practice NPI should be 10 digits")
      .max(10, "Practice NPI should be 10 digits")
      .matches(/^[0-9]+$/, "Must be only digits"),
    emr: Yup.string().required("EMR cannot be blank"),
    lowerAge: Yup.string().required(
      "Lower Age Limit for New Patients cannot be blank"
    ),
  });
  const validateFieldsFn = (prevData, currentData) => {
    console.log("prd", prevData, "cd", currentData);
    console.log("cd", currentData);
    if (
      (practiceNameL?.status !== "Pending"
        ? prevData?.practiceNameLegal?.toLowerCase() ===
          currentData?.practiceNameLegal?.toLowerCase()
        : true) &&
      (tinData?.status !== "Pending"
        ? prevData?.practiceGroup?.tin?.toLowerCase() ===
          currentData?.tin?.toLowerCase()
        : true) &&
      (emrData?.status !== "Pending"
        ? prevData?.emr?.emr?.toLowerCase() === currentData?.emr?.toLowerCase()
        : true) &&
      (practiceSpeialtyData?.status !== "Pending"
        ? prevData?.specialtyDetailsByspecialtype?.specialty?.id.toString() ===
          currentData?.specialty
        : true)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const [inputList, setInputList] = useState([]);
  const onSubmitFun = async (values) => {
    console.log("vv", values);
    setIsLoading(true);
    let careManagerPayLoad = [];
    if (inputList.length > 0) {
      inputList.map((data) => {
        let list = {};
        list["mdofficeId"] = data?.mdofficeId || "";
        list["firstName"] = data?.firstName || "";
        list["lastName"] = data?.lastName || "";
        list["middleName"] = data?.middleName || "";
        list["suffix"] = data?.suffix || "";
        list["prefixId"] = parseInt(data?.prefixId) || 0;
        list["personId"] = data?.personId || 0;
        list["phone"] = data?.phone || "";
        list["email"] = data?.email || "";
        list["psc"] = data?.psc || 0;
        list["ext"] = data?.ext || "";
        list["isPrimarySqcn"] = false;
        list["title"] = data?.title || "";
        list["titleId"] = parseInt(data?.titleId) || null;
        careManagerPayLoad.push(list);
      });
    }
    const requestOptionsCareManager = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(careManagerPayLoad),
    };
    let careManagerStatus = "";
    setTimeout(async () => {
    await fetch(
      base_url +
        `/api/v1/practices/InsertCareManagerDetails?practiceid=${selectedPracticeId}`,
      requestOptionsCareManager
    )
      .then((response) => {
        // console.log("st", response.status);
        careManagerStatus = response?.status;
      })
      .then((res) => {
        // console.log("ress", res, careManagerStatus);
        if (Number(careManagerStatus) === 200) {
          setErrorMessage();
          const validFields = validateFieldsFn(
            practiceInformationData,
            payLoad
          );
          if (validFields) {
            practiceInfoUpdate(true);
          } else {
            practiceInfoFieldEdit(true);
          }
          setEditModeBox(false);
          fetchPracticeRecords();
          fetchPracticeChangeRecords();
          fetchCareManagerRecords();
          setIsLoading(false);
        } else {
          setErrorMessage(res?.message);
          setIsLoading(false);
        }
      });
    }, 1000);
    const payLoad = {
      updateUser: practiceManagerId,
      practiceNameLegal:
        practiceNameL?.status !== "Pending" ? values.practiceName : undefined,
      primaryNpi:
        practiceNpiData?.status !== "Pending" ? values.npi : undefined,
      tin: tinData?.status !== "Pending" ? values.tin : undefined,
      emr: emrData?.status !== "Pending" ? values.emr : undefined,
      telehealth: values?.telemedicine,
      specialty:
        practiceSpeialtyData?.status !== "Pending"
          ? sltdSpltyId?.toString()
          : undefined,
      takingMedicaidPatients:
        values?.acceptingNewMedicaid === "Yes" ? true : false,
      takingNewPatients: values?.acceptingNewpatients === "Yes" ? true : false,
      lowerAgeLimit: values?.lowerAge,
    };
    console.log("pay", payLoad);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "access-control-allow-credentials": "true",
        "ocp-apim-subscription-key": subscriptionKey,
        "api-supported-versions": "1.0",
      },
      body: JSON.stringify(payLoad),
    };
    let status = "";
    await fetch(base_url + `/api/v1/practices/${selectedPracticeId}`, requestOptions)
      .then((response) => {
        // console.log("st", response.status);
        status = response?.status;
      })
      .then((res) => {
        // console.log("ress", res, status);
        if (Number(status) === 200) {
          setErrorMessage();
          const validFields = validateFieldsFn(
            practiceInformationData,
            payLoad
          );
          if (validFields) {
            practiceInfoUpdate(true);
          } else {
            practiceInfoFieldEdit(true);
          }
          setEditModeBox(false);
          fetchPracticeRecords();
          fetchPracticeChangeRecords();
          setIsLoading(false);
        } else {
          setErrorMessage(res?.message);
          setIsLoading(false);
        }
      });
  };
  return (
    <React.Fragment>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={practiceInformationSchema}
          onSubmit={onSubmitFun}
          enableReinitialize={true}
        >
          {({ errors, values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {isServerError && <ErrorServer />}
              {!isServerError && (
                <div>
                  <div className="main-block">
                    <div className="block">
                      <div className="title" data-testid="title">
                        {labels.NAME_LEGAL_NAME}{" "}
                        {practiceNameL?.status === "Pending" && (
                          <span>
                            <ApproveIcon />
                          </span>
                        )}
                      </div>
                      {practiceNameL?.status === "Pending" ? (
                        <div className="info">{practiceNameL?.newValue}</div>
                      ) : (
                        <div className="info">
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceName"
                            autoComplete="off"
                            value={values.practiceName}
                            defaultValue={values.practiceName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceName}
                          </Form.Control.Feedback>
                        </div>
                      )}
                    </div>
                    <div className="block">
                      <div className="title">
                        {labels.TAX_IDENTIFICATION_NUMBER}{" "}
                        {tinData?.status === "Pending" && (
                          <span>
                            <ApproveIcon />
                          </span>
                        )}
                      </div>
                      {tinData?.status === "Pending" ? (
                        <div className="info">
                          {tinData?.newValue2 || tinData?.newValue}
                        </div>
                      ) : (
                        <div className="info">
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="tin"
                            autoComplete="off"
                            value={values.tin}
                            defaultValue={values.tin}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.tin}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.tin}
                          </Form.Control.Feedback>
                        </div>
                      )}
                    </div>
                    <div className="block">
                      <div className="title">
                        {labels.PRACTICE_NPI}{" "}
                        {practiceNpiData?.status === "Pending" && (
                          <span>
                            <ApproveIcon />
                          </span>
                        )}
                      </div>
                      <div className="info">
                        {practiceNpiData?.status === "Pending" ? (
                          <div className="info" data-testid="NpiData">
                            {practiceNpiData?.newValue}
                          </div>
                        ) : (
                          <div className="info">
                            <Form.Control
                              type="text"
                              placeholder="---"
                              id="npi"
                              autoComplete="off"
                              value={values.npi}
                              defaultValue={values.npi}
                              className="nb-input"
                              onChange={handleChange}
                              isInvalid={!!errors.npi}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.npi}
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="main-block">
                    <div className="block">
                      <div className="title">
                        {labels.EMR_VENDOR}{" "}
                        {emrData?.status === "Pending" && (
                          <span>
                            <ApproveIcon />
                          </span>
                        )}
                      </div>
                      {emrData?.status === "Pending" ? (
                        <div className="info">
                          {emrData?.newValue2 || emrData?.newValue}
                        </div>
                      ) : (
                        // <div className="info">
                        //   <Form.Control
                        //     type="text"
                        //     placeholder="---"
                        //     id="emr"
                        //     autoComplete="off"
                        //     value={values.emr}
                        //     defaultValue={values.emr}
                        //     className="nb-input"
                        //     onChange={handleChange}
                        //     isInvalid={!!errors.emr}
                        //   />
                        //   <Form.Control.Feedback type="invalid">
                        //     {errors.emr}
                        //   </Form.Control.Feedback>
                        // </div>


<div className="info">
<Form.Select
  size="sm"
  type="text"
  placeholder="---"
  id="emr"
  name="emr"
  autoComplete="off"
  value={values.emr}
  defaultValue={values.emr}
  className="nb-select"
  onChange={handleChange}

  // onChange={(e) => {
  //   handleChange({
  //     target: { name: "emr", value: e.value },
  //   });
  //   let emRid =
  //     e.target[e.target.selectedIndex].getAttribute(
  //       "data-emrId"
  //     );
  //   handleChange({
  //     target: { name: "emRid", value: emRid },
  //   });
  // }}
>
  <option value={values.emr}>{values.emr}</option>
  {practiceMasterData?.emRs.length > 0 &&
    practiceMasterData?.emRs.map((emrItem, index) => (
      <option
        key={emrItem.emr + index}
        data-emrId={emrItem.id}
        value={emrItem.emr}
        selected={emrItem.id === values.emRid}
      >
        {emrItem.emr}
      </option>
    ))}
</Form.Select>
{/* <Form.Control.Feedback type="invalid">
{errors.emr}
</Form.Control.Feedback> */}
</div>
                      )}
                    </div>
                    <div className="block">
                      <div className="title">{labels.TELEMEDICINE}</div>
                      {/* <div className="info">
                        <Form.Control
                          type="text"
                          placeholder="---"
                          id="telemedicine"
                          autoComplete="off"
                          value={values.telemedicine}
                          defaultValue={values.telemedicine}
                          className="nb-input"
                          onChange={handleChange}
                          isInvalid={!!errors.telemedicine}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.telemedicine}
                        </Form.Control.Feedback>
                      </div> */}


<Form.Select
                        size="sm"
                        placeholder="---"
                        id="telemedicine"
                        autoComplete="off"
                        value={values.telemedicine}
                        defaultValue={values.telemedicine}
                        className="nb-select"
                        onChange={handleChange}
                      >
                        <option value={values.telehealth}>
                          {values.telehealth}
                        </option>
                        {teleHealthData.length > 0 &&
                          teleHealthData.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                    <div className="block">
                      <div className="title">
                        {labels.PRACTICE_SPECIALTY}{" "}
                        {practiceSpeialtyData?.status === "Pending" && (
                          <span>
                            <ApproveIcon />
                          </span>
                        )}{" "}
                      </div>
                      {practiceSpeialtyData?.status === "Pending" ? (
                        <div className="info">
                          {practiceSpeialtyData?.newValue}
                        </div>
                      ) : (
                        <div className="info">
                          <PracticeSpecialty
                            specialtydata={specialtydata}
                            specialtyDetailsByspecialtype={
                              specialtyDetailsByspecialtype
                            }
                            selectedSpecialtyIdfn={selectedSpecialtyIdfn}
                            validationError={!spltyName?.length > 0}
                            validationError1={
                              newSpecialty && spltyName?.length > 1
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="main-block">
                    <div className="block">
                      <div className="title">
                        {labels.ACCEPTING_NEW_MEDICAID_PATIENTS}
                      </div>
                      <div className="info mt-2" data-testid="AcceptingNewPatients">
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          id="acceptingNewMedicaid"
                          className="contactradio"
                          data-testid="acceptingNewMedicaid"
                          value="Yes"
                          checked={values?.acceptingNewMedicaid === "Yes"}
                          defaultChecked={
                            values?.acceptingNewMedicaid === "Yes"
                          }
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          value="No"
                          id="acceptingNewMedicaid"
                          data-testid="acceptingNewMedicaid"
                          checked={values?.acceptingNewMedicaid === "No"}
                          defaultChecked={values?.acceptingNewMedicaid === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="block">
                      <div className="title">
                        {labels.ACCEPTING_NEW_PATIENTS}
                      </div>
                      <div className="info mt-2" data-testid="AcceptingNewPatients">
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          id="acceptingNewpatients"
                          className="contactradio"
                          data-testid="acceptingNewpatients"
                          value="Yes"
                          checked={values?.acceptingNewpatients === "Yes"}
                          defaultChecked={
                            values?.acceptingNewpatients === "Yes"
                          }
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          value="No"
                          id="acceptingNewpatients"
                          data-testid="acceptingNewpatients"
                          checked={values?.acceptingNewpatients === "No"}
                          defaultChecked={values?.acceptingNewpatients === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="block">
                      <div className="title">
                        {labels.LOWER_AGE_LIMIT_FOR_NEW_PATIENTS}
                      </div>
                      <div className="info">
                        <Form.Control
                          type="text"
                          placeholder="---"
                          id="lowerAge"
                          autoComplete="off"
                          value={values.lowerAge}
                          defaultValue={values.lowerAge}
                          className="nb-input"
                          onChange={handleChange}
                          isInvalid={!!errors.lowerAge}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lowerAge}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <EditCareManager
                    careManagers={careManagers}
                    inputList={inputList}
                    setInputList={setInputList}
                    selectedPracticeIdByAdmin={selectedPracticeId}
                  />
                </div>
              )}
              {isLocationServerError && (
                <div className="contact-details">
                  No location data available.
                </div>
              )}
              {!isLocationServerError && (
                <div>
                  <LocationList
                    locationListData={locationListData}
                    manageStyle={manageStyle}
                  />
                </div>
              )}
              <div className="add-location-footer">
                {errorMessage && (
                  <p className="Error-message">{errorMessage}</p>
                )}
                <p>Please confirm that all information is correct</p>
                <div className="add-location-buttons">
                  <button
                    className="white-btn"
                    data-testid="EditCancelButton"
                    onClick={() => setEditModeBox(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="blue-btn"
                    type="submit"
                    data-testid="EditConfirmButton"
                    value="submit"
                    // disabled={!spltyName &&
                    //   !practiceInformationData?.specialtySpecialtyType[0]?.specialty?.specialtyName ||
                    //   isLoading}
                  >
                    {isLoading ? "Saving..." : "Confirm and Save"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default EditPracticeInformation;
