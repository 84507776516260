/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useOutsideClick from "../../../Hooks/useOutsideClick";

function StateData({
  stateFieldData,
  selectedStateIdfn,
  validationError,
  validationError1,
  formState,
}) {
  const ref = useRef();
  const [selectedState, setselectedState] = useState("");
  useEffect(() => {
    setselectedState(formState);
  }, [formState])
  const [selectedStateId, setselectedStateId] = useState();
  const [filterDropdown, setFilterDropdown] = useState(false);
  useOutsideClick(ref, () => {
    if (filterDropdown) {
      setFilterDropdown(false);
    }
  });
  console.log("st", selectedState);
  const onChange = (event) => {
    const evValue = event.target.value;
    setselectedState(event.target.value);
    setFilterDropdown(true);
    const spl = stateFieldData.filter(
      (item) => item.stateName.toLowerCase() === evValue.toLowerCase()
    );
    if (spl.length > 0) {
      setselectedState(spl[0].stateName);
      setselectedStateId(spl[0].id);
    }
  };
  const onSearch = (searchTerm, id) => {
    const id1 = id;
    const searchTerm1 = searchTerm;
    setselectedState(searchTerm1);
    setselectedStateId(id1);
  };
  useEffect(() => {
    if (selectedStateId && selectedState&& stateFieldData){
      selectedStateIdfn(selectedStateId, selectedState.trim(), stateFieldData);
    } else {
      selectedStateIdfn(null, selectedState?.trim(), stateFieldData);
    }
  }, [selectedState, selectedStateId, stateFieldData]);

  return (
    <>
      <Form.Control
        type="text"
        value={selectedState}
        placeholder="---"
        onChange={onChange}
        onKeyDown={(e) =>
          [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "0",
            "!",
            "@",
            "#",
            "$",
            "%",
            "^",
            "&",
            "*",
            "(",
            ")",
            "-",
            "_",
            "+",
            "=",
            "Enter",
          ].includes(e.key) && e.preventDefault()
        }
        className="nb-input"
        isInvalid={validationError || validationError1}
      />
      {validationError && (
        <Form.Control.Feedback type="invalid">
          State Name cannot be blank
        </Form.Control.Feedback>
      )}{" "}
      {validationError1 && (
        <Form.Control.Feedback type="invalid">
          Please select valid State
        </Form.Control.Feedback>
      )}
      {filterDropdown && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="dropdown"
          data-testid="dropdown-row-1"
          onClick={() => setFilterDropdown(!filterDropdown)}
          ref={ref}
        >
          {stateFieldData
            ?.filter((item) => {
              const searchTerm = selectedState?.toLowerCase();
              const stateNewName = item?.stateName?.toLowerCase();
              return (
                (searchTerm && stateNewName?.startsWith(searchTerm)) ||
                stateNewName === searchTerm
              );
            })
            .slice(0, 10)
            .map((item) => (
              <button
                onClick={() => onSearch(item?.stateName, item?.id)}
                className="dropdown-row"
                key={item?.id}
                data-testid={`dropdown-row${item.id}`}
              >
                {item?.stateName}
              </button>
            ))}
        </div>
      )}
    </>
  );
}

export default StateData;
