/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import "./style.css";
import labels from "Static/Dictionary.json";
import cookies from "js-cookie";
import ErrorServer from "Components/Hooks/Error404";
import AddPracticeLocation from "./AddPracticeLocation";
import { Col, Row } from "react-bootstrap";
import EditPracticeInformation from "./EditPracticeInformation";
import ApproveIcon from "Components/UI/Atoms/ApproveIcon";
import { useFetch } from "Components/Hooks/Fetch";
import LocationList from "./LocationList";

const PracticeInformation = ({
  practiceInformationData,
  isServerError,
  locationListData,
  selectedPracticeId,
  isLocationServerError,
  practiceInfoUpdate,
  practiceInformationUpdate,
  AddNewLocationInfo,
  handleAlertClick,
  removeLocation,
  fetchPracticeRecords,
  practiceInfoFieldEdit,
  practiceManagerId,
  PersonRole
}) => {
  console.log("pd", practiceInformationData);
  const [editMode, setEditMode] = useState(false);
  const [locationBox, setLocationBox] = useState(false);
  const [changeFieldData, setChangeFieldData] = useState([]);
  const token = cookies.get("access");
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const { data: specialtydata = [] } = useFetch("/api/v1/component/getpracticespecialities");
  const specialtyDetailsByspecialtype =
    practiceInformationData?.specialtySpecialtyType?.length > 0 ?
      practiceInformationData?.specialtySpecialtyType?.find(
        (item) => item?.specialtyType?.typeSpecialty === "Practice Specialty"
      ) : "";
  // const { data: contactData = [] } = useFetch(
  //   `/api/v1/practices/GetCareManagerforPractice?practiceId=${selectedPracticeId}`
  // );
  const [careManagerData, setCareManagerData] = useState([]);
  const [displayNames, setDisplayNames] = useState("");
  const fetchCareManagerRecords = () => {
    fetch(
      base_url +
      `/api/v1/practices/GetCareManagerforPractice?practiceId=${selectedPracticeId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCareManagerData(data);
        const formattedCareManagerNames = data?.map(manager => {
          const fullName = `${manager?.firstName} ${manager?.middleName || ""} ${manager?.lastName}`?.trim();
          return fullName;
        });
        setDisplayNames(formattedCareManagerNames?.join(", "));
      });
  };

  useEffect(() => {
    const fetch = async () => {
      fetchCareManagerRecords();
    };
    fetch();
  }, [selectedPracticeId]);

  const fetchPracticeChangeRecords = () => {
    fetch(
      base_url +
      `/api/v1/practices/GetPracticeChangeFieldsByPracticeId?practiceId=${selectedPracticeId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChangeFieldData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeChangeRecords();
    };
    fetch();
  }, [selectedPracticeId]);

  const practiceName =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "PracticeNameLegal"
    ) : "";
  const tin =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "tin"
    ) : "";
  const emrData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "emr"
    ) : "";
  const practiceNpiData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "PrimaryNpi"
    ) : "";
  const practiceSpeialtyData =
    changeFieldData?.length > 0 ? changeFieldData?.find(
      (item) => item?.field === "specialty"
    ) : "";
  const handleEditClick = () => {
    setEditMode(true);
    practiceInformationUpdate();
  };
  return (
    <React.Fragment>
      {editMode ? (
        <div>
          <EditPracticeInformation
            practiceInformationData={practiceInformationData}
            specialtyDetailsByspecialtype={specialtyDetailsByspecialtype}
            specialtydata={specialtydata}
            careManager={displayNames}
            isServerError={isServerError}
            practiceNameL={practiceName}
            tinData={tin}
            emrData={emrData}
            practiceNpiData={practiceNpiData}
            practiceSpeialtyData={practiceSpeialtyData}
            locationListData={locationListData}
            selectedPracticeId={selectedPracticeId}
            practiceManagerId={practiceManagerId}
            isLocationServerError={isLocationServerError}
            practiceInfoUpdate={practiceInfoUpdate}
            practiceInfoFieldEdit={practiceInfoFieldEdit}
            fetchPracticeRecords={fetchPracticeRecords}
            fetchPracticeChangeRecords={fetchPracticeChangeRecords}
            setEditModeBox={() => setEditMode(false)}
            careManagers={careManagerData}
            fetchCareManagerRecords={fetchCareManagerRecords}
          />
        </div>
      ) : (
        <div>
          {locationBox ? (
            <div>
              <AddPracticeLocation
                selectedPracticeId={selectedPracticeId}
                practiceManagerId={practiceManagerId}
                AddNewLocationInfo={AddNewLocationInfo}
                removeLocation={removeLocation}
                handleAlertClick={handleAlertClick}
                setLocationBox={() => setLocationBox(false)}
                PersonRole ={PersonRole}
              />
            </div>
          ) : (
            <div>
              {isServerError && <ErrorServer />}
              {!isServerError && (
                <div>
                  <Row className="mb-4">
                    <Col md={10}></Col>
                    <Col md={2}>
                      {PersonRole !== "SQCNAdmin" &&
                        <button
                          className="edit-contact-button"
                          onClick={handleEditClick}
                        >
                          Edit
                        </button>
                      }
                    </Col>
                  </Row>
                  <div>
                    <div className="main-block">
                      <div className="block">
                        <div className="title" data-testid="title">
                          {labels.NAME_LEGAL_NAME}{" "}
                          {practiceName?.status === "Pending" && (
                            <span>
                              <ApproveIcon />
                            </span>
                          )}
                        </div>
                        {practiceName?.newValue ||
                          practiceInformationData?.practiceNameLegal ? (
                          <div className="info" data-testid="NameData">
                            {practiceName?.newValue ||
                              practiceInformationData?.practiceNameLegal}
                          </div>
                        ) : (
                          <div className="info" data-testid="NoNameData">
                            --
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">
                          {labels.TAX_IDENTIFICATION_NUMBER}{" "}
                          {tin?.status === "Pending" && (
                            <span>
                              <ApproveIcon />
                            </span>
                          )}
                        </div>
                        {tin?.newValue2 || tin?.newValue ||
                          practiceInformationData?.practiceGroup?.tin ? (
                          <div className="info" data-testid="TinData">
                            {tin?.newValue2 || tin?.newValue ||
                              practiceInformationData.practiceGroup.tin}
                          </div>
                        ) : (
                          <div className="info" data-testid="NoTinData">
                            --
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">
                          {labels.PRACTICE_NPI}{" "}
                          {practiceNpiData?.status === "Pending" && (
                            <span>
                              <ApproveIcon />
                            </span>
                          )}
                        </div>
                        {practiceNpiData?.newValue ||
                          practiceInformationData?.primaryNpi ? (
                          <div className="info" data-testid="NpiData">
                            {practiceNpiData?.newValue ||
                              practiceInformationData.primaryNpi}
                          </div>
                        ) : (
                          <div className="info" data-testid="NoNpiData">
                            --
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="main-block">
                      <div className="block">
                        <div className="title">
                          {labels.EMR_VENDOR}{" "}
                          {emrData?.status === "Pending" && (
                            <span>
                              <ApproveIcon />
                            </span>
                          )}
                        </div>
                        {emrData?.newValue2 || emrData?.newValue ||
                          practiceInformationData?.emr?.emr ? (
                          <div className="info" data-testid="EmrData">
                            {emrData?.newValue2 || emrData?.newValue ||
                              practiceInformationData.emr.emr}
                          </div>
                        ) : (
                          <div className="info" data-testid="NoEmrData">
                            --
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">{labels.TELEMEDICINE}</div>
                        {practiceInformationData?.telehealth ? (
                          <div className="info" data-testid="TelehealthData">
                            {practiceInformationData.telehealth}
                          </div>
                        ) : (
                          <div className="info" data-testid="NoTelehealthData">
                            --
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">
                          {labels.PRACTICE_SPECIALTY}{" "}
                          {practiceSpeialtyData?.status === "Pending" && (
                            <span>
                              <ApproveIcon />
                            </span>
                          )}
                        </div>
                        {practiceSpeialtyData?.newValue ||
                          specialtyDetailsByspecialtype?.specialty?.specialtyName ?
                          (
                            <div className="info" data-testid="SpecialtyData">
                              {practiceSpeialtyData?.newValue ||
                                specialtyDetailsByspecialtype?.specialty?.specialtyName
                              }
                            </div>
                          ) : (
                            <div className="info" data-testid="NoSpecialtyData">
                              --
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="main-block">
                      <div className="block">
                        <div className="title">
                          {labels.ACCEPTING_NEW_MEDICAID_PATIENTS}
                        </div>
                        {practiceInformationData?.takingMedicaidPatients ===
                          true ? (
                          <div className="info" data-testid="AcceptingMedicaid">
                            Yes
                          </div>
                        ) : (
                          <div
                            className="info"
                            data-testid="NotAcceptingMedicaid"
                          >
                            No
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">
                          {labels.ACCEPTING_NEW_PATIENTS}
                        </div>
                        {practiceInformationData?.takingNewPatients === true ? (
                          <div
                            className="info"
                            data-testid="AcceptingNewPatients"
                          >
                            Yes
                          </div>
                        ) : (
                          <div
                            className="info"
                            data-testid="NotAcceptingNewPatients"
                          >
                            No
                          </div>
                        )}
                      </div>
                      <div className="block">
                        <div className="title">
                          {labels.LOWER_AGE_LIMIT_FOR_NEW_PATIENTS}
                        </div>
                        {practiceInformationData?.lowerAgeLimit ? (
                          <div className="info" data-testid="LowerAgeLimitData">
                            {practiceInformationData.lowerAgeLimit}
                          </div>
                        ) : (
                          <div
                            className="info"
                            data-testid="noLowerAgeLimitData"
                          >
                            --
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="main-block">
                      <div className="block">
                        <div className="title">{labels.CARE_MANAGERS}</div>
                        {displayNames ? (
                          <p className="info" data-testid="CareManagerName">
                            {displayNames}
                          </p>
                        ) : (
                          <p className="info" data-testid="NoCareManagerName">
                            --
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isLocationServerError && <div className="contact-details">No location data available.</div>}
              {!isLocationServerError && (
                <div>
                  <LocationList
                    locationListData={locationListData}
                    setLocationBox={() => setLocationBox(true)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PracticeInformation;
