import React, { useState, useEffect } from "react";
import { Table, Col, Form, Row, Button } from "react-bootstrap";
import { Formik } from "formik";
import datePickerIcon from "../../../../../../../images/datepicker_icon.svg";
import searchIcon from "../../../../../../../images/search.svg";
import sortIcon from "../../../../../../../images/sort_icon.svg";
import deleteIcon from "../../../../../../../images/delete_icon.svg";
import upArrowIcon from "../../../../../../../images/up_arrow_icon.svg";
import downArrowIcon from "../../../../../../../images/down_arrow_icon.svg";
import * as Yup from "yup";
import stateFieldData from "Static/state.json";
//import cookies from "js-cookie";
import { formatDate } from "../../../../../../../Static/format.js";
import Pagination2 from "../../../../UI/Atoms/Pagination2";
import AdminAddEditLocationForm from "./AdminAddEditLocationForm";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import StateData from "Components/UI/Organisms/StateData";
// import { formatDate } from "../../../../../../../Static/format.js";

function AdminAddLocationForm(props) {
  const [stateName, setStateName] = useState(null);
  const [newState, setNewState] = useState();
  const [locationData, setLocationData] = useState([]);
  const [isSavedData, setIsSavedData] = useState(false);
  const [isLocationData, setIsLocationData] = useState(false);
  const [editData, setEditData] = useState({});

  const [searchLocNum, setSearchLocNum] = useState("");
  const [searchLocName, setSearchLocName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchPhoneNum, setSearchPhoneNum] = useState("");
  const [searchFaxNum, setSearchFaxNum] = useState("");
  const [searchScheduleNum, setSearchScheduleNum] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [editLocationPayLoad, setEditLocationPayLoad] = useState({});
  // const [localLocationData, setLocalLocationData] = useState(false);
  const [payload, setPayload] = useState([]);

  const handleSearchLocNumChange = (e) => {
    const input = e.target.value;
    setSearchLocNum(input);
  };

  const handleSearchLocNameChange = (e) => {
    const input = e.target.value;
    setSearchLocName(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchPhoneNumChange = (e) => {
    const input = e.target.value;
    setSearchPhoneNum(input);
  };

  const handleSearchFaxNumChange = (e) => {
    const input = e.target.value;
    setSearchFaxNum(input);
  };

  const handleSearchScheduleNumChange = (e) => {
    const input = e.target.value;
    setSearchScheduleNum(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  const createSearchPattern = (input) => {
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };

  const filterData = (item) => {
    const locationNumber = item?.locationNumber?.toString().replace(/\s/g, "");
    const locationName = item?.locationName?.toLowerCase().replace(/\s/g, "");
    const phoneNumber = item?.main_ContactNumber?.toString().replace(/\s/g, "");
    const faxNumber = item?.fax_ContactNumber?.toString().replace(/\s/g, "");
    const schedulingNumber = item?.directScheduling_ContactNumber
      ?.toString()
      .replace(/\s/g, "");
    const address = `${item?.street1 || ""} ${item?.street2 || ""} ${
      item?.city || ""
    } ${item?.state || ""} ${item?.zipCode || ""}`
      ?.toLowerCase()
      .replace(/\s/g, "");
    const startDate = item?.startDate
      ? new Date(item.startDate)?.toLocaleDateString()?.replace(/\s/g, "")
      : "";
    const endDate = item?.termDate
      ? new Date(item.termDate)?.toLocaleDateString()?.replace(/\s/g, "")
      : "";
    const searchLocationNumPattern = createSearchPattern(searchLocNum);
    const searchLocationNamePattern = createSearchPattern(searchLocName);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchPhoneNumberPattern = createSearchPattern(searchPhoneNum);
    const searchFaxNumberPattern = createSearchPattern(searchFaxNum);
    const searchSchedulingNumberPattern =
      createSearchPattern(searchScheduleNum);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);
    return (
      searchLocationNumPattern.test(locationNumber) &&
      searchLocationNamePattern.test(locationName) &&
      searchAddressPattern.test(address) &&
      searchPhoneNumberPattern.test(phoneNumber) &&
      searchFaxNumberPattern.test(faxNumber) &&
      searchSchedulingNumberPattern.test(schedulingNumber) &&
      searchStartDatePattern.test(startDate) &&
      searchEndDatePattern.test(endDate)
    );
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const sortingData = () => {
    let sortedData = [...locationData].sort((a, b) => {
      if (!sortConfig) return 0;
      const isAscending = sortConfig.direction === "ascending" ? 1 : -1;
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return -1 * isAscending;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return 1 * isAscending;
      }
      return 0;
    });
    setLocationData(sortedData);
  }

  useEffect(() => {
    sortingData();
  }, [sortConfig]);
  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  useEffect(() => {
    const updatedLocationData = locationData?.map((location) => {
      if (location.locationNumber === editLocationPayLoad.locationNumber) {
        return editLocationPayLoad;
      }
      return location;
    });
    setLocationData([...updatedLocationData]);
    localStorage.setItem(
      "adminLocationData",
      JSON.stringify([...updatedLocationData])
    );
  }, [editLocationPayLoad]);
  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem("adminLocationData")) || [];
    const newFilteredData = localStorageData?.filter(filterData);
    setLocationData(newFilteredData);
  }, [
    searchLocNum,
    searchLocName,
    searchAddress,
    searchPhoneNum,
    searchFaxNum,
    searchScheduleNum,
    searchStartDate,
    searchEndDate,
  ]);
  const [locationNumber, setLocationNumber] = useState(1);
  useEffect(() => {
    if (props.isAddView) {
      const maxLocationNumber = locationData.reduce(
        (max, item) => Math.max(max, item.locationNumber),
        0
      );
      setLocationNumber(maxLocationNumber + 1);
    }
  }, [props.isAddView, locationData]);
  const initialValues = {
    locationReplicaId: 1,
    locationNumber: locationNumber,
    locationName: "",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    faxNumber: "",
    schedulingNumber: "",
    status: "",
    updateUser: "",
    startDate: "",
    termDate: "",
  };
  const AddPracticeLocationSchema = Yup.object().shape({
    locationName: Yup.string().required("Location Name cannot be blank"),
    street1: Yup.string().required("Street1 cannot be blank"),
    city: Yup.string().required("City Name cannot be blank"),
    zipCode: Yup.string().required("Zip Code cannot be blank"),
    phoneNumber: Yup.string().required("Phone Number cannot be blank"),
    faxNumber: Yup.string().required("Fax Number cannot be blank"),
    startDate: Yup.string().notRequired(),
    endDate: Yup.string().notRequired(),
  });
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsSavedData(true);
    setIsLocationData(true);

    const formatStartDate = new Date(values?.startDate);
    const formattedStartDate = formatDate(formatStartDate);
    const formatTermDate = new Date(values?.termDate);
    const formattedTermDate = formatDate(formatTermDate);
    const payLoad = {
      locationReplicaId: values?.locationReplicaId,
      locationNumber: values?.locationNumber,
      locationName: values?.locationName,
      street1: values?.street1,
      street2: values?.street2,
      street3: values?.street3,
      city: values?.city,
      state: stateCodeName?.stateCode,
      zipCode: values?.zipCode,
      phoneNumber: [],
      // [
      //   { number: values?.phoneNumber, phoneTypeId: 1, practiceId: 0 },
      //   { number: values?.faxNumber, phoneTypeId: 4, practiceId: 0 },
      //   { number: values?.schedulingNumber, phoneTypeId: 5, practiceId: 0 },
      // ],
      status: "Approved",
      updateUser: "SYSTEM",
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
    };
    setPayload((prevData) => [...prevData, payLoad]);
    const locationPayload = {
      locationReplicaId: values?.locationReplicaId,
      locationName: values?.locationName,
      locationNumber: values?.locationNumber,
      street1: values?.street1,
      street2: values?.street2,
      street3: values?.street3,
      city: values?.city,
      state: stateCodeName?.stateCode,
      zipCode: values?.zipCode,
      startDate: values?.startDate && formattedStartDate,
      termDate: values?.termDate && formattedTermDate,
      main_ContactNumber: values?.phoneNumber,
      fax_ContactNumber: values?.faxNumber,
      directScheduling_ContactNumber: values?.schedulingNumber,
      sourceSystem: "SQCN",
      updateUser: "SYSTEM",
      status: "Approved",
      phoneNumber: [],
    };
    setLocationData((prevData) => [...prevData, locationPayload]);
    // localStorage.setItem("adminLocationData", JSON.stringify([...locationData]));
    // console.log("locationPayload==>", locationPayload);
    // props.setLocationPayload((prevData) => {
    //   const locationNumber = prevData.length + 1;
    //   const newLocationPayload = { ...locationPayload, locationNumber };
    //   return [...prevData, newLocationPayload];
    // });
    // const locationData = {
    //   locationName: values?.locationName,
    //   locationNumber: values?.locationNumber,
    //   street1: values?.street1,
    //   street2: values?.street2,
    //   street3: values?.street3,
    //   city: values?.city,
    //   state: stateCodeName?.stateCode,
    //   zipCode: values?.zipCode,
    //   startDate: values?.startDate && formattedStartDate,
    //   termDate: values?.termDate && formattedTermDate,
    //   main_ContactNumber: values.phoneNumber,
    //   fax_ContactNumber: values.faxNumber,
    //   directScheduling_ContactNumber: values.schedulingNumber,
    //   sourceSystem: "SQCN",
    //   updateUser: "SYSTEM",
    //   status: "Approved",
    // };
    // setLocalLocationData((prevData) => {
    //   const locationNumber = prevData.length + 1;
    //   const newLocationPayload = { ...locationPayload, locationNumber };
    //   return [...prevData, newLocationPayload];
    // });
    // console.log("localLocationData==>", localLocationData);
    props.setShowForm(false);
    // Clear the form data
    resetForm();

    // Prevent page reload
    setSubmitting(false); // Set submitting state to false
  };
  useEffect(() => {
    localStorage.setItem("adminLocationData", JSON.stringify([...locationData]));
    const updatedLocationData = locationData.map(location => ({
      ...location,
      phoneNumber: [
        {
          number: location.main_ContactNumber,
          phoneTypeId: 1,
        },
        {
          number: location.fax_ContactNumber,
          phoneTypeId: 4,
        },
        {
          number: location.directScheduling_ContactNumber,
          phoneTypeId: 5,
        },
      ].filter(phone => phone.number), // This will remove any phone objects where number is undefined or empty
    }));
    props.setLocationPayload(updatedLocationData);
  }, [payload]);

  const selectedStateIdfn = (id, name, data = []) => {
    const fName = data?.filter(
      (item) => item?.stateName?.toLowerCase() === name?.toLowerCase()
    );
    if (fName?.length > 0) {
      setNewState(false);
    } else {
      setNewState(true);
    }
    setStateName(name);
  };
  const stateCodeName = stateFieldData?.find(
    (item) => item?.stateName?.toLowerCase() === stateName?.toLowerCase()
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  let currentRows = [...locationData].slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(locationData.length / rowsPerPage);
  useEffect(() => {
    if (
      currentPage > 1 &&
      locationData.length <= (currentPage - 1) * rowsPerPage
    ) {
      setCurrentPage(1);
    }
  }, [locationData, currentPage, rowsPerPage]);
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleEditView = (num) => {
    props.setAddView(false);
    props.setEditView(true);
    setEditData(locationData?.find((item) => item.locationNumber === num));
    setSelectedLocationId(num);
  };
  // console.log("currentRows==>", currentRows);
  const handleDelete = (locationNumber) => {
    setLocationData(locationData.filter((item) => item.locationNumber !== locationNumber));
    localStorage.setItem("adminLocationData", JSON.stringify(locationData.filter((item) => item.locationNumber !== locationNumber)));
  }
  const handleAction = (direction, index) => {
    let newLocationData = [...locationData];

    if (direction === "up" && index !== 0) {
      [newLocationData[index - 1], newLocationData[index]] = [newLocationData[index], newLocationData[index - 1]];
    } else if (direction === "down" && index !== newLocationData.length - 1) {
      [newLocationData[index + 1], newLocationData[index]] = [newLocationData[index], newLocationData[index + 1]];
    }
    // Reorder locationNumber in ascending order
    newLocationData = newLocationData.map((item, index) => ({
      ...item,
      locationNumber: index + 1,
    }));
    setLocationData(newLocationData);
    localStorage.setItem("adminLocationData", JSON.stringify(newLocationData));
  };
  return (
    <React.Fragment>
      <div className="add-location">
        {isLocationData && (
          <div className="practice-location-table">
            <Table data-testid="providerLocationtable">
              <thead>
                <tr>
                  <th
                    className="location_number search-table-font"
                    style={{ width: "5%" }}
                  >
                    Location Number
                  </th>
                  <th className="location_name search-table-font">
                    Location Name
                  </th>
                  <th className="location_name search-table-font">Address</th>
                  <th
                    className="phone-head other_number search-table-font"
                    style={{ width: "12%" }}
                  >
                    Phone Number
                  </th>
                  <th
                    className="phone-head1 other_number search-table-font"
                    style={{ width: "12%" }}
                  >
                    Fax Number
                  </th>
                  <th
                    className="phone-head2 other_number search-table-font"
                    style={{ width: "12%" }}
                  >
                    Direct Scheduling Number
                  </th>
                  <th className="phone-head1 location_date search-table-font">
                    <span className="header-icons">
                      Start Date
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </span>
                  </th>
                  <th className="phone-head1 location_date search-table-font">
                    <span className="header-icons">
                      Term Date
                      <img src={datePickerIcon} alt="DatePicker Icon" />
                    </span>
                  </th>
                  <th className="search-table-font">Action</th>
                </tr>
                <tr className="search_row">
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchLocNum}
                        onChange={handleSearchLocNumChange}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("locationNumber")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchLocName}
                        onChange={handleSearchLocNameChange}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("locationName")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchAddress}
                        onChange={handleSearchAddressChange}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("street1")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        value={searchPhoneNum}
                        onChange={handleSearchPhoneNumChange}
                        placeholder={"Search"}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("phoneNumber")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        value={searchFaxNum}
                        onChange={handleSearchFaxNumChange}
                        placeholder={"Search"}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("faxNumber")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        value={searchScheduleNum}
                        onChange={handleSearchScheduleNumChange}
                        placeholder={"Search"}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("schedulingNumber")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        placeholder={"Search"}
                        value={searchStartDate}
                        onChange={handleSearchStartDateChange}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("startDate")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="input-group search-container">
                      <input
                        type="text"
                        value={searchEndDate}
                        onChange={handleSearchEndDateChange}
                        placeholder={"Search"}
                        className="table_search search-table-font"
                      />
                      <button type="submit" className="search-button left ">
                        <img src={searchIcon} alt="Search Icon" />
                      </button>
                      <button
                        type="button"
                        className="search-button right"
                        onClick={() => handleSort("termDate")}
                      >
                        <img src={sortIcon} alt="Sort Icon" />
                      </button>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody data-testid="LocationData">
                {currentRows?.length > 0 &&
                  currentRows?.map((location, index) => (
                    <tr key={location?.id} className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "row-background"}>
                      <td
                        data-testid="locationNum"
                        className={
                          selectedLocationId === location.locationNumber
                            ? "locationNum tr_border_color_grey"
                            : "locationNum Secondary1"
                        }

                      >
                        {location?.locationNumber}
                      </td>
                      <td
                        className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}
                      >
                        <button
                          type="button"
                          onClick={() => handleEditView(location?.locationNumber)}
                          className="td_button"
                        >
                          {location?.locationName}
                        </button>
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.street1 || "--"} {location?.street2 || ""}{" "}
                        {location?.street3 || ""}, {location?.city},{" "}
                        {location?.state}, {location?.zipCode}
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.main_ContactNumber || "--"}
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.fax_ContactNumber || "--"}
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.directScheduling_ContactNumber || "--"}
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.startDate &&
                          new Date(location.startDate).toLocaleDateString()}
                      </td>
                      <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey" : "Secondary1"}>
                        {location?.termDate &&
                          new Date(location.termDate).toLocaleDateString()}
                      </td>
                      {currentRows?.length === 1 ? (
                        <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey text-center" : "Secondary1 text-center"}></td>
                      ) : (
                        <td className={selectedLocationId === location.locationNumber ? "tr_border_color_grey text-center header-icons" : "Secondary1 text-center header-icons"}>
                          {index > 0 ? (
                            <>
                              <button
                                type="button"
                                className="icon_style"
                                onClick={() => handleDelete(location.locationNumber)}
                              >
                                <img src={deleteIcon} alt="Sort Icon" />
                              </button>
                              <button
                                type="button"
                                className="icon_style"
                                onClick={() => handleAction("up", index)}
                              >
                                <img src={upArrowIcon} alt="Sort Icon" />
                              </button>
                            </>
                          ) : null}
                          {index < currentRows.length - 1 && (
                            <button
                              type="button"
                              className="icon_style"
                              onClick={() => handleAction("down", index)}
                            >
                              <img src={downArrowIcon} alt="Sort Icon" />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
            {currentRows?.length > 0 && (
              <>
                <Pagination2
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                  data-testid="admin_add_location_form_pagination"
                />
              </>
            )}
          </div>
        )}

        {props.isEditView && (
          <>
            <AdminAddEditLocationForm
              locationEditData={editData}
              AddPracticeLocationSchema={AddPracticeLocationSchema}
              setShowForm={props.setShowForm}
              isEditView={props.isEditView}
              setEditView={props.setEditView}
              setEditLocationPayLoad={setEditLocationPayLoad}
            />
          </>
        )}
        {props.isAddView && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={AddPracticeLocationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, errors, values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                {props.showForm && (
                  <div className="contact-section">
                    <Row>
                      <Col md={2}>
                        <p className="details-label">
                          {labels.LOCATION_NUMBER}
                        </p>
                        <Form.Control
                          type="text"
                          id="practiceManagerMdId"
                          autoComplete="off"
                          className="nb-input readonly-input"
                          placeholder="--"
                          readOnly
                          value={values.locationNumber}
                        />
                      </Col>
                      <Col md={4}>
                        <p className="details-label">{labels.LOCATION_NAME}</p>
                        <Form.Control
                          type="text"
                          id="locationName"
                          autoComplete="off"
                          className="nb-input"
                          placeholder="--"
                          value={values.locationName}
                          onChange={handleChange}
                          isInvalid={!!errors.locationName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.locationName}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={6}>
                        <p className="details-label">{labels.ADDRESS}</p>
                        <Form.Control
                          type="text"
                          placeholder="--"
                          id="street1"
                          autoComplete="off"
                          className="nb-input"
                          value={values.street1}
                          onChange={handleChange}
                          isInvalid={!!errors.street1}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.street1}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row className="mt-4 d-flex flex-row-reverse">
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          placeholder="--"
                          id="street2"
                          autoComplete="off"
                          className="nb-input"
                          value={values.street2}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 d-flex flex-row-reverse">
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          placeholder="--"
                          id="street3"
                          autoComplete="off"
                          className="nb-input"
                          value={values.street3}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={3}>
                        <p className="details-label">{labels.CITY}</p>
                        <Form.Control
                          type="text"
                          placeholder="--"
                          id="city"
                          autoComplete="off"
                          className="nb-input"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3}>
                        <p className="details-label">{labels.STATE}</p>
                        <StateData
                          placeholder="--"
                          id="practiceManagerMdId"
                          autoComplete="off"
                          className="nb-input"
                          stateFieldData={stateFieldData}
                          selectedStateIdfn={selectedStateIdfn}
                          validationError={
                            (values?.locationName?.length > 0 &&
                              !stateName?.length > 0) ||
                            (errors.locationName && !stateName?.length > 0)
                          }
                          validationError1={newState && stateName?.length > 1}
                          isSavedData={isSavedData}
                          setIsSavedData={setIsSavedData}
                        />
                      </Col>
                      <Col md={3}>
                        <p className="details-label">{labels.ZIP_CODE}</p>
                        <Form.Control
                          type="tel"
                          maxLength={10}
                          placeholder="--"
                          id="zipCode"
                          autoComplete="off"
                          className="nb-input"
                          value={values.zipCode}
                          onChange={handleChange}
                          isInvalid={!!errors.zipCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.zipCode}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3}>
                        <p className="details-label">
                          {labels.PHONE_NUMBER_MAIN}
                        </p>
                        <Form.Control
                          type="tel"
                          placeholder="--"
                          id="phoneNumber"
                          autoComplete="off"
                          className="nb-input"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                          maxLength={12}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={3}>
                        <p className="details-label">{labels.FAX_NUMBER}</p>
                        <Form.Control
                          type="tel"
                          placeholder="--"
                          id="faxNumber"
                          autoComplete="off"
                          className="nb-input"
                          value={values.faxNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.faxNumber}
                          maxLength={12}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.faxNumber}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3}>
                        <p className="details-label">
                          {labels.DIRECT_SCHEDULING_NUMBER}
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="--"
                          id="schedulingNumber"
                          autoComplete="off"
                          className="nb-input"
                          value={values.schedulingNumber}
                          onChange={handleChange}
                          maxLength={12}
                        />
                      </Col>
                      <Col md={3}>
                        <p className="details-label">{labels.START_DATE}</p>
                        <div className="input-group search-container-date">
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={values.startDate}
                            onChange={(date) =>
                              handleChange({
                                target: { name: "startDate", value: date },
                              })
                            }
                            placeholderText="--"
                            data-testid="startDate"
                            className="nb-input width-full"
                            value={values.startDate || "--"}
                            isInvalid={!!errors.startDate}
                          />
                          <button
                            type="button"
                            className="search-button right cursor-auto"
                          >
                            <img src={datePickerIcon} alt="DatePicker Icon" />
                          </button>
                        </div>
                      </Col>
                      <Col md={3}>
                        <p className="details-label">{labels.TERM_DATE}</p>
                        <div className="input-group search-container-date">
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={values.termDate}
                            onChange={(date) =>
                              handleChange({
                                target: { name: "termDate", value: date },
                              })
                            }
                            placeholderText="--"
                            data-testid="editproviderStartDate"
                            className="nb-input width-full"
                            value={values.termDate || "--"}
                            isInvalid={!!errors.endDate}
                            minDate={values.startDate}
                          />
                          <button
                            type="button"
                            className="search-button right cursor-auto"
                          >
                            <img src={datePickerIcon} alt="DatePicker Icon" />
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {props.showForm && (
                  <Row className="mt-3 d-flex flex-row-reverse">
                    <Col md={3} className="text-right">
                      <Button
                        variant="primary"
                        className="rounded-pill save_btn"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </React.Fragment>
  );
}

export default AdminAddLocationForm;
