import React, { useState, useRef, useEffect } from "react";
import { useFetch } from "Components/Hooks/Fetch";
import useOutsideClick from "Components/Hooks/useOutsideClick";
import cookies from "js-cookie";
import { Col, Form, Row, Table } from "react-bootstrap";
import searchIcon from "images/search.svg";
import { BannerHeading } from "Components/Admin/Components/UI/Atoms/BannerHeading";
import sortIcon from "../../../../../../src/images/sort_icon.svg";
import Spinner from "Components/Hooks/Spinner";
import jwt_decode from "jwt-decode";
import Pagination2 from "Components/Admin/Components/UI/Atoms/Pagination2";
import datePickerIcon from "../../../../../../src/images/datepicker_icon.svg";
import moment from "moment";
import PendingIcon from "Components/Admin/Components/Templates/PracticeApprovalBoard/images/pending_icon.svg";

function NpiSearch() {
  const ref = useRef();
  const [mdOfficevalue, setmdOfficeValue] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [curatedProvidersList, setCuratedProvidersList] = useState([]);
  const [providersLoading, setIsProvidersLoading] = useState(false);
  const [bannerData, setBannerData] = useState({
    name: "",
    npi: "",
  });
  const token = cookies.get("access");
  const base_url1 = process.env.REACT_APP_SERVER_URL1;
  const subscriptionKey = process.env.REACT_APP_TOKEN;

  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [providerEdit, setProviderEdit] = useState([]);
  const { data: contractedSpecialty = [] } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=1"
  );
  const [errorMessage, setErrorMessage] = useState("");
  // const [presentError, setPresentError] = useState(false);
  // const [creatNpi, setCreateNpi] = useState("");
  const [providerData, setProviderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  useOutsideClick(ref, () => {
    if (filterDropdown) {
      setFilterDropdown(false);
    }
  });

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };
  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };
  const createSearchPattern = (input) => {
    // Remove spaces from the input and make it case-insensitive
    const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
    return new RegExp(sanitizedInput, "i");
  };
  const filterData = (item) => {
    const searchLocationNamePattern = createSearchPattern(searchInput);
    const searchAddressPattern = createSearchPattern(searchAddress);
    const searchStartDatePattern = createSearchPattern(searchStartDate);
    const searchEndDatePattern = createSearchPattern(searchEndDate);

    const locationMatches = item?.practice?.location?.some((location) => {
      const locationName = location?.locationName
        ?.toLowerCase()
        .replace(/\s/g, "");
      const address = `${location?.street1 || ""} ${location?.street2 || ""} ${
        location?.city || ""
      } ${location?.state || ""} ${location?.zipCode || ""}`
        .toLowerCase()
        .replace(/\s/g, "");
      const startDate = item?.startDate
        ? moment(item.startDate)
          .format("MM/DD/YYYY")
          ?.toLowerCase()
          .replace(/\s/g, "")
        : "";
      const endDate = item?.termDate
        ? moment(item?.termDate)
          .format("MM/DD/YYYY")
          ?.toLowerCase()
          .replace(/\s/g, "")
        : "";

      return (
        searchLocationNamePattern.test(locationName) &&
        searchAddressPattern.test(address) &&
        searchStartDatePattern.test(startDate) &&
        searchEndDatePattern.test(endDate)
      );
    });

    return locationMatches;
  };
  useEffect(() => {
    const newFilteredData =
      providerEdit?.practiceAssignment?.filter(filterData);
    setFilteredAllData(newFilteredData);
  }, [
    searchInput,
    searchAddress,
    searchStartDate,
    searchEndDate,
    providerEdit,
  ]);

  const queryProvidersList = (e) => {
    e.preventDefault();
    setmdOfficeValue(e.target.value);
    console.log("e.target.value==", e.target.value);
    
    setIsProvidersLoading(false);
    setFilterDropdown(true);
    if (e.target.value !== "") {
      setIsProvidersLoading(true);
      setCuratedProvidersList(providerData);
      setFilterDropdown(false);
      // const value = e.target.value;
      // const maxLength = 10;
      // const sanitizedValue = value.replace(/\D/g, " ");
      // const truncatedValue = sanitizedValue.slice(0, maxLength);
      // if (truncatedValue !== value) {
      //   e.target.value = truncatedValue;
      // }
    }
  };
  useEffect(() => {
    setFilterDropdown(false);
    if (mdOfficevalue !== "" && providerData?.length > 0) {
      setIsProvidersLoading(false);
      setFilterDropdown(true);
      const curatedList = providerData?.filter(
        (f) =>
          (f && f?.npi && f?.npi?.includes(mdOfficevalue?.toString())) ||
          f?.firstName?.toLowerCase().replace(/\s/g, "")?.includes(mdOfficevalue?.toLowerCase().replace(/\s/g, "")) ||
          f?.middleName?.toLowerCase().replace(/\s/g, "")?.includes(mdOfficevalue?.toLowerCase().replace(/\s/g, "")) ||
          f?.lastName?.toLowerCase().replace(/\s/g, "")?.includes(mdOfficevalue?.toLowerCase().replace(/\s/g, ""))
      );
      if (curatedList?.length > 0) {
        console.log("providerData==", providerData, curatedList);
        // setCreateNpi("");
        setCuratedProvidersList(curatedList);
        setErrorMessage("");
      } else {
        setCuratedProvidersList([]);
        setErrorMessage("No providers match this record");
        // setCreateNpi(mdOfficevalue);
      }

      if (mdOfficevalue?.length === 0) {
        setCuratedProvidersList([]);
      }

      if (mdOfficevalue?.length >= 1 && curatedList?.length === 0) {
        // setPresentError(true);
        // setErrorMessage(false);
      } else {
        // setErrorMessage(false);
        // setPresentError(false);
      }
    }
  }, [providerData, mdOfficevalue]);
  // console.log("curatedList==", filteredAllData);

  const fetchProviderData = async () => {
    await fetch(base_url1 + "/api/v1/providers", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          setProviderData(data);
          setIsProvidersLoading(false);
          setFilterDropdown(true);
        }
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchProviderData();
    };
    fetch();
  }, []);
  const handleViewProviderClick = async (e, providerId) => {
    e.preventDefault();
    setFilterDropdown(!filterDropdown);
    const decodeToken = jwt_decode(token);
    await fetch(
      base_url1 +
        `/api/v1/providers/${providerId}?practicemanagerid=${decodeToken?.id}&&isSqcnAdmin=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBannerData({
            name: data?.firstName + data?.lastName,
            npi: data?.npi,
          });
          if (data?.practiceAssignment?.length > 0) {
            // setFilteredAllData(data?.practiceAssignment);
            if (data?.practiceAssignment?.length > 0) {
              if (contractedSpecialty.length > 0) {
                const value = data?.practiceAssignment?.map((item) => ({
                  ...item,
                  isProviderTrue: item?.termDate === null ? true : false,
                  isProviderFalse: item?.termDate === null ? false : true,
                  isDefaultId: item?.assignmentTypeId === 1 ? true : false,
                  contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
                  practice: {
                    ...item.practice,
                    location: item?.practice?.location?.map((location) => ({
                      ...location,
                      contractedSpecialtyId: parseInt(
                        item?.contractedSpecialtyId
                      ),
                      contractedSpecialtyName:
                        contractedSpecialty &&
                        contractedSpecialty.find(
                          (obj) =>
                            obj.specialtySpecialtyTypeId ===
                            item?.contractedSpecialtyId
                        )?.name,
                    })),
                  },
                }));
                const provider = { ...data, practiceAssignment: value };
                setProviderEdit({ ...data, practiceAssignment: value });
                setFilteredAllData(provider?.practiceAssignment);
              }
            }
            // setFilteredAllData(data);
          } else {
            setProviderEdit([]);
            setFilteredAllData([]);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortingData = () => {
    let vals = providerEdit?.practiceAssignment || "";
    let sortedData = [...vals].sort((a, b) => {
      if (!sortConfig) return 0;
      const isAscending = sortConfig.direction === "ascending" ? 1 : -1;
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return -1 * isAscending;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return 1 * isAscending;
      }
      return 0;
    });
    setFilteredAllData(sortedData);
  };

  useEffect(() => {
    sortingData();
  }, [sortConfig]);
  const [currentRows, setCurrentRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (
      currentPage > 1 &&
      providerEdit?.practiceAssignment?.length <=
        (currentPage - 1) * rowsPerPage
    ) {
      setCurrentPage(1);
    }
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    if (providerEdit?.practiceAssignment?.length > 0) {
      let vals = providerEdit?.practiceAssignment || [];
      let currentRows = [...vals]?.slice(indexOfFirstRow, indexOfLastRow);
      setCurrentRows(currentRows);
      setTotalPages(
        Math.ceil(providerEdit?.practiceAssignment?.length / rowsPerPage)
      );
    }
  }, [providerEdit, currentPage, rowsPerPage]);
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  console.log(
    "filteredAllData---",
    filteredAllData,
    "currentRows===",
    currentRows
  );

  return (
    <div>
      <div className="outlet-2">
        <h4>NPI Search</h4>
        <br />
        <Form autoComplete="off" data-testid="form">
          <Form.Group className="mb-2 dropdown-npi-search">
            <Form.Label className="search-ppd-label">NPI / Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search NPI ID"
              // onKeyDown={(e) =>
              //   ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              // }
              className="nb-input dashboard-search search-ppd"
              onChange={queryProvidersList}
              data-testid="SearchProviderNpi"
              value={mdOfficevalue}
              maxLength={10}
            />
          </Form.Group>
          <div
            className="dropdown locations-result dropdown-npi-search"
            data-testid="dropdown-row-1"
            onClick={() => setFilterDropdown(!filterDropdown)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setFilterDropdown(true)}
          >
            {!providersLoading ? (
              <>
                {errorMessage !== "" ? (
                  <>
                    <div className="nonpi_div">
                      <img
                        src={PendingIcon}
                        alt="pending Approval Icon"
                        className="pending-icon"
                      ></img>{" "}
                      <span className="nonpi_text">{errorMessage}</span>
                    </div>
                    <div className="dropdown-row-npi">
                      <button
                        onClick={() => {
                          setFilterDropdown(!filterDropdown);
                          setmdOfficeValue("");
                        }}
                        className="btn btn-primary rounded-pill npi_search_cancel_btn cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  filterDropdown &&
                  curatedProvidersList?.length > 0 &&
                  curatedProvidersList && (
                    <div>
                      {curatedProvidersList?.map((item) => {
                        return (
                          <>
                            <div>
                              <button
                                onClick={(e) =>
                                  handleViewProviderClick(e, item.id)
                                }
                                className="dropdown-row"
                                key={item?.id}
                                data-testid={`dropdown-row${item.id}`}
                              >
                                <div key={item.id} className="npi-search-list">
                                  <Row>
                                    <Col
                                      md={6}
                                      xs={12}
                                      data-testid="providerName"
                                    >
                                      {item?.firstName} {item?.lastName}
                                    </Col>
                                    <Col
                                      md={6}
                                      xs={12}
                                      data-testid="providerEmail"
                                    >
                                      {item?.npi ? item?.npi : "--"}
                                    </Col>
                                  </Row>
                                </div>
                              </button>
                            </div>
                          </>
                        );
                      })}
                      <div className="dropdown-row-npi">
                        <button
                          onClick={() => {
                            setFilterDropdown(!filterDropdown);
                            setmdOfficeValue("");
                          }}
                          className="btn btn-primary rounded-pill npi_search_cancel_btn cancel-button"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </Form>
      </div>
      <div className="outlet-2">
        {curatedProvidersList?.length > 0 && (
          <BannerHeading
            headingText={bannerData}
            subText={"Active Location"}
            npiSearchBanner={true}
          />
        )}
        <div className="practice-location-table">
          <Table data-testid="providerLocationtable">
            <thead>
              <tr>
                <th width="18%">Location Number - Name</th>
                <th width="15%">Address</th>
                <th className="phone-head5" width="5%">
                  Provider at Location
                </th>
                <th className="phone-head4" width="12%">
                  Is Primary?
                </th>
                <th className="phone-head6" width="8%">
                  {/* <span className="date_text">Start Date</span> */}
                  <span className="header-icons">
                    Start Date
                    <img src={datePickerIcon} alt="DatePicker Icon" />
                  </span>
                </th>
                <th className="phone-head6" width="8%">
                  <span className="date_text">End Date </span>
                </th>
              </tr>
              <tr className="search_row">
                <th>
                  <div className="input-group search-container">
                    <input
                      type="text"
                      placeholder={"Search"}
                      value={searchInput}
                      onChange={handleSearchChange}
                      className="table_search"
                    />
                    <button type="submit" className="search-button left">
                      <img src={searchIcon} alt="Search Icon" />
                    </button>
                    <button
                      type="button"
                      className="search-button right"
                      onClick={() => handleSort("locationNumber")}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </div>
                </th>
                <th>
                  <div className="input-group search-container">
                    <input
                      type="text"
                      placeholder={"Search"}
                      value={searchAddress}
                      onChange={handleSearchAddressChange}
                      className="table_search"
                    />
                    <button type="submit" className="search-button left">
                      <img src={searchIcon} alt="Search Icon" />
                    </button>
                    <button
                      type="button"
                      className="search-button right"
                      onClick={() => handleSort("address")}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </div>
                </th>
                <th></th>
                <th></th>
                <th>
                  <div className="input-group search-container">
                    <input
                      type="text"
                      placeholder={"Search"}
                      value={searchStartDate}
                      onChange={handleSearchStartDateChange}
                      className="table_search"
                    />
                    <button type="submit" className="search-button left">
                      <img src={searchIcon} alt="Search Icon" />
                    </button>
                    <button
                      type="button"
                      className="search-button right"
                      onClick={() => handleSort("address")}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </div>
                </th>
                <th>
                  <div className="input-group search-container">
                    <input
                      type="text"
                      placeholder={"Search"}
                      value={searchEndDate}
                      onChange={handleSearchEndDateChange}
                      className="table_search"
                    />
                    <button type="submit" className="search-button left">
                      <img src={searchIcon} alt="Search Icon" />
                    </button>
                    <button
                      type="button"
                      className="search-button right"
                      onClick={() => handleSort("address")}
                    >
                      <img src={sortIcon} alt="Sort Icon" />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody data-testid="ProviderData">
              {currentRows?.length > 0 && filteredAllData?.length > 0 ? (
                filteredAllData?.map((item) => {
                  const date = new Date(item?.startDate);
                  const formattedStartDate = item?.startDate
                    ? `${(date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}/${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")}/${date.getFullYear()}`
                    : "--";
                  const termdate = new Date(item?.termDate);
                  const formattedTermDate = item?.termDate
                    ? `${(termdate.getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}/${termdate
                      .getDate()
                      .toString()
                      .padStart(2, "0")}/${termdate.getFullYear()}`
                    : "--";
                  return (
                    <React.Fragment key={item?.id}>
                      {item?.practice?.location?.map((locationItem) => (
                        <tr key={locationItem.id}>
                          <td
                            className={
                              locationItem?.locationNumber === 1
                                ? "Primary1"
                                : "Secondary1"
                            }
                          >
                            {locationItem?.locationNumber}
                            &nbsp;-&nbsp;
                            {locationItem?.locationName}
                          </td>
                          <td
                            className={
                              locationItem?.locationNumber === 1
                                ? "Primary1"
                                : "Secondary1"
                            }
                          >
                            {`${locationItem?.street1} ${
                              locationItem?.street2 ? locationItem?.street2 : ""
                            }, ${locationItem?.city}, ${locationItem?.state} ${
                              locationItem?.zipCode
                            }`}
                          </td>
                          <td
                            className={
                              locationItem?.locationNumber === 1
                                ? "Primary1 text-center"
                                : "Secondary1 text-center"
                            }
                          >
                            <div className="info">
                              <Form.Check
                                inline
                                type="radio"
                                disabled={locationItem?.locationNumber !== 1}
                                label="Yes"
                                data-testid="providerAtLocationYes"
                                value={item?.isProviderTrue}
                                checked={item?.isProviderTrue}
                              />
                              <Form.Check
                                inline
                                type="radio"
                                disabled={locationItem?.locationNumber !== 1}
                                label="No"
                                data-testid="providerAtLocationNo"
                                value={item?.isProviderFalse}
                                checked={item?.isProviderFalse}
                              />
                            </div>
                          </td>
                          {locationItem?.locationNumber === 1 ? (
                            <td
                              className={
                                locationItem?.locationNumber === 1
                                  ? "Primary1 text-center"
                                  : "Secondary1 text-center"
                              }
                            >
                              <div className="info">
                                <Form.Check
                                  inline
                                  type="radio"
                                  data-testid="providerPrimary"
                                  value="yes"
                                  checked={item?.assignmentTypeId === 1}
                                />
                              </div>
                            </td>
                          ) : (
                            <td
                              className={
                                locationItem?.locationNumber === 1
                                  ? "Primary1 text-center"
                                  : "Secondary1 text-center"
                              }
                            ></td>
                          )}

                          <td
                            className={
                              locationItem.locationNumber === 1
                                ? "Primary1"
                                : "Secondary1"
                            }
                          >
                            {locationItem.locationNumber === 1
                              ? formattedStartDate
                              : "--"}
                          </td>
                          <td
                            className={
                              locationItem.locationNumber === 1
                                ? "Primary1"
                                : "Secondary1"
                            }
                          >
                            {locationItem.locationNumber === 1
                              ? formattedTermDate
                              : "--"}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>--</tr>
              )}
            </tbody>
          </Table>
          {filteredAllData?.length > 0 && currentRows?.length > 0 && (
            <>
              <Pagination2
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                data-testid="admin_provider_list_pagination"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NpiSearch;
