/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Table, Button } from "react-bootstrap";
import { firstLetterOfTwo } from "utils";
// import { Link } from "react-router-dom";
// import practiceIcon from "./images/Icon-b-practice.svg";
// import providerListIcon from "./images/Icon-b-provider-list.svg";
// import userIcon from "./images/Icon-b-user.svg";
import sortIcon from "./images/Sort_arrow.svg";
// import editIcon from "./images/Icon-b-edit.svg";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFetch } from "Components/Hooks/Fetch";
// import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import cookies from "js-cookie";
import AddLocationModalPageAdmin from "../DataPages/AddLocationModalPageAdmin";
import jwt_decode from "jwt-decode";
import datePickerIcon from "../../../images/datepicker_icon.svg";
import { formatDate, formatDateByTimeZone } from "Static/format";

function convertLocalToUTCDate(date) {
  return date ? new Date(date) : null;
}
const EditPracticeNewProvider = ({
  practiceTitle,
  providerSummary,
  //   id,
  selectedPracticeId,
  practiceManagerId,
  personRoleName,
  //providerListGetUpdate,
  updatedProviderList,
  addFlow,
  setUpdatedProviderList,
  setCreatNpi,
  setViewProviderIdModal,
  providerListGetUpdate,
  setRefreshData,
  refreshData,
  locationPayload,
  titleData,
  // prefixData
}) => {
  const [providerEdit, setProviderEdit] = useState({});
  const [providerPrefix, setProviderPrefix] = useState({
    prefixId: providerSummary?.prefixId,
  });

  const [providerPrefixName, setProviderPrefixName] = useState("");
  //const [changeFieldData, setChangeFieldData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  //const [providerEffectiveDate, setProviderEffDate] = useState(null);
  // const [effectiveDate, setEffectiveDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSuffix, setSelectedSuffix] = useState("");
  const base_url = process.env.REACT_APP_SERVER_URL1;

  const { data = [] } = useFetch("/api/v1/providers/GetAllTitleTypes");
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const selectedPracticeId1 =
    localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const [viewProviderId, setViewProviderId] = useState(false);
  // const [showAlert, setshowAlert] = useState(false);
  const [hideData, setHideData] = useState(false);
  const decodeToken = jwt_decode(token);
  const [locationListedData, setLocationListedData] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const base_url1 = process.env.REACT_APP_SERVER_URL;
  const [prefixData, setPrefixData] = useState([]);

  const id = window.localStorage.getItem("providerId");

  const prefix_mapTitles = [
    "16",
    "41",
    "24",
    "61",
    "31",
    "62",
    "63",
    "5",
    "11",
    "36",
    "1",
    "66",
    "67",
    "32",
    "68",
    "22",
    "84",
    "23",
    "45",
  ];

  const handleIdChange = (name, value) => {
    if (name === "prefixId") {
      setProviderPrefix({ prefixId: value });
    }
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    setProviderPrefixName(value);
  };

  
  useEffect(() => {
    if (prefixData.length > 0) {
      const prefix = prefixData.find(
        (item) => item.id === providerSummary?.prefixId
      );

      if (prefix) {
        setProviderPrefix({ prefixId: prefix.id });
        setProviderPrefixName(prefix.prefixName);
      }
    }
  }, [prefixData, providerSummary]);

  const GetTitleDetails = () => {
    fetch(base_url1 + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPrefixData(data?.prefixList || []);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
    };
    fetch();
  }, []);

  const GetPrimaryLocation = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetPracticePrimaryLocation?practiceid=${selectedPracticeId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        clickHandler([data]);
      });
  };

  useEffect(() => {
    if (addFlow && locationPayload.length > 0) {
      clickHandler([
        {
          practice: {
            location: locationPayload,
          },
        },
      ]);
    }
  }, [locationPayload]);

  const providerLocationGetUpdate = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetLocationByPracticeManagerId?practiceManagerId=${
          decodeToken?.id || practiceManagerId
        }&personid=undefined&&isSqcnAdmin=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.practiceAssignment?.length > 0) {
          const primaryLocations = data?.practiceAssignment
            ?.filter(
              (assignment) =>
                assignment?.practiceId === parseInt(selectedPracticeId1)
            )
            .map((item) => ({
              ...item,
              isProviderTrue: item?.termDate === null ? true : false,
              isProviderFalse: item?.termDate === null ? false : true,
              assignmentTypeId: 1,
              isDefaultId: item?.assignmentTypeId === 1 ? true : false,
              isdefaultPrimary: true,
            }));
          console.log(
            "primaryLocations",
            primaryLocations,
            selectedPracticeId1
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    const base_url00 = process.env.REACT_APP_SERVER_URL;
    const selectedPracticeIdByAdmin = localStorage.getItem(
      "selectedPracticeIdByAdmin"
    );
    fetch(
      base_url00 +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location?includePending=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    fetch(base_url + "/api/v1/providers/GetAllPracticeAssignmentsLocations", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          setAllLocationData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      providerLocationGetUpdate();
    };
    fetchData();
  }, [selectedPracticeId1]);
  
  const getPrefixByName = (prefixName) => {
    if (prefixData?.length > 0) {
      const prefix = prefixData?.filter(
        (item) => item.prefixName === prefixName
      );
      if (prefix.length > 0) {
        return prefix[0].id;
      }
    }
    return "";
  };

  const handlePrefixChange = (id) => {
    console.log("id", id);
    const DrId = getPrefixByName("Dr");
    const value = { ...providerPrefix, prefixId: DrId };
    setProviderPrefix(value);
    setProviderPrefixName("Dr");
  };

  const { data: contractedSpecialty = [] } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=1"
  );

  const { data: boardSpecialty = [] } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=2"
  );

  useEffect(() => {
    if (providerSummary?.practiceAssignment?.length > 0) {
      const value = providerSummary?.practiceAssignment?.map((item) => ({
        ...item,
        isProviderTrue: item?.termDate === null ? true : false,
        isProviderFalse: item?.termDate === null ? false : true,
        isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      }));
      const provider = { ...providerSummary, practiceAssignment: value };

      if (!addFlow) {
        // GetPrimaryLocation();

        setProviderEdit(provider);
        setTimeout(() => {
          GetPrimaryLocation();
        }, 2000);
      } else {
        setProviderEdit(provider);
      }
    }
    providerSummary && setSelectedTitle(selectedTitle);
    providerSummary && setSelectedSuffix(selectedSuffix);
  }, [providerSummary]);

  useEffect(() => {
    if(contractedSpecialty.length > 0) {
      if (providerSummary?.practiceAssignment?.length > 0) {
        const value = providerSummary?.practiceAssignment?.map((item) => ({
          ...item,
          isProviderTrue: item?.termDate === null ? true : false,
          isProviderFalse: item?.termDate === null ? false : true,
          isDefaultId: item?.assignmentTypeId === 1 ? true : false,
          contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
          practice: {
            ...item.practice,
            location: item?.practice?.location?.map((location) => ({
              ...location,
              contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
              contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
            })),
          },
        }));
        const provider = { ...providerSummary, practiceAssignment: value };
        setProviderEdit(provider);
      }
    }
  }, [providerSummary, contractedSpecialty]);

  useEffect(() => {
    if (!addFlow) {
      GetPrimaryLocation();
    }
  }, []);

  const specialtyDetailsByspecialtype =
    providerSummary?.specialtySpecialtyType?.find(
      (item) => item?.specialtyType?.typeSpecialty === "Board Specialty1"
    );
  const firstLetters = firstLetterOfTwo(
    providerSummary?.firstName,
    providerSummary?.lastName
  );

  const [allSuffixData, setAllSuffixData] = useState([]);

  //console.log("-----------boardSpecialty--------------------",boardSpecialty);

  //console.log("-----------contractedSpecialty--------------------",contractedSpecialty);

  const { data: GetSuffixData } = useFetch("/api/v1/contact/GetSuffixData");

  useEffect(() => {
    if (GetSuffixData) {
      setAllSuffixData(GetSuffixData);
    }
  }, [GetSuffixData]);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .max(25, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Name cannot contain numbers ")
      .required("First Name is required"),
    middleName: yup
      .string()
      .nullable()
      .max(25, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Name cannot contain numbers"),
    lastName: yup
      .string()
      .max(25, "Too Long!")
      .matches(/^[A-Za-z\s'_.-]+$/, "Name cannot contain numbers ")
      .required("Last Name is required"),
    mdofficeId: yup.string().max(25, "Too Long!"),
    npi: yup
      .string()
      .required("NPI is required")
      .min(10, "NPI should be 10 digits")
      .max(10, "NPI should be 10 digits")
      .matches(/^[0-9]+$/, "Must be only digits"),
    preferredEmailAddress: yup
      .string()
      .email("Invalid email format")
      .required("Email required."),
    // sentaraEmailAddress: yup.string().email("Invalid email format"),
    boardSpecialtyName: yup.string().required("Board Specialty is required"),
  });

  const validateFieldsFn = (prevData, currentData) => {
    const specialtyDetailsByCurrentData =
      currentData?.specialtySpecialtyType?.find(
        (item) => item?.specialtyTypeId?.toString() === "2"
      );
    console.log("prd", prevData, "crd", currentData);
    if (
      prevData?.npi?.toString() === currentData?.npi?.toString() &&
      specialtyDetailsByspecialtype?.specialty?.id?.toString() ===
        specialtyDetailsByCurrentData?.specialtyId?.toString()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      mdofficeId: "",
      npi: localStorage.getItem("creatNpi") || "",
      preferredEmailAddress: "",
      sentaraEmailAddress: "",
      contractedSpecialtyId: "",
      contractedSpecialtyName: "",
      providersEffectiveDate: "",
      providersNameBeforeEditing: "",
      suffixName: "",
      suffixId: "",

      boardSpecialtyId: "",
      boardSpecialtyName: "",
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const titleVal = [
        titleData?.find((title) => title.title === values?.title),
      ];
      const titleTypeData = values?.title ? titleVal : [];
      const payload = {
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        mdofficeId: values?.mdofficeId,
        npi: values?.npi,
        preferredEmailAddress: values?.preferredEmailAddress,
        sentaraEmailAddress: values?.sentaraEmailAddress,
        updateUser: practiceManagerId,
        providersEffectiveDate:
          values?.providersEffectiveDate &&
          formatDate(values?.providersEffectiveDate),
        providersNameBeforeEditing: values.providersNameBeforeEditing,
        suffix: parseInt(values.suffixId),
        personStatusId: 1,
        preferredEmail: values?.preferredEmailAddress,
        sentaraEmail: values?.sentaraEmailAddress,
        suffixId: parseInt(values.suffixId),
        prefixId: parseInt(providerPrefix?.prefixId),
        titleType: titleTypeData,
        // titleId: parseInt(values?.titleId),
      };
      let email = [
        {
          emailTypeId: 1,
          emailAddress: values.preferredEmailAddress,
        },
      ];
      if (values.sentaraEmailAddress) {
        let obj = {
          emailTypeId: 2,
          emailAddress: values.sentaraEmailAddress,
        };
        email.push(obj);
      }
      let practiceAssignment = providerEdit?.practiceAssignment;

      // if (practiceAssignment.length > 0) {
      //   practiceAssignment[0]?.practice?.location === locationListedData;
      // } else {

      practiceAssignment = filteredAllData?.map((item) => ({
        startDate: item?.startDate
          ? moment(item?.startDate).format("YYYY-MM-DD")
          : "",
        termDate: item?.termDate
          ? moment(item?.termDate).format("YYYY-MM-DD")
          : undefined,
        practiceId: item.practiceId,
        roleTypeId: 2,
        assignmentTypeId: item?.assignmentTypeId,
        personId: item.personId,
        contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
      }));

      let specialtySpecialtyType = [];

      if (values.boardSpecialtyId) {
        specialtySpecialtyType.push({
          specialtyId: parseInt(values.boardSpecialtyId),
          specialtyTypeId: 2,
        });
      }

      if (values.contractedSpecialtyId) {
        specialtySpecialtyType.push({
          specialtyId: parseInt(values.contractedSpecialtyId),
          specialtyTypeId: 1,
        });
      }
      // practiceAssignment.push({ practice: { location: locationListedData } });
      //}
      const reqBody = {
        ...providerEdit,
        ...payload,
        email: email,
        specialtySpecialtyType: specialtySpecialtyType,
        ...{ practiceAssignment: practiceAssignment },
      };

      if (addFlow) {
        let localProviders = [...updatedProviderList];
        let practiceAssignment2 = filteredAllData?.map((item) => ({
          startDate: item?.startDate
            ? moment(item?.startDate).format("YYYY-MM-DD")
            : "",
          termDate: item?.termDate
            ? moment(item?.termDate).format("YYYY-MM-DD")
            : undefined,
          practiceId: item.practiceId || 0,
          roleTypeId: 2,
          assignmentTypeId: item?.assignmentTypeId,
          practice: {
            ...item.practice,
            practiceAssignment: []
          },
          personId: item.personId || 0,
          contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
          status: "Approved",
          sourceSystem: "SQCN",
        }));

        const localLocations = {
          ...providerEdit,
          ...payload,
          email: email,
          specialtySpecialtyType: specialtySpecialtyType,
          ...{ practiceAssignment: practiceAssignment2 },
          existingProvider: false
        };

        localProviders.push(localLocations);

        localProviders.forEach((item, index) => {
          item.localId = index + 1;
        });
        setUpdatedProviderList(localProviders);

        setViewProviderIdModal(false);

        setCreatNpi(false);

        // setUpdatedProviderList

        // providerListGetUpdate,
        // updatedProviderList,
        // addFlow,
        // setUpdatedProviderList,
        // setCreatNpi,
        // setViewProviderIdModal,
      } else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(reqBody),
        };
        let status = "";
        fetch(
          base_url + "/api/v1/providers?primaryLocation=false&issqcnqdmin=true",
          requestOptions
        )
          .then((response) => {
            status = response?.status;
            return response.json();
          })
          .then((res) => {
            if (Number(status) === 200) {
              setErrorMessage();
              setIsLoading(false);
              const validFields = validateFieldsFn(providerSummary, reqBody);
              console.log("vf", validFields);
              providerListGetUpdate();
              setRefreshData(!refreshData);
              setCreatNpi(false);
              //attachLocation(res.id)
              setViewProviderId(id);
              setHideData(true);
            } else {
              setErrorMessage(res?.message || res?.error);
              setIsLoading(false);
              setHideData(false);
            }
          });
      }
    },
  });

  //const [addLocationFromModal, setAddLocationFromModal] = useState(null);

  //const locationID = addLocationFromModal?.map((item) => item.id);
  //console.log(locationID, "locationID");

  const handleCheckYes = (e, itemId) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      let localId = item.id;

      if (addFlow) {
        localId = item.localPracticeId;
      }

      if (localId === itemId) {
        return {
          ...item,
          isProviderTrue: e.target.checked,
          isProviderFalse: !e.target.checked,
          termDate: null,
          // assignmentTypeId: item?.isDefaultId === true ? 1 : 2,
        };
      }
      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };

  const handleCheckNo = (e, itemId) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      let localId = item.id;

      if (addFlow) {
        localId = item.localPracticeId;
      }

      if (localId === itemId) {
        return {
          ...item,
          isProviderTrue: !e.target.checked,
          isProviderFalse: e.target.checked,
          // assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            // id: 2,
          },
        };
      }
      return item;
    });
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const handleContractedSpecialty = (itemId, selectedId) => {
    if(contractedSpecialty.length > 0) {
      const updatedData = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;
  
        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return {
            ...item,
            contractedSpecialtyId: parseInt(selectedId),
            practice: {
              ...item.practice,
              location: item?.practice?.location?.map((location) => ({
                ...location,
                contractedSpecialtyId: parseInt(selectedId),
                contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === parseInt(selectedId))?.name,
              })),
            },
          };
        }
        return item;
      });
      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
    }
  }

  const updatePrimaryLocation = (practiceId) => {
    let updatePrimary = locationListedData.map((location) => {
      if (location.practiceId === practiceId) {
        location.isDefaultId = true;
        location.assignmentTypeId = 1;
      }
      return location;
    });

    setLocationListedData(updatePrimary);
  };
  const handleRadioSelection = (pid) => {
    // fetchPrimaryLocChangeRecords();
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.practiceId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === pid ? 1 : 2,
          },
        };
      } else {
        return {
          ...item,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2,
        };
      }
    });

    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (!item?.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (item.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.practiceId === pid ? 1 : 2,
    //       },
    //     };
    //   }
    //   return {
    //     ...item,
    //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //     ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2,
    //   };
    // });
    // console.log("up", selectedPracticeId1, item);
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };

  const handleRadioSelectionAddFlow = (pid) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item.localPracticeId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.localPracticeId === pid ? 1 : 2,
          },
        };
      } else {
        return {
          ...item,
          assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
          ["assignmentType.id"]: item?.localPracticeId === pid ? 1 : 2,
        };
      }
    });

    //   if (item?.isDefaultId && isSelect) {

    //     console.log("-------1 if-----------",item);
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (!item?.isDefaultId) {

    //     console.log("-------2 if-----------",item);
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (item.isDefaultId) {

    //     console.log("-------3 if-----------",item);
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   }
    //   return {
    //     ...item,
    //     assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //     ["assignmentType.id"]: item?.localPracticeId === pid ? 1 : 2,
    //   };
    // });

    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };

  const startDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    if (filteredAllData.length > 0) {
      const updatedData = filteredAllData?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, startDate: isoDateString };
        }
        return item;
      });

      const updatedData2 = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, startDate: isoDateString };
        }
        return item;
      });

      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData2 });

      setFilteredAllData(updatedData);
    }
  };
  const endDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    if (filteredAllData.length > 0) {
      const updatedData = filteredAllData?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }

        if (localId === itemId) {
          return { ...item, termDate: isoDateString };
        }
        return item;
      });

      const updatedData2 = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, termDate: isoDateString };
        }
        return item;
      });

      setFilteredAllData(updatedData);
      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData2 });
    }
  };

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  // const createSearchPattern = (input) => {
  //   const sanitizedInput = input.replace(/\s/g, "").toLowerCase();
  //   return new RegExp(sanitizedInput, "i");
  // };
  // const filterData = (item) => {
  //   const searchLocationNamePattern = createSearchPattern(searchInput);
  //   const searchAddressPattern = createSearchPattern(searchAddress);
  //   const searchStartDatePattern = createSearchPattern(searchStartDate);
  //   const searchEndDatePattern = createSearchPattern(searchEndDate);

  //   const locationMatches = item?.practice?.location?.some((location) => {
  //     const locationName = location?.locationName
  //       ?.toLowerCase()
  //       .replace(/\s/g, "");
  //     const address = `${location?.street1 || ""} ${location?.street2 || ""} ${
  //       location?.city || ""
  //     } ${location?.state || ""} ${location?.zipCode || ""}`
  //       .toLowerCase()
  //       .replace(/\s/g, "");
  //     const startDate = item?.startDate
  //       ? moment(item.startDate)
  //           .format("MM/DD/YYYY")
  //           ?.toLowerCase()
  //           .replace(/\s/g, "")
  //       : "";
  //     const endDate = item?.termDate
  //       ? moment(item?.termDate)
  //           .format("MM/DD/YYYY")
  //           ?.toLowerCase()
  //           .replace(/\s/g, "")
  //       : "";

  //     return (
  //       searchLocationNamePattern.test(locationName) &&
  //       searchAddressPattern.test(address) &&
  //       searchStartDatePattern.test(startDate) &&
  //       searchEndDatePattern.test(endDate)
  //     );
  //   });

  //   return locationMatches;
  // };

  useEffect(() => {
    const newFilteredData = providerEdit?.practiceAssignment?.map((item) => {
      return item;
    });

    if (newFilteredData?.length > 0) {
      setFilteredAllData(newFilteredData);
    }
  }, [
    searchInput,
    searchAddress,
    searchStartDate,
    searchEndDate,
    providerEdit,
  ]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortDataByStartDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.startDate).diff(moment(b.startDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const sortDataByEndDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.termDate).diff(moment(b.termDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };
  const clickHandler = (locations) => {
    //setAddLocationFromModal(locations);

    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
    }));

    const addedLocations = fData || [];

    if (locationListedData.length > 0) {
      setLocationListedData([...addedLocations, ...locationListedData]);
    } else {
      setLocationListedData([...addedLocations]);
    }

    let localFilterData = [];

    if (filteredAllData?.length > 0) {
      localFilterData = [...filteredAllData];
    }

    if (localFilterData?.length > 0) {
      let localFilter = [...localFilterData, ...fData];
      localFilter.forEach((item, index) => {
        item.localPracticeId = index + 1;
      });
      setFilteredAllData(localFilter);
    } else {
      let localFilter = fData;
      localFilter.forEach((item, index) => {
        item.localPracticeId = index + 1;
      });
      setFilteredAllData(localFilter);
    }

    if (
      addFlow &&
      locationPayload.length > 0 &&
      (filteredAllData === undefined || filteredAllData.length === 0)
    ) {
      setFilteredAllData(fData);
    }

    let localPracticeEdit = { ...providerEdit };

    let updatedPracticeAssignment = [];

    if (localPracticeEdit?.practiceAssignment?.length > 0) {
      updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment];

      updatedPracticeAssignment = [...updatedPracticeAssignment, ...fData];
    } else {
      updatedPracticeAssignment = fData;
    }

    updatedPracticeAssignment.forEach((item, index) => {
      item.localPracticeId = index + 1;
    });

    localPracticeEdit.practiceAssignment = updatedPracticeAssignment;

    setProviderEdit(localPracticeEdit);
  };

  useEffect(() => {
    let result = [];
    if (
      locationListedData &&
      allLocationData &&
      allLocationData?.length > 0 &&
      filteredAllData?.length > 0
    ) {
      result = allLocationData?.filter(
        (dataItem) =>
          !filteredAllData[0]?.practice?.location?.some(
            (locationItem) => locationItem.id === dataItem.id
          )
      );
      setAllLocationData(result);
    }
  }, [locationListedData, filteredAllData]);

  // console.log("--------------filteredAllData----------------", filteredAllData);

  return (
    <div>
      <Container className="mt-40">
        {!hideData && (
          <div className="edit-provider">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={2} xs={12}>
                  <div className="provider-profile">
                    <div
                      className="firstletter justify-content-center  d-flex align-items-center"
                      data-testid="providerNamefirstletters"
                    >
                      {firstLetters}
                    </div>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <div className="main-block create_provider">
                    <div className="block">
                      <Form.Label>
                        Providers Effective Date with SQCN
                      </Form.Label>
                      <div className="input-group search-container-date">
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          // selected={effectiveDate}
                          selected={
                            formik?.values?.providersEffectiveDate &&
                            formik?.values?.providersEffectiveDate
                          }
                          onChange={(date) =>
                            formik.handleChange({
                              target: {
                                name: "providersEffectiveDate",
                                value: date,
                              },
                            })
                          }
                          value={
                            (formik.values.providersEffectiveDate &&
                              new Date(formik.values.providersEffectiveDate)) ||
                            ""
                          }
                          // onChange={(date) => {
                          //   // handleChange({
                          //   //   target: { name: "termDate", value: date },
                          //   // })

                          //   setEffectiveDate(date);
                          // }}
                          placeholderText="--"
                          data-testid="editproviderStartDate"
                          className="nb-input width-full"
                          // value={effectiveDate}
                          minDate={new Date()}
                        />

                        <button
                          type="button"
                          className="search-button right cursor-auto"
                        >
                          <img src={datePickerIcon} alt="DatePicker Icon" />
                        </button>
                      </div>
                    </div>
                    <div className="block">
                      <Form.Label>{labels.NPI}</Form.Label>
                      <Form.Control
                        type={"tel"}
                        maxLength={10}
                        placeholder="---"
                        name="npi"
                        className={
                          formik.touched.npi && formik.errors.npi
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.npi}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerNpi"
                      />
                      {formik.touched.npi && formik.errors.npi ? (
                        <div
                          className="form-error"
                          data-testid="providerNpiError"
                        >
                          {formik.errors.npi}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.MDOFFICE_ID}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="mdofficeId"
                        className={
                          formik.touched.mdofficeId && formik.errors.mdofficeId
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mdofficeId}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerMDofficeId"
                      />
                      {formik.touched.mdofficeId && formik.errors.mdofficeId ? (
                        <div
                          className="form-error"
                          data-testid="providerMDofficeIdError"
                        >
                          {formik.errors.mdofficeId}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>
                        {labels.PROVIDER_NAME_BEFORE_EDITING}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="providersNameBeforeEditing"
                        className={
                          formik.touched.providersNameBeforeEditing &&
                          formik.errors.providersNameBeforeEditing
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.providersNameBeforeEditing}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providersNameBeforeEditing"
                      />
                      {/* {formik.touched.providersNameBeforeEditing && formik.errors.providersNameBeforeEditing ? (
                        <div
                          className="form-error"
                          data-testid="providersNameBeforeEditingError"
                        >
                          {formik.errors.providersNameBeforeEditing}
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="main-block create_provider_select">
                    <div className="block">
                      <div className="title">{labels.PREFIX}</div>

                      <Form.Select
                        size="md"
                        placeholder="---"
                        id="prefix"
                        autoComplete="off"
                        value={providerPrefixName}
                        defaultValue={providerPrefixName}
                        className="nb-select nb-input"
                        name="prefix"
                        // onChange={handleChange}

                        onChange={(e) => {
                          handleInputChange(e);

                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-prefixId"
                            );

                          handleIdChange("prefixId", id);
                          //newhandleSelectPrefix()
                        }}
                      >
                        <option value={providerPrefixName}>
                          {providerPrefixName}
                        </option>
                        {prefixData && prefixData?.length > 0 &&
                          prefixData?.map((prefixItem, index) => (
                          <option
                            key={prefixItem.prefixName + index}
                            value={prefixItem.prefixName}
                            data-prefixId={prefixItem.id}
                          >
                            {prefixItem.prefixName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className="block">
                      <Form.Label>{labels.FIRST_NAME}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        className={
                          formik.touched.firstName && formik.errors.firstName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        name="firstName"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerFirstName"
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div
                          className="form-error"
                          data-testid="providerFirstNameError"
                        >
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.MIDDLE_INTITIAL}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="middleName"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.middleName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerMiddleName"
                        className={
                          formik.touched.middleName && formik.errors.middleName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.middleName && formik.errors.middleName ? (
                        <div
                          className="form-error"
                          data-testid="providerMiddleNameError"
                        >
                          {formik.errors.middleName}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.LAST_NAME}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="lastName"
                        className={
                          formik.touched.lastName && formik.errors.lastName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerLastName"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div
                          className="form-error"
                          data-testid="providerLastNameError"
                        >
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="main-block create_provider_select">
                    <Col md={3}>
                      <div className="block">
                        <Form.Label>{labels.SUFFIX}</Form.Label>
                        <Form.Select
                          size="sm"
                          name={"suffixName"}
                          autoComplete="off"
                          value={formik.values.suffixName}
                          defaultValue={formik.values.suffixName}
                          className="nb-select nb-input"
                          onChange={(e) => {
                            formik.handleChange({
                              target: {
                                name: "suffixName",
                                value: e.value,
                              },
                            });
                            let suffixId =
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-suffixId"
                              );
                            formik.handleChange({
                              target: {
                                name: "suffixId",
                                value: suffixId,
                              },
                            });
                          }}
                        >
                          <option value={formik.values.suffixName}>
                            {formik.values.suffixName}
                          </option>

                          {allSuffixData?.length > 0 &&
                            allSuffixData?.map((suffix) => (
                              <option
                                key={suffix.specialityId}
                                data-suffixId={suffix.id}
                                value={suffix.suffixName}
                              >
                                {suffix.suffixName}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <Form.Label className="label-align">{labels.TITLE}</Form.Label>
                        <Form.Select
                          size="md"
                          id="title"
                          autoComplete="off"
                          name="title"
                          value={formik.values.title}
                          //defaultValue={selectedTitle}
                          className="nb-select nb-input"
                          onChange={(e) => {
                            // handleInputChange(e, i);

                            setSelectedTitle(e.target.value);

                            formik.handleChange({
                              target: { name: "title", value: e.target.value },
                            });
                            let id =
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-title"
                              );
                            formik.handleChange({
                              target: {
                                name: "titleId",
                                value: id,
                              },
                            });
                            if (prefix_mapTitles.includes(id)) {
                              handlePrefixChange(id);
                            }
                          }}
                        >
                          <option value={selectedTitle}>{selectedTitle}</option>
                          {data?.map((titleItem, index) => (
                            <option
                              key={titleItem.title + index}
                              value={titleItem.title}
                              data-title={titleItem.id}
                            >
                              {titleItem.title}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <div className="block">
                      <Form.Label>{labels.BOARD_SPECIALITY}</Form.Label>
                      <Form.Select
                        size="sm"
                        name={"boardSpecialtyName"}
                        autoComplete="off"
                        value={formik.values.boardSpecialtyName}
                        defaultValue={formik.values.boardSpecialtyName}
                        className="nb-select nb-input"
                        onChange={(e) => {
                          formik.handleChange({
                            target: {
                              name: "boardSpecialtyName",
                              value: e.target.value,
                            },
                          });
                          let boardSpecialtyId = e.target[
                            e.target.selectedIndex
                          ].getAttribute("data-boardSpecialtyId");
                          formik.handleChange({
                            target: {
                              name: "boardSpecialtyId",
                              value: boardSpecialtyId,
                            },
                          });
                        }}
                      >
                        <option value={formik.values.boardSpecialtyName}>
                          {formik.values.boardSpecialtyName}
                        </option>
                        {boardSpecialty?.length > 0 &&
                          boardSpecialty
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <option
                                key={item.specialityId}
                                data-boardSpecialtyId={item.specialityId}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                      </Form.Select>
                      {formik.touched.boardSpecialtyName && formik.errors.boardSpecialtyName ? (
                        <div
                          className="form-error"
                          data-testid="boardSpecialtyNameError"
                        >
                          {formik.errors.boardSpecialtyName}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="block">
                      <Form.Label>{labels.CONTRACTED_SPECIALITY}</Form.Label>
                      <Form.Select
                        size="sm"
                        id={"contractedSpecialty"}
                        autoComplete="off"
                        name={"contractedSpecialtyName"}
                        value={formik.values.contractedSpecialtyName}
                        defaultValue={formik.values.contractedSpecialtyName}
                        className="nb-select nb-input"
                        onChange={(e) => {
                          formik.handleChange({
                            target: {
                              name: "contractedSpecialtyName",
                              value: e.value,
                            },
                          });
                          let contractedSpecialtyId = e.target[
                            e.target.selectedIndex
                          ].getAttribute("data-contractedSpecialtyId");
                          formik.handleChange({
                            target: {
                              name: "contractedSpecialtyId",
                              value: contractedSpecialtyId,
                            },
                          });
                        }}
                      >
                        <option value={formik.values.contractedSpecialtyName}>
                          {formik.values.contractedSpecialtyName}
                        </option>
                        {contractedSpecialty?.length > 0 &&
                          contractedSpecialty
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <option
                                key={item.specialityId}
                                data-contractedSpecialtyId={item.specialityId}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                      </Form.Select>
                    </div> */}
                  </div>
                  <div className="main-block create_provider_select">
                    <div className="block">
                      <Form.Label>{labels.PREFERED_EMAIL_ADDRESS}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="---"
                        name="preferredEmailAddress"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.preferredEmailAddress}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerPreferredEmailAddress"
                        className={
                          formik.touched.preferredEmailAddress &&
                          formik.errors.preferredEmailAddress
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.preferredEmailAddress &&
                      formik.errors.preferredEmailAddress ? (
                        <div
                          className="form-error"
                          data-testid="providerPreferredEmailAddressError"
                        >
                          {formik.errors.preferredEmailAddress}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.SENTARA_EMAIL_ADDRESS}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="---"
                        name="sentaraEmailAddress"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.sentaraEmailAddress}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providersentaraEmailAddress"
                        className={
                          formik.touched.sentaraEmailAddress &&
                          formik.errors.sentaraEmailAddress
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.sentaraEmailAddress &&
                      formik.errors.sentaraEmailAddress ? (
                        <div
                          className="form-error"
                          data-testid="providersentaraEmailAddressError"
                        >
                          {formik.errors.sentaraEmailAddress}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  <div
                    className="location-banner provider-loc"
                    data-testid="locationBanner"
                  >
                    <Row>
                      <Col
                        md={8}
                        xs={12}
                        className="practice-loc-title"
                        data-testid="practicelocationTitle"
                      >
                        <h4>Practice Assignments</h4>
                      </Col>
                      <Col md={4} xs={12} className="ta-right">
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="add-location"
                          data-testid="AddLocationbutton"
                        >
                          {/* <span>
                            <img src={addIcon} alt="add icon" />
                          </span> */}
                          Add Practice Assignments
                        </Button>
                        {modalShow && (
                          <AddLocationModalPageAdmin
                            data-testid="LocationModel"
                            show={modalShow}
                            onHide={() => {
                              setModalShow(false);
                            }}
                            clickHandler={clickHandler}
                            practiceManagerId={practiceManagerId}
                            addLocationList={allLocationData}
                            id="0"
                            filteredAllData={filteredAllData}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="provider-location-table">
                    <Table data-testid="providerLocationtable">
                      <thead>
                        <tr>
                          <th width="18%">Location Number - Name</th>
                          <th width="15%">Address</th>
                          <th width="15%">Contracted Specialty</th>
                          <th className="phone-head5" width="5%">
                            Provider at Location
                          </th>
                          <th className="phone-head4" width="12%">Is Primary?</th>
                          <th className="phone-head6" width="8%">
                            <span className="date_text">Start Date</span>
                            <button
                              className="sort_icon_arrow pl2"
                              type="button"
                              onClick={sortDataByStartDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                          <th className="phone-head6" width="8%">
                            <span className="date_text">End Date </span>
                            <button
                              className="sort_icon_arrow pl2"
                              type="button"
                              onClick={sortDataByEndDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody data-testid="ProviderData">
                        <tr>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchInput}
                                onChange={handleSearchChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchAddress}
                                onChange={handleSearchAddressChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchStartDate}
                                onChange={handleSearchStartDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column1">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchEndDate}
                                onChange={handleSearchEndDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        {filteredAllData?.map((item) => {
                          const formattedStartDate = item.startDate
                            ? new Date(item.startDate)
                            : null;
                          const formattedTermDate = item.termDate
                            ? new Date(item.termDate)
                            : null;

                          return (
                            <React.Fragment key={item?.id}>
                              {item?.practice?.location?.map((locationItem) => (
                                <tr key={locationItem.id}>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {locationItem?.locationNumber}
                                    &nbsp;-&nbsp;
                                    {locationItem?.locationName}
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {`${locationItem?.street1} ${
                                      locationItem?.street2
                                        ? locationItem?.street2
                                        : ""
                                    }, ${locationItem?.city}, ${
                                      locationItem?.state
                                    } ${locationItem?.zipCode}`}
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {locationItem?.locationNumber !== 1 ?
                                      <div className="info" data-testid="contractedSpecialtyName1">
                                        {locationItem?.contractedSpecialtyName}
                                      </div> :
                                      <Form.Select
                                        size="sm"
                                        id={"contractedSpecialty"}
                                        autoComplete="off"
                                        name={"contractedSpecialtyName"}
                                        value={locationItem?.contractedSpecialtyName}
                                        defaultValue={locationItem?.contractedSpecialtyName}
                                        className="nb-select select-in-table"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        required
                                        onChange={(e) => {
                                          let localId = item.id;

                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }
                                          let id =
                                            e.target[e.target.selectedIndex].getAttribute(
                                              "data-contractedSpecialtyId"
                                            );

                                          handleContractedSpecialty(localId, id);
                                        }}
                                      >
                                        <option value={locationItem?.contractedSpecialtyName}>
                                          {locationItem?.contractedSpecialtyName}
                                        </option>
                                        {contractedSpecialty?.length > 0 &&
                                          contractedSpecialty
                                            ?.sort((a, b) => a.name.localeCompare(b.name))
                                            ?.map((item) => (
                                              <option
                                                key={item.specialtySpecialtyTypeId}
                                                data-contractedSpecialtyId={item.specialtySpecialtyTypeId}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                      </Form.Select>
                                    }
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1 text-center"
                                        : "Secondary1 text-center"
                                    }
                                  >
                                    <div className="info">
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="Yes"
                                        data-testid="providerAtLocationYes"
                                        value={item?.isProviderTrue}
                                        checked={item?.isProviderTrue}
                                        onChange={(e) => {
                                          let localId = item.id;

                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }

                                          handleCheckYes(e, localId);
                                        }}
                                      />
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="No"
                                        data-testid="providerAtLocationNo"
                                        value={item?.isProviderFalse}
                                        checked={item?.isProviderFalse}
                                        onChange={(e) => {
                                          let localId = item.id;
                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }
                                          handleCheckNo(e, localId);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div className="info">
                                        <Form.Check
                                          inline
                                          type="radio"
                                          data-testid="providerPrimary"
                                          value="yes"
                                          checked={item?.assignmentTypeId === 1}
                                          onChange={() => {
                                            if (!addFlow) {
                                              handleRadioSelection(
                                                item?.practiceId
                                              );
                                              updatePrimaryLocation(
                                                item?.practiceId
                                              );
                                            } else {
                                              handleRadioSelectionAddFlow(
                                                item?.localPracticeId
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        item?.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    ></td>
                                  )}

                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editstartDateInput"
                                      >
                                        <DatePicker
                                          dateFormat="MM/dd/yyyy"
                                          selected={formattedStartDate}
                                          onChange={(date) => {
                                            let localId = item.id;

                                            if (addFlow) {
                                              localId = item.localPracticeId;
                                            }

                                            // endDateFn(date, localId);
                                            startDateFn(date, localId);
                                          }}
                                          placeholderText="MM/DD/YYYY"
                                          data-testid="editproviderStartDate"
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item?.startDate
                                        ? moment(item?.startDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editendDateInput"
                                      >
                                        <DatePicker
                                          disabled={item.isProviderTrue}
                                          dateFormat="MM/dd/yyyy"
                                          data-testid="editproviderEndDate"
                                          selected={formattedTermDate}
                                          placeholderText="MM/DD/YYYY"
                                          onChange={(date) => {
                                            let localId = item.id;

                                            if (addFlow) {
                                              localId = item.localPracticeId;
                                            }

                                            endDateFn(date, localId);
                                          }}
                                          required
                                          minDate={formattedStartDate}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item?.termDate
                                        ? moment(item?.termDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="add-location-footer">
                    {errorMessage && (
                      <p className="Error-message">{errorMessage}</p>
                    )}
                    <div className="d-flex justify-content-end">
                      <button
                        className="blue-btn"
                        type="submit"
                        disabled={isLoading}
                        data-testid="EditConfirmButton"
                      >
                        {isLoading ? "Saving..." : "Save"}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
        {hideData && viewProviderId && (
          <EditPracticeNewProvider
            practiceManagerId={decodeToken?.id || practiceManagerId}
            providerSummary={providerSummary}
            id={id}
            selectedPracticeId={selectedPracticeId}
            practiceTitle={practiceTitle}
            personRoleName={personRoleName}
          />
        )}
      </Container>
    </div>
  );
};

export default EditPracticeNewProvider;
