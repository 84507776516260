import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import { Popover } from "react-bootstrap";
import tooltipData from "../ContactInformation/ContactTooltip.json";
import ContactInformation from "../ContactInformation/ContactInformation";

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.mdOfficeID}</p>
    </Popover.Body>
  </Popover>
);
const popover1 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.additional}</p>
    </Popover.Body>
  </Popover>
);
const popover2 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">{tooltipData.director}</p>
    </Popover.Body>
  </Popover>
);
const popover3 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">
        To change this please email{" "}
        <a href="mailto:SQCNPPD@Sentara.com">SQCNPPD@Sentara.com</a> This is the
        executive leader who has signing priviledges for your practice(s)
      </p>
    </Popover.Body>
  </Popover>
);
const popover4 = (
  <Popover id="popover-basic">
    <Popover.Body>
      <p className="contact-tooltip-text">
        The change to this field can only be initiated by SQCN admin. Please
        email changes to
        <a href="mailto:SQCNPPD@Sentara.com">SQCNPPD@Sentara.com</a>
      </p>
    </Popover.Body>
  </Popover>
);

function ContactInformationPage({
  selectedPracticeId,
  practiceManagerId,
  contactInfoUpdate,
  contactpracticeInfoUpdate,
}) {
  const [isData, setIsData] = useState([]);
  const token = cookies.get("access");
  const contactInfoGetUpdate = () => {
    const base_url = process.env.REACT_APP_SERVER_URL;
    const subscriptionKey = process.env.REACT_APP_TOKEN;
    fetch(base_url + `/api/v1/practices/${selectedPracticeId}/contact`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data1", data);
        setIsData(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      contactInfoGetUpdate();
    };
    fetch();
  }, [selectedPracticeId]);
  return (
    <>
      <ContactInformation
        data={isData}
        selectedPracticeId={selectedPracticeId}
        practiceManagerId={practiceManagerId}
        contactInfoUpdate={contactInfoUpdate}
        contactInfoGetUpdate={contactInfoGetUpdate}
        contactpracticeInfoUpdate={contactpracticeInfoUpdate}
        popover={popover}
        popover1={popover1}
        popover2={popover2}
        popover3={popover3}
        popover4={popover4}
      />
    </>
  );
}

export default ContactInformationPage;
