/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Table, Button } from "react-bootstrap";
import { firstLetterOfTwo } from "utils";
// import { Link } from "react-router-dom";
// import practiceIcon from "./images/Icon-b-practice.svg";
// import providerListIcon from "./images/Icon-b-provider-list.svg";
// import userIcon from "./images/Icon-b-user.svg";
import sortIcon from "./images/Sort_arrow.svg";
// import editIcon from "./images/Icon-b-edit.svg";
import labels from "Static/Dictionary.json";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFetch } from "Components/Hooks/Fetch";
// import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import cookies from "js-cookie";
// import ApproveIcon from "Components/UI/Atoms/ApproveIcon";
import AddLocationModalPageAdmin from "../DataPages/AddLocationModalPageAdmin";
import jwt_decode from "jwt-decode";
import datePickerIcon from "../../../images/datepicker_icon.svg";
import closeIcon from "./images/close-icon.svg";
import updatedIcon from "./images/Icon-updated.svg";
import { Alert } from "react-bootstrap";
import { formatDate, formatDateByTimeZone } from "Static/format";

function convertLocalToUTCDate(date) {
  return date ? new Date(date) : null;
}

const ExistingProvider = ({
  practiceTitle,
  providerSummary,
  selectedPracticeId,
  practiceManagerId,
  personRoleName,
  id,
  // locationPayload,
  // setLocationPayload,
  // setContactInformationData,
  // contactInformationData,
  addFlow,
  setViewProviderIdModal,
  setUpdatedProviderList,
  updatedProviderList,
  providerListGetUpdate,
  setRefreshData,
  refreshData,
  locationPayload,
  titleData
}) => {
  const [providerEdit, setProviderEdit] = useState({});
  const [providerPrefix, setProviderPrefix] = useState({
    prefixId: providerSummary?.prefixId,
  });
  const [providerPrefixName, setProviderPrefixName] = useState("");
  const [success, setSuccess] = useState(false);
  // const [changeFieldData, setChangeFieldData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  // const [sltdSpltyId, setSltdSpltyId] = useState(null);
  // const [spltyName, setSpltyName] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  // const [newSpecialty, setNewSpecialty] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  //const [addLocationList, setAddLocationListData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  // const [primaryPracticeId, setPrimaryPracticeId] = useState(null);
  const [primaryLocData, setPrimayLocData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const base_url = process.env.REACT_APP_SERVER_URL1;
  const base_url1 = process.env.REACT_APP_SERVER_URL;
  const { data = [] } = useFetch("/api/v1/providers/GetAllTitleTypes");
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");
  const selectedPracticeId1 =
    localStorage.getItem("selectedPracticeId") || selectedPracticeId;
  const [viewProviderId, setViewProviderId] = useState(false);
  // const [showAlert, setshowAlert] = useState(false);
  const [hideData, setHideData] = useState(false);
  const decodeToken = jwt_decode(token);
  //const [locationListedData, setLocationListedData] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [allSuffixData, setAllSuffixData] = useState([]);
  const [selectedSuffix, setSelectedSuffix] = useState(providerSummary?.suffix);
  // const [providerEffectiveDate, setProviderEffDate] = useState(null);
  //const [addLocationFromModal, setAddLocationFromModal] = useState(null);
  // const base_url00 = process.env.REACT_APP_SERVER_URL;
  const practiceId = localStorage.getItem("selectedPracticeIdByAdmin");
  const [prefixData, setPrefixData] = useState([]);

  const prefix_mapTitles = [
    "16",
    "41",
    "24",
    "61",
    "31",
    "62",
    "63",
    "5",
    "11",
    "36",
    "1",
    "66",
    "67",
    "32",
    "68",
    "22",
    "84",
    "23",
    "45",
  ];

  const { data: contractedSpecialty = [] } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=1"
  );

  const { data: boardSpecialty = [] } = useFetch(
    "/api/v1/component/getpracticespecialities?specialityTypeId=2"
  );

  const { data: GetSuffixData } = useFetch("/api/v1/contact/GetSuffixData");
  useEffect(() => {
    if (GetSuffixData) {
      setAllSuffixData(GetSuffixData);
    }
  }, [GetSuffixData]);

  const fixSonar = () => {
    setViewProviderId(false);

    setErrorMessage("");

    setHideData(false);

    console.log("fixSonar", primaryLocData);
  };

  useEffect(() => {
    if (prefixData.length > 0) {
      const prefix = prefixData.find(
        (item) => item.id === providerSummary?.prefixId
      );

      if (prefix) {
        setProviderPrefix({ prefixId: prefix.id });
        setProviderPrefixName(prefix.prefixName);
      }
    }
  }, [prefixData, providerSummary]);

  const getSuffixName = (suffixId) => {
    if (allSuffixData && allSuffixData?.length > 0) {
      const suffix = allSuffixData.find((item) => item.id === suffixId);
      if (suffix) {
        return suffix.suffixName;
      }
      return "";
    }
    return "";
  };
  //   const navigation = useNavigate();
  const fetchPracticeChangeRecords = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetProviderUnderProcessById?providerId=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };
  useEffect(() => {
    const fetch = async () => {
      fetchPracticeChangeRecords();
    };
    fetch();
  }, [id]);

  const handleIdChange = (name, value) => {
    if (name === "prefixId") {
      setProviderPrefix({ prefixId: value });
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setProviderPrefixName(value);
  };

  const GetTitleDetails = () => {
    fetch(base_url1 + "/api/v1/contact/GetTitleDetails", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPrefixData(data?.prefixList || []);
      });
  };

  const GetPrimaryLocation = (provider) => {
    fetch(
      base_url +
        `/api/v1/providers/GetPracticePrimaryLocation?practiceid=${practiceId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {


        console.log("------------------data-------------", data);
        
console.log("------------------providerSummary?.practiceAssignment-------------", providerSummary?.practiceAssignment);
        if (data) {
          if (providerSummary?.practiceAssignment?.length > 0) {
            let existing = false;
            const { practice } = data;

            //console.log("------------------data-------------", data);

            providerSummary?.practiceAssignment?.map((practiceAssignment) => {
              let localId = practiceAssignment.practice.id;
              if (practice.id === localId) {
                existing = true;
              }
            });

            // console.log("------------------existing-------------", existing);
            if (!existing) {
              clickHandler2([data],provider);
            }
          } else {
            clickHandler2([data],provider);
          }
        }
      });
  };

  //https://localhost:7061/api/v1/providers/7412?practicemanagerid=7245&&isSqcnAdmin=true
  useEffect(() => {
    const fetch = async () => {
      GetTitleDetails();
    };
    fetch();
    fixSonar();
  }, []);

  const fetchPrimaryLocChangeRecords = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetSQCNApprovalFlag?providerId=${id}&practiceaManagerId=${practiceManagerId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error("API request failed with status: " + response.status);
        }
      })
      .then((data) => {
        const trimmedData = data?.trim();
        if (trimmedData.toLowerCase() === "false") {
          setPrimayLocData(false);
        } else if (trimmedData.toLowerCase() === "true") {
          setPrimayLocData(true);
        } else if (trimmedData.toLowerCase() === "pending") {
          setPrimayLocData(false);
        } else {
          setPrimayLocData(false);
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
      });
  };

  const providerLocationGetUpdate = () => {
    fetch(
      base_url +
        `/api/v1/providers/GetLocationByPracticeManagerId?practiceManagerId=${practiceManagerId}&personid=${id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.practiceAssignment?.length > 0) {
          console.log("data", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    const base_url00 = process.env.REACT_APP_SERVER_URL;
    const selectedPracticeIdByAdmin = localStorage.getItem(
      "selectedPracticeIdByAdmin"
    );
    fetch(
      base_url00 +
        `/api/v1/practices/${selectedPracticeIdByAdmin}/location?includePending=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setLocationListedData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    fetch(base_url + "/api/v1/providers/GetAllPracticeAssignmentsLocations", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "ocp-apim-subscription-key": subscriptionKey,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          setAllLocationData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      providerLocationGetUpdate();
    };
    fetchData();
  }, [selectedPracticeId1]);

  const getPrefixByName = (prefixName) => {
    if (prefixData?.length > 0) {
      const prefix = prefixData?.filter(
        (item) => item.prefixName === prefixName
      );
      if (prefix.length > 0) {
        return prefix[0].id;
      }
    }
    return "";
  };

  const handlePrefixChange = (id) => {
    console.log("id", id);
    const DrId = getPrefixByName("Dr");
    const value = { ...providerPrefix, prefixId: DrId };
    setProviderPrefix(value);
    setProviderPrefixName("Dr");
  };
  // useEffect(() => {
  //   if (addFlow) {
  //     let localPractice = providerSummary?.practiceAssignment;
  //     if (locationPayload?.length > 0) {
  //       let payload = [{ practice: { location: locationPayload } }];
  //       if (localPractice?.length > 0) {
  //         localPractice = [...localPractice, ...payload];
  //       } else {
  //         localPractice = [...payload];
  //       }
  //       const value = localPractice?.map((item, index) => ({
  //         ...item,
  //         localPracticeId: index + 1,
  //         isProviderTrue: item?.termDate === null ? true : false,
  //         isProviderFalse: item?.termDate === null ? false : true,
  //         isDefaultId: item?.assignmentTypeId === 1 ? true : false,
  //         assignmentTypeId: item?.assignmentTypeId === 1 || 2,
  //       }));

  //       const provider = { ...providerSummary, practiceAssignment: value };

  //       setProviderEdit(provider);
  //       // if(practiceId){
  //       //   GetPrimaryLocation(provider);
  //       // }


    
  //     }
  //   } else {
  //     const value = providerSummary?.practiceAssignment?.map((item) => ({
  //       ...item,
  //       isProviderTrue: item?.termDate === null ? true : false,
  //       isProviderFalse: item?.termDate === null ? false : true,
  //       isDefaultId: item?.assignmentTypeId === 1 ? true : false,
  //       contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
  //       contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
  //       practice: {
  //         ...item.practice,
  //         location: item?.practice?.location?.map((location) => ({
  //           ...location,
  //           contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
  //           contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
  //         })),
  //       },
  //     }));
  //     const provider = { ...providerSummary, practiceAssignment: value };



  //     console.log("------------------else------provider-------", provider);
  //    setProviderEdit(provider);
   
  //     GetPrimaryLocation(provider);
   

  //   }

  //   providerSummary && setSelectedTitle(selectedTitle);
  //   providerSummary && setSelectedSuffix(selectedSuffix);
  // }, [providerSummary]);

  useEffect(() => {
    if(contractedSpecialty.length > 0) {
      if (addFlow) {
        let localPractice = providerSummary?.practiceAssignment;
        if (locationPayload?.length > 0) {
          let payload = [{ practice: { location: locationPayload } }];
          if (localPractice?.length > 0) {
            localPractice = [...localPractice, ...payload];
          } else {
            localPractice = [...payload];
          }
          const value = localPractice?.map((item, index) => ({
            ...item,
            localPracticeId: index + 1,
            isProviderTrue: item?.termDate === null ? true : false,
            isProviderFalse: item?.termDate === null ? false : true,
            isDefaultId: item?.assignmentTypeId === 1 ? true : false,
            assignmentTypeId: item?.assignmentTypeId,
            contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
            contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
            practice: {
              ...item.practice,
              location: item?.practice?.location?.map((location) => ({
                ...location,
                contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
                contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
              })),
            },
          }));
          const provider = { ...providerSummary, practiceAssignment: value };
          setProviderEdit(provider);
        }
      } else {
        if(providerSummary?.practiceAssignment.length > 0) {
          const value = providerSummary?.practiceAssignment?.map((item) => ({
            ...item,
            isProviderTrue: item?.termDate === null ? true : false,
            isProviderFalse: item?.termDate === null ? false : true,
            isDefaultId: item?.assignmentTypeId === 1 ? true : false,
            practice: {
              ...item.practice,
              location: item?.practice?.location?.map((location) => ({
                ...location,
                contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
                contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === item?.contractedSpecialtyId)?.name,
              })),
            },
          }));
          const provider = { ...providerSummary, practiceAssignment: value };
          setProviderEdit(provider);
          GetPrimaryLocation(provider);
        }
      }
    }
    providerSummary && setSelectedTitle(selectedTitle);
    providerSummary && setSelectedSuffix(selectedSuffix);
  }, [providerSummary, contractedSpecialty]);

  const prefferedEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Preferred"
  );
  const sentaraEmailByemailtype = providerSummary?.email?.find(
    (item) => item?.emailType?.typeEmail === "Sentara"
  );
  //console.log(providerSummary, "providerSummary");
  const firstLetters = firstLetterOfTwo(
    providerSummary?.firstName,
    providerSummary?.lastName
  );

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .max(25, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Name cannot contain numbers ")
      .required("First Name is required"),
    middleName: yup
      .string()
      .nullable()
      .max(25, "Too Long!")
      .matches(/^[aA-zZ\s]+$/, "Name cannot contain numbers"),
    lastName: yup
      .string()
      .max(25, "Too Long!")
      .matches(/^[A-Za-z\s'_.-]+$/, "Name cannot contain numbers ")
      .required("Last Name is required"),
    mdofficeId: yup.string().max(25, "Too Long!"),
    npi: yup
      .string()
      .required("NPI is required")
      .min(10, "NPI should be 10 digits")
      .max(10, "NPI should be 10 digits")
      .matches(/^[0-9]+$/, "Must be only digits"),
    preferredEmailAddress: yup
      .string()
      .email("Invalid email format")
      .required("Email required."),
    // sentaraEmailAddress: yup.string().email("Invalid email format"),
    boardSpecialtyName: yup.string().required("Board Specialty is required"),
  });

  // const validateFieldsFnContracted = (prevData, currentData) => {
  //   const specialtyDetailsByCurrentData =
  //     currentData?.specialtySpecialtyType?.find(
  //       (item) => item?.specialtyTypeId?.toString() === "1"
  //     );
  //   console.log("prd", prevData, "crd", currentData);
  //   if (
  //     prevData?.npi?.toString() === currentData?.npi?.toString() &&
  //     specialtyDetailsByContractedSpeciality?.specialty?.id?.toString() ===
  //       specialtyDetailsByCurrentData?.specialtyId?.toString()
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      firstName: providerSummary && providerSummary?.firstName,
      middleName: providerSummary && providerSummary?.middleName,
      lastName: providerSummary && providerSummary?.lastName,
      mdofficeId: providerSummary && providerSummary?.mdofficeId,
      npi: providerSummary && providerSummary?.npi,
      preferredEmailAddress: prefferedEmailByemailtype?.emailAddress,
      sentaraEmailAddress: sentaraEmailByemailtype?.emailAddress,

      suffixName: getSuffixName(providerSummary?.suffixId),
      suffixId: providerSummary && providerSummary?.suffixId,
      // contractedSpecialtyId:
      //   (providerSummary &&
      //     providerSummary?.specialtySpecialtyType?.[0]?.specialtyId) ||
      //   "",
      // contractedSpecialtyName:
      //   (providerSummary &&
      //     providerSummary?.specialtySpecialtyType?.[0]?.specialty
      //       ?.specialtyName) ||
      //   "",

      boardSpecialtyId:
        (providerSummary &&
          providerSummary?.specialtySpecialtyType[0]?.specialtyId) ||
        "",
      boardSpecialtyName:
        (providerSummary &&
          providerSummary?.specialtySpecialtyType[0]?.specialty
            ?.specialtyName) ||
        "",
      providersEffectiveDate:
        providerSummary?.providersEffectiveDate !== null
          ? providerSummary?.providersEffectiveDate
          : null,
      providersNameBeforeEditing:
        providerSummary && providerSummary?.providersNameBeforeEditing,
      title: providerSummary && providerSummary?.titleType[0]?.title,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      // console.log("ons", values);

      let specialtySpecialtyType = [];

      if (values.boardSpecialtyId) {
        specialtySpecialtyType.push({
          specialtyId: parseInt(values.boardSpecialtyId),
          specialtyTypeId: 2,
        });
      }

      if (values.contractedSpecialtyId) {
        specialtySpecialtyType.push({
          specialtyId: parseInt(values.contractedSpecialtyId),
          specialtyTypeId: 1,
        });
      }
      const titleVal = [
        titleData?.find((title) => title.title === values?.title),
      ];
      const titleTypeData = values?.title ? titleVal : [];
      console.log("titleTypeData---", titleTypeData);
      const payload = {
        updateUser: practiceManagerId,
        prefixId: parseInt(providerPrefix?.prefixId),
        PersonId: providerSummary?.id,
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        titleType: titleTypeData,
        boardSpeciality: values.boardSpecialtyName,
        npi: values?.npi,
        mdofficeId: values?.mdofficeId,
        preferredEmail: values?.preferredEmailAddress,
        providersEffectiveDate:
          values?.providersEffectiveDate &&
          formatDate(values?.providersEffectiveDate),
        sentaraEmail: values?.sentaraEmailAddress,
        suffixId: values.suffixId,
        IsPrimaryCare: false,
        // contractedSpecialtyID: parseInt(values?.contractedSpecialtyId),

        specialtySpecialtyType: specialtySpecialtyType,

        practiceAssignment: filteredAllData?.map((item) => ({
          startDate: item?.startDate
            ? moment(item?.startDate).format("YYYY-MM-DD")
            : "",
          termDate: item?.termDate
            ? moment(item?.termDate).format("YYYY-MM-DD")
            : undefined,
          practiceId: item.practiceId,
          roleTypeId: 2,
          assignmentTypeId: item?.assignmentTypeId === null ? 2 : item?.assignmentTypeId,
          personId: providerSummary?.id,
          // contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
        })),
      };
      if (addFlow) {
          let email = [
            {
              emailTypeId: 1,
              emailAddress: values.preferredEmailAddress,
            },
          ];
          if (values.sentaraEmailAddress) {
            let obj = {
              emailTypeId: 2,
              emailAddress: values.sentaraEmailAddress,
            };
            email.push(obj);
          }

        let practiceAssignment2 = filteredAllData?.map((item) => ({
          startDate: item?.startDate
            ? moment(item?.startDate).format("YYYY-MM-DD")
            : "",
          termDate: item?.termDate
            ? moment(item?.termDate).format("YYYY-MM-DD")
            : undefined,
          practiceId: item.practiceId,
          roleTypeId: 2,
          assignmentTypeId: item?.assignmentTypeId,
          practice: item.practice,
          personId: id,
          contractedSpecialtyId: parseInt(item?.contractedSpecialtyId),
        }));

        const localLocations = {
          ...providerEdit,
          ...payload,
          email: email,
          specialtySpecialtyType: specialtySpecialtyType,
          ...{ practiceAssignment: practiceAssignment2 },
          existingProvider: true
        };

        let localProviders = [...updatedProviderList];
        localProviders.push(localLocations);
        localProviders.forEach((item, index) => {
          item.localId = index + 1;
        });
        setUpdatedProviderList(localProviders);
        setViewProviderIdModal(false);
      } else {
        // reqBody.practiceAssignment = [...providerEdit.practiceAssignment];

        // const reqBody = { ...providerEdit, ...payload, ...emailList };

        let putPayload = payload;

        putPayload = { ...payload, id: parseInt(id), personStatusId: 1 };
        const value = providerEdit?.practiceAssignment?.map((item) => ({
          ...item,
          personId: providerSummary?.id,
          roleTypeId: 2,
        }));

        putPayload.practiceAssignment = [...value];
        
        let status1 = "";
        const requestOptions2 = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(putPayload),
        };
        await fetch(
          base_url +
            `/api/v1/providers/${id}?primaryLocation=false&&issqcnadmin=true`,
          requestOptions2
        )
          .then((response) => {
            status1 = response?.status;
            return response.json();
          })
          .then((res) => {
            if (Number(status1) === 200) {
              console.log("-------------------------------", res);
            }
          });

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "access-control-allow-credentials": "true",
            "ocp-apim-subscription-key": subscriptionKey,
            "api-supported-versions": "1.0",
          },
          body: JSON.stringify(putPayload),//payload
        };
        let status = "";
        await fetch(
          base_url +
            `/api/v1/providers/CreateExistingProviderForApproval?practiceId=${practiceId}&primaryLocation=${primaryLocData}&isSqcnAdmin=true`,
          requestOptions
        )
          .then((response) => {
            status = response?.status;
            return response.json();
          })
          .then((res) => {
            if (Number(status) === 200) {
              setErrorMessage();
              setIsLoading(false);
              // navigation(`/edit-practice/${"450"}`, {
              //     state: { isCreate: true },
              //   });
              setSuccess(true);
              setViewProviderIdModal(false);

              providerListGetUpdate();
              setRefreshData(!refreshData);
            } else {
              setErrorMessage(res?.message || res?.error);
              setIsLoading(false);
            }
          });

          const contractedSpecialtyPayload = providerEdit?.practiceAssignment?.map((item) => ({
            personId: providerSummary?.id,
            practiceId: item?.practiceId,
            contractSpecialtyId: parseInt(item?.contractedSpecialtyId),
          }));

          const requestOptions_contractedSpecialty = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "access-control-allow-credentials": "true",
              "ocp-apim-subscription-key": subscriptionKey,
              "api-supported-versions": "1.0",
            },
            body: JSON.stringify(contractedSpecialtyPayload),
          };
          setTimeout(async () => {
            await fetch(base_url1 + "/api/v1/practices/UpdateContractId", requestOptions_contractedSpecialty)
              .then((res) => {
                if (Number(status) === 200) {
                  console.log("-----------", res);
                }
              });
        }, 1000);
        // if (locationID?.length > 0) {
        //   const requestOptionsForLocations = {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: "Bearer " + token,
        //       "access-control-allow-credentials": "true",
        //       "ocp-apim-subscription-key": subscriptionKey,
        //       "api-supported-versions": "1.0",
        //     },
        //     body: JSON.stringify(locationID[0]),
        //   };
        //   fetch(
        //     base_url00 +
        //       `/api/v1/contact/MapLocationforPractice?providerid=${id}`,
        //     requestOptionsForLocations
        //   )
        //     .then((response) => {
        //       status = response?.status;
        //       return response.json();
        //     })
        //     .then((res) => {
        //       console.log(res);

        //       setViewProviderIdModal(false);

        //       providerListGetUpdate();
        //       setRefreshData(!refreshData);
        //     });
        // } else {
        // setViewProviderIdModal(false);

        // providerListGetUpdate();
        // setRefreshData(!refreshData);
        // }
      }
    },
  });
  const providerEffectiveDate1 = (date) => {
    if (date !== null) {
      return new Date(date);
      // } else if (providerEffectiveDate !== null) {
      //   return providerEffectiveDate;
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    formik.setValues({
      ...formik.values,
      firstName: providerSummary && providerSummary?.firstName,
      middleName: providerSummary && providerSummary?.middleName,
      lastName: providerSummary && providerSummary?.lastName,
      mdofficeId: providerSummary && providerSummary?.mdofficeId,
      npi: providerSummary && providerSummary?.npi,
      preferredEmailAddress: prefferedEmailByemailtype?.emailAddress,
      sentaraEmailAddress: sentaraEmailByemailtype?.emailAddress,
      providersEffectiveDate: providerEffectiveDate1(
        providerSummary?.providersEffectiveDate
      ),
      //providerEffectiveDate ? providerEffectiveDate : (providerSummary.providersEffectiveDate ? new Date(providerSummary.providersEffectiveDate) : null),

      providersNameBeforeEditing:
        providerSummary && providerSummary?.providersNameBeforeEditing,
    });
  }, [
    providerSummary,
    prefferedEmailByemailtype,
    sentaraEmailByemailtype,
    // providerEffectiveDate,
  ]);

  const handleCheckYes = (e, itemId) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      let localId = item.id;

      if (addFlow) {
        localId = item.localPracticeId;
      }

      if (localId === itemId) {
        return {
          ...item,
          isProviderTrue: e.target.checked,
          isProviderFalse: !e.target.checked,
          termDate: null,
          // assignmentTypeId: item?.isDefaultId === true ? 1 : 2,
        };
      }
      return item;
    });

    console.log("---------------------------handleCheckYes---------providerEdit-------------------",providerEdit);
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const handleCheckNo = (e, itemId) => {
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      let localId = item.id;

      if (addFlow) {
        localId = item.localPracticeId;
      }

      if (localId === itemId) {
        return {
          ...item,
          isProviderTrue: !e.target.checked,
          isProviderFalse: e.target.checked,
          // assignmentTypeId: 2,
          assignmentType: {
            ...item.assignmentType,
            // id: 2,
          },
        };
      }
      return item;
    });

    console.log("---------------------------handleCheckNo---------providerEdit-------------------",providerEdit);
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };
  const handleContractedSpecialty = (itemId, selectedId) => {
    if(contractedSpecialty.length > 0) {
      const updatedData = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;
  
        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return {
            ...item,
            contractedSpecialtyId: parseInt(selectedId),
            practice: {
              ...item.practice,
              location: item?.practice?.location?.map((location) => ({
                ...location,
                contractedSpecialtyId: parseInt(selectedId),
                contractedSpecialtyName: contractedSpecialty && contractedSpecialty.find((obj) => obj.specialtySpecialtyTypeId === parseInt(selectedId))?.name,
              })),
            },
          };
        }
        return item;
      });
      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
    }
  }
  const handleRadioSelection = (pid) => {
    fetchPrimaryLocChangeRecords();
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item?.practiceId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.practiceId === pid ? 1 : 2,
          },
        };
      } else {
        return {
          ...item,
          assignmentTypeId: item?.practiceId === pid ? 1 : 2,
          ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2,
        };
      }
    });

    // if (item?.isDefaultId && isSelect) {
    //   return {
    //     ...item,
    //     isProviderTrue: true,
    //     isProviderFalse: false,
    //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //     assignmentType: {
    //       ...item.assignmentType,
    //       id: item?.practiceId === pid ? 1 : 2,
    //     },
    //   };
    // } else if (!item?.isDefaultId) {
    //   return {
    //     ...item,
    //     isProviderTrue: true,
    //     isProviderFalse: false,
    //     termDate: null,
    //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //     assignmentType: {
    //       ...item.assignmentType,
    //       id: item?.practiceId === pid ? 1 : 2,
    //     },
    //   };
    // } else if (item.isDefaultId) {
    //   return {
    //     ...item,
    //     isProviderTrue: true,
    //     isProviderFalse: false,
    //     termDate: null,
    //     assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //     assignmentType: {
    //       ...item.assignmentType,
    //       id: item?.practiceId === pid ? 1 : 2,
    //     },
    //   };
    // }
    // return {
    //   ...item,
    //   assignmentTypeId: item?.practiceId === pid ? 1 : 2,
    //   ["assignmentType.id"]: item?.practiceId === pid ? 1 : 2,
    // };
    // });
    //console.log("up", selectedPracticeId1, item);

    console.log("---------------------------handleRadioSelection---------providerEdit-------------------",providerEdit);
    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
  };

  const handleRadioSelectionAddFlow = (pid) => {
    // fetchPrimaryLocChangeRecords();
    const updatedData = providerEdit?.practiceAssignment?.map((item) => {
      if (item?.localPracticeId === pid) {
        return {
          ...item,
          isProviderTrue: true,
          isProviderFalse: false,
          assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
          assignmentType: {
            ...item.assignmentType,
            id: item?.localPracticeId === pid ? 1 : 2,
          },
        };
      } else {
        return {
          ...item,
          assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
          ["assignmentType.id"]: item?.localPracticeId === pid ? 1 : 2,
        };
      }
    });

    //   if (item?.isDefaultId && isSelect) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (!item?.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   } else if (item.isDefaultId) {
    //     return {
    //       ...item,
    //       isProviderTrue: true,
    //       isProviderFalse: false,
    //       termDate: null,
    //       assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //       assignmentType: {
    //         ...item.assignmentType,
    //         id: item?.localPracticeId === pid ? 1 : 2,
    //       },
    //     };
    //   }
    //   return {
    //     ...item,
    //     assignmentTypeId: item?.localPracticeId === pid ? 1 : 2,
    //     ["assignmentType.id"]: item?.localPracticeId === pid ? 1 : 2,
    //   };
    // });

    
    console.log("---------------------------handleRadioSelectionAddFlow---------providerEdit-------------------",providerEdit);

    setProviderEdit({ ...providerEdit, practiceAssignment: updatedData });
    // setPrimaryPracticeId(pid);
  };

  // const providerEffDate = (date) => {
  //   setProviderEdit({
  //     ...providerEdit,
  //     providersEffectiveDate: convertLocalToUTCDate(date),
  //   });
  //   setProviderEffDate(date);
  // };
  const startDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);

    if (filteredAllData.length > 0) {
      const updatedData = filteredAllData?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, startDate: isoDateString };
        }
        return item;
      });

      const updatedData2 = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, startDate: isoDateString };
        }
        return item;
      });


      console.log("---------------------------startDateFn---------providerEdit-------------------",providerEdit);
      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData2 });

      setFilteredAllData(updatedData);
    }
  };
  const endDateFn = (date, itemId) => {
    const selectedDate = convertLocalToUTCDate(date);
    const isoDateString = formatDateByTimeZone(selectedDate);
    if (filteredAllData.length > 0) {
      const updatedData = filteredAllData?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }

        if (localId === itemId) {
          return { ...item, termDate: isoDateString };
        }
        return item;
      });

      const updatedData2 = providerEdit?.practiceAssignment?.map((item) => {
        let localId = item.id;

        if (addFlow) {
          localId = item.localPracticeId;
        }
        if (localId === itemId) {
          return { ...item, termDate: isoDateString };
        }
        return item;
      });

      setFilteredAllData(updatedData);

console.log("---------------------------endDateFn---------providerEdit-------------------",providerEdit);

      setProviderEdit({ ...providerEdit, practiceAssignment: updatedData2 });
    }
  };

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleSearchAddressChange = (e) => {
    const input = e.target.value;
    setSearchAddress(input);
  };

  const handleSearchStartDateChange = (e) => {
    const input = e.target.value;
    setSearchStartDate(input);
  };

  const handleSearchEndDateChange = (e) => {
    const input = e.target.value;
    setSearchEndDate(input);
  };

  useEffect(() => {
    console.log("-----------------providerEdit-----------", providerEdit);
    const newFilteredData = providerEdit?.practiceAssignment?.map((item) => {
      return item;
    });



    console.log("-----------------newFilteredData-----------",newFilteredData);
    if (newFilteredData?.length > 0) {
      setFilteredAllData(newFilteredData);
    }
  }, [
    providerEdit,
  ]);


  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortDataByStartDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.startDate).diff(moment(b.startDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };

  const sortDataByEndDate = () => {
    const sortedData = [...filteredAllData];
    sortedData.sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      return order * moment(a.termDate).diff(moment(b.termDate));
    });
    setFilteredAllData(sortedData);
    toggleSortOrder();
  };
  // const primaryPending = filteredAllData?.filter(
  //   (item) => item?.status === "Pending" && item?.assignmentTypeId === 1
  // );

  // const locationID = addLocationFromModal?.map((item) =>
  //   item.practice.location.map((location) => location.id)
  // );

  const clickHandler = (locations) => {
    //   setAddLocationFromModal(locations);
    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
      isNewLocation: true,
    }));

    //setAddLocationFromModal(fData);

   // let localFilterData = [];

    // if (filteredAllData?.length > 0) {
    //   localFilterData = [...filteredAllData];
    // }



    // console.log("--------------localFilterData-------------------------",localFilterData);
    // if (localFilterData?.length > 0) {
    //   setFilteredAllData([...localFilterData, ...fData]);
    // } else {
    //   setFilteredAllData(fData);
    // }

    // if (
    //   addFlow &&
    //   locationPayload.length > 0 &&
    //   (filteredAllData === undefined || filteredAllData.length === 0)
    // ) {
    //   setFilteredAllData(fData);
    // }

    let localPracticeEdit = { ...providerEdit };

     console.log("--------------localPracticeEdit-------------------------",localPracticeEdit);

    let updatedPracticeAssignment = [];

    if (localPracticeEdit?.practiceAssignment?.length > 0) {
     // updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment];

      updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment, ...fData];
    } else {
      updatedPracticeAssignment = fData;
    }
    updatedPracticeAssignment.forEach((item, index) => {
      item.localPracticeId = index + 1;
    });

    localPracticeEdit.practiceAssignment = updatedPracticeAssignment;

    console.log("--------------localPracticeEdit----after---------------------",localPracticeEdit);

    setProviderEdit(localPracticeEdit);
  };

  const clickHandler2 = (locations,localPracticeEdit) => {
    //   setAddLocationFromModal(locations);
    const fData = locations?.map((item) => ({
      ...item,
      isProviderTrue: item?.termDate === null ? true : false,
      isProviderFalse: item?.termDate === null ? false : true,
      isDefaultId: item?.assignmentTypeId === 1 ? true : false,
      assignmentTypeId: 2,
      isNewLocation: true,
    }));

    //setAddLocationFromModal(fData);

   // let localFilterData = [];

    // if (filteredAllData?.length > 0) {
    //   localFilterData = [...filteredAllData];
    // }



    // console.log("--------------localFilterData-------------------------",localFilterData);
    // if (localFilterData?.length > 0) {
    //   setFilteredAllData([...localFilterData, ...fData]);
    // } else {
    //   setFilteredAllData(fData);
    // }

    // if (
    //   addFlow &&
    //   locationPayload.length > 0 &&
    //   (filteredAllData === undefined || filteredAllData.length === 0)
    // ) {
    //   setFilteredAllData(fData);
    // }


     console.log("--------------localPracticeEdit-------------------------",localPracticeEdit);

    let updatedPracticeAssignment = [];

    if (localPracticeEdit?.practiceAssignment?.length > 0) {
     // updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment];

      updatedPracticeAssignment = [...localPracticeEdit?.practiceAssignment, ...fData];
    } else {
      updatedPracticeAssignment = fData;
    }
    updatedPracticeAssignment.forEach((item, index) => {
      item.localPracticeId = index + 1;
    });

    localPracticeEdit.practiceAssignment = updatedPracticeAssignment;

    console.log("--------------localPracticeEdit----after---------------------",localPracticeEdit);


    const newFilteredData = localPracticeEdit?.practiceAssignment?.map((item) => {
      return item;
    });



    console.log("-----------------newFilteredData-----------",newFilteredData);
    if (newFilteredData?.length > 0) {
      setFilteredAllData(newFilteredData);
    }


    setProviderEdit(localPracticeEdit);
  };

  console.log("--------------filteredAllData-------------------------",filteredAllData);

  return (
    <div>
      {success && (
        <div>
          <Alert variant="success" data-testid="successProvider">
            <img
              src={updatedIcon}
              alt="Updated Icon"
              className="updated-icon"
            />
            Provider information updated successfully
            <button
              onClick={() => setSuccess(false)}
              className="close-icon-button"
              data-testid="providerProfileupdatedclose"
            >
              <img
                src={closeIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
            </button>
          </Alert>
        </div>
      )}
      <Container className="mt-40">
        {!hideData && (
          <div className="edit-provider">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={2} xs={12}>
                  <div className="provider-profile">
                    <div
                      className="firstletter justify-content-center  d-flex align-items-center"
                      data-testid="providerNamefirstletters"
                    >
                      {firstLetters}
                    </div>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <div className="main-block create_provider">
                    <div className="block">
                      <Form.Label>
                        Providers Effective Date with SQCN
                      </Form.Label>
                      <div className="input-group search-container-date">
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={
                            formik?.values?.providersEffectiveDate !== null
                              ? new Date(formik.values.providersEffectiveDate)
                              : null
                          }
                          onChange={(date) =>
                            formik.handleChange({
                              target: {
                                name: "providersEffectiveDate",
                                value: date,
                              },
                            })
                          }
                          placeholderText="--"
                          data-testid="startDate"
                          className="nb-input width-full"
                          value={
                            (formik.values.providersEffectiveDate &&
                              new Date(formik.values.providersEffectiveDate)) ||
                            ""
                          }
                        />
                        <button
                          type="button"
                          className="search-button right cursor-auto"
                        >
                          <img src={datePickerIcon} alt="DatePicker Icon" />
                        </button>
                      </div>
                    </div>
                    <div className="block">
                      <Form.Label>{labels.NPI}</Form.Label>
                      <Form.Control
                        type="tel"
                        maxLength={10}
                        placeholder="---"
                        name="npi"
                        className={
                          formik.touched.npi && formik.errors.npi
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.npi}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerNpi"
                      />
                      {formik.touched.npi && formik.errors.npi ? (
                        <div
                          className="form-error"
                          data-testid="providerNpiError"
                        >
                          {formik.errors.npi}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.MDOFFICE_ID}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="mdofficeId"
                        className={
                          formik.touched.mdofficeId && formik.errors.mdofficeId
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mdofficeId}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerMDofficeId"
                      />
                      {formik.touched.mdofficeId && formik.errors.mdofficeId ? (
                        <div
                          className="form-error"
                          data-testid="providerMDofficeIdError"
                        >
                          {formik.errors.mdofficeId}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>
                        {labels.PROVIDER_NAME_BEFORE_EDITING}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="providersNameBeforeEditing"
                        className={
                          formik.touched.providersNameBeforeEditing &&
                          formik.errors.providersNameBeforeEditing
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.providersNameBeforeEditing}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providersNameBeforeEditing"
                      />
                      {/* {formik.touched.providersNameBeforeEditing && formik.errors.providersNameBeforeEditing ? (
                        <div
                          className="form-error"
                          data-testid="providersNameBeforeEditingError"
                        >
                          {formik.errors.providersNameBeforeEditing}
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="main-block create_provider_select">
                    <div className="block">
                      <div className="title">{labels.PREFIX}</div>

                      <Form.Select
                        size="md"
                        placeholder="---"
                        id="prefix"
                        autoComplete="off"
                        value={providerPrefixName}
                        defaultValue={providerPrefixName}
                        className="nb-select"
                        name="prefix"
                        // onChange={handleChange}

                        onChange={(e) => {
                          handleInputChange(e);

                          let id =
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-prefixId"
                            );

                          handleIdChange("prefixId", id);
                          //newhandleSelectPrefix()
                        }}
                      >
                        <option value={providerPrefixName}>
                          {providerPrefixName}
                        </option>
                        {prefixData.map((prefixItem, index) => (
                          <option
                            key={prefixItem.prefixName + index}
                            value={prefixItem.prefixName}
                            data-prefixId={prefixItem.id}
                          >
                            {prefixItem.prefixName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className="block">
                      <Form.Label>{labels.FIRST_NAME}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        className={
                          formik.touched.firstName && formik.errors.firstName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        name="firstName"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerFirstName"
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div
                          className="form-error"
                          data-testid="providerFirstNameError"
                        >
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.MIDDLE_INTITIAL}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="middleName"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.middleName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerMiddleName"
                        className={
                          formik.touched.middleName && formik.errors.middleName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.middleName && formik.errors.middleName ? (
                        <div
                          className="form-error"
                          data-testid="providerMiddleNameError"
                        >
                          {formik.errors.middleName}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.LAST_NAME}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        name="lastName"
                        className={
                          formik.touched.lastName && formik.errors.lastName
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerLastName"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div
                          className="form-error"
                          data-testid="providerLastNameError"
                        >
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="main-block create_provider_select">
                    <Col md={3}>
                      <div className="block">
                        <Form.Label>{labels.SUFFIX}</Form.Label>
                        <Form.Select
                          size="sm"
                          name={"suffixName"}
                          autoComplete="off"
                          value={formik.values.suffixName}
                          defaultValue={formik.values.suffixName}
                          className="nb-select nb-input"
                          onChange={(e) => {
                            formik.handleChange({
                              target: {
                                name: "suffixName",
                                value: e.value,
                              },
                            });
                            let suffixId =
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-suffixId"
                              );
                            formik.handleChange({
                              target: {
                                name: "suffixId",
                                value: suffixId,
                              },
                            });
                          }}
                        >
                          <option value={formik.values.suffixName}>
                            {formik.values.suffixName}
                          </option>
                          {allSuffixData?.length > 0 &&
                            allSuffixData?.map((suffix) => (
                              <option
                                key={suffix.specialityId}
                                data-suffixId={suffix.id}
                                value={suffix.suffixName}
                              >
                                {suffix.suffixName}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="block">
                        <Form.Label className="label-align">{labels.TITLE}</Form.Label>
                        <Form.Select
                          size="md"
                          id="title"
                          autoComplete="off"
                          name="title"
                          value={formik.values.title}
                          //defaultValue={selectedTitle}
                          className="nb-select nb-input"
                          onChange={(e) => {
                            // handleInputChange(e, i);

                            setSelectedTitle(e.target.value);

                            formik.handleChange({
                              target: { name: "title", value: e.target.value },
                            });
                            let id =
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-title"
                              );
                            if (prefix_mapTitles.includes(id)) {
                              handlePrefixChange(id);
                            }
                          }}
                        >
                          <option value={selectedTitle}>{selectedTitle}</option>
                          {data.map((titleItem, index) => (
                            <option
                              key={titleItem.title + index}
                              value={titleItem.title}
                              data-title={titleItem.id}
                            >
                              {titleItem.title}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                    <div className="block">
                      <Form.Label>{labels.BOARD_SPECIALITY}</Form.Label>
                      <Form.Select
                        size="sm"
                        name={"boardSpecialtyName"}
                        autoComplete="off"
                        value={formik.values.boardSpecialtyName}
                        defaultValue={formik.values.boardSpecialtyName}
                        className="nb-select nb-input"
                        onChange={(e) => {
                          formik.handleChange({
                            target: {
                              name: "boardSpecialtyName",
                              value: e.target.value,
                            },
                          });
                          let boardSpecialtyId = e.target[
                            e.target.selectedIndex
                          ].getAttribute("data-boardSpecialtyId");
                          formik.handleChange({
                            target: {
                              name: "boardSpecialtyId",
                              value: boardSpecialtyId,
                            },
                          });
                        }}
                      >
                        <option value={formik.values.boardSpecialtyName}>
                          {formik.values.boardSpecialtyName}
                        </option>
                        {boardSpecialty?.length > 0 &&
                          boardSpecialty
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <option
                                key={item.specialityId}
                                data-boardSpecialtyId={item.specialityId}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                      </Form.Select>
                      {formik.touched.boardSpecialtyName && formik.errors.boardSpecialtyName ? (
                        <div
                          className="form-error"
                          data-testid="boardSpecialtyNameError"
                        >
                          {formik.errors.boardSpecialtyName}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="block">
                      <Form.Label>{labels.CONTRACTED_SPECIALITY}</Form.Label>
                      <Form.Select
                        size="sm"
                        id={"contractedSpecialty"}
                        autoComplete="off"
                        name={"contractedSpecialtyName"}
                        value={formik.values.contractedSpecialtyName}
                        defaultValue={formik.values.contractedSpecialtyName}
                        className="nb-select nb-input"
                        onChange={(e) => {
                          formik.handleChange({
                            target: {
                              name: "contractedSpecialtyName",
                              value: e.value,
                            },
                          });
                          let contractedSpecialtyId = e.target[
                            e.target.selectedIndex
                          ].getAttribute("data-contractedSpecialtyId");
                          formik.handleChange({
                            target: {
                              name: "contractedSpecialtyId",
                              value: contractedSpecialtyId,
                            },
                          });
                        }}
                      >
                        <option value={formik.values.contractedSpecialtyName}>
                          {formik.values.contractedSpecialtyName}
                        </option>
                        {contractedSpecialty?.length > 0 &&
                          contractedSpecialty
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <option
                                key={item.specialityId}
                                data-contractedSpecialtyId={item.specialityId}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                      </Form.Select>
                    </div> */}
                  </div>
                  <div className="main-block create_provider_select">
                    <div className="block">
                      <Form.Label>{labels.PREFERED_EMAIL_ADDRESS}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="---"
                        name="preferredEmailAddress"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.preferredEmailAddress}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providerPreferredEmailAddress"
                        className={
                          formik.touched.preferredEmailAddress &&
                          formik.errors.preferredEmailAddress
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.preferredEmailAddress &&
                      formik.errors.preferredEmailAddress ? (
                        <div
                          className="form-error"
                          data-testid="providerPreferredEmailAddressError"
                        >
                          {formik.errors.preferredEmailAddress}
                        </div>
                      ) : null}
                    </div>
                    <div className="block">
                      <Form.Label>{labels.SENTARA_EMAIL_ADDRESS}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="---"
                        name="sentaraEmailAddress"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.sentaraEmailAddress}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        data-testid="providersentaraEmailAddress"
                        className={
                          formik.touched.sentaraEmailAddress &&
                          formik.errors.sentaraEmailAddress
                            ? "error-field nb-input"
                            : "nb-input"
                        }
                      />
                      {formik.touched.sentaraEmailAddress &&
                      formik.errors.sentaraEmailAddress ? (
                        <div
                          className="form-error"
                          data-testid="providersentaraEmailAddressError"
                        >
                          {formik.errors.sentaraEmailAddress}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  <div
                    className="location-banner provider-loc"
                    data-testid="locationBanner"
                  >
                    <Row>
                      <Col
                        md={8}
                        xs={12}
                        className="practice-loc-title"
                        data-testid="practicelocationTitle"
                      >
                        <h4>Practice Assignments</h4>
                      </Col>
                      <Col md={4} xs={12} className="ta-right">
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="add-location"
                          data-testid="AddLocationbutton"
                        >
                          {/* <span>
                            <img src={addIcon} alt="add icon" />
                          </span> */}
                          Add Practice Assignments
                        </Button>
                        {modalShow && (
                          <AddLocationModalPageAdmin
                            data-testid="LocationModel"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            clickHandler={clickHandler}
                            practiceManagerId={practiceManagerId}
                            addLocationList={allLocationData}
                            id="0"
                            filteredAllData={filteredAllData}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="provider-location-table">
                    <Table data-testid="providerLocationtable">
                      <thead>
                        <tr>
                          <th width="18%">Location Number - Name</th>
                          <th width="15%">Address</th>
                          <th width="15%">Contracted Specialty</th>
                          <th className="phone-head5" width="5%">
                            Provider at Location
                          </th>
                          <th className="phone-head4" width="12%">Is Primary?</th>
                          <th className="phone-head6" width="8%">
                            <span className="date_text">Start Date</span>
                            <button
                              className="sort_icon_arrow pl2"
                              type="button"
                              onClick={sortDataByStartDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                          <th className="phone-head6" width="8%">
                            <span className="date_text">End Date </span>
                            <button
                              className="sort_icon_arrow pl2"
                              type="button"
                              onClick={sortDataByEndDate}
                            >
                              <img src={sortIcon} alt="Sort Icon" />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody data-testid="ProviderData">
                        <tr>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchInput}
                                onChange={handleSearchChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchAddress}
                                onChange={handleSearchAddressChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column"></td>
                          <td className="search_column">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchStartDate}
                                onChange={handleSearchStartDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                          <td className="search_column1">
                            <Form.Group
                              className="search-npi"
                              controlId="formGroupEmail"
                            >
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Search"
                                autoComplete="off"
                                className="search-input new"
                                value={searchEndDate}
                                onChange={handleSearchEndDateChange}
                                data-testid="LocationName"
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        {filteredAllData?.map((item) => {
                          const formattedStartDate = item?.startDate
                            ? new Date(item?.startDate)
                            : null;
                          const formattedTermDate = item?.termDate
                            ? new Date(item?.termDate)
                            : null;

                          return (
                            <React.Fragment key={item?.id}>
                              {item?.practice?.location?.map((locationItem) => (
                                <tr key={locationItem.id}>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {locationItem?.locationNumber}
                                    &nbsp;-&nbsp;
                                    {locationItem?.locationName}
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {`${locationItem?.street1} ${
                                      locationItem?.street2
                                        ? locationItem?.street2
                                        : ""
                                    }, ${locationItem?.city}, ${
                                      locationItem?.state
                                    } ${locationItem?.zipCode}`}
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1"
                                        : "Secondary1"
                                    }
                                  >
                                    {locationItem?.locationNumber !== 1 ?
                                      <div className="info" data-testid="contractedSpecialtyName1">
                                        {locationItem?.contractedSpecialtyName}
                                      </div> :
                                      <Form.Select
                                        size="sm"
                                        id={"contractedSpecialty"}
                                        autoComplete="off"
                                        name={"contractedSpecialtyName"}
                                        value={locationItem?.contractedSpecialtyName}
                                        defaultValue={locationItem?.contractedSpecialtyName}
                                        className="nb-select select-in-table"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        required
                                        onChange={(e) => {
                                          let localId = item.id;

                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }
                                          let id =
                                            e.target[e.target.selectedIndex].getAttribute(
                                              "data-contractedSpecialtyId"
                                            );

                                          handleContractedSpecialty(localId, id);
                                        }}
                                      >
                                        <option value={locationItem?.contractedSpecialtyName}>
                                          {locationItem?.contractedSpecialtyName}
                                        </option>
                                        {contractedSpecialty?.length > 0 &&
                                          contractedSpecialty
                                            ?.sort((a, b) => a.name.localeCompare(b.name))
                                            ?.map((item) => (
                                              <option
                                                key={item.specialtySpecialtyTypeId}
                                                data-contractedSpecialtyId={item.specialtySpecialtyTypeId}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                      </Form.Select>
                                    }
                                  </td>
                                  <td
                                    className={
                                      locationItem?.locationNumber === 1
                                        ? "Primary1 text-center"
                                        : "Secondary1 text-center"
                                    }
                                  >
                                    <div className="info">
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="Yes"
                                        data-testid="providerAtLocationYes"
                                        value={item?.isProviderTrue}
                                        checked={item?.isProviderTrue}
                                        onChange={(e) => {
                                          let localId = item.id;

                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }

                                          handleCheckYes(e, localId);
                                        }}
                                      />
                                      <Form.Check
                                        inline
                                        type="radio"
                                        disabled={
                                          locationItem?.locationNumber !== 1
                                        }
                                        label="No"
                                        data-testid="providerAtLocationNo"
                                        value={item?.isProviderFalse}
                                        checked={item?.isProviderFalse}
                                        onChange={(e) => {
                                          let localId = item.id;
                                          if (addFlow) {
                                            localId = item.localPracticeId;
                                          }
                                          handleCheckNo(e, localId);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div className="info">
                                        <Form.Check
                                          inline
                                          type="radio"
                                          data-testid="providerPrimary"
                                          value="yes"
                                          checked={item?.assignmentTypeId === 1}
                                          onChange={() => {
                                            if (!addFlow) {
                                              handleRadioSelection(
                                                item?.practiceId
                                              );
                                            } else {
                                              handleRadioSelectionAddFlow(
                                                item?.localPracticeId
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1 text-center"
                                          : "Secondary1 text-center"
                                      }
                                    ></td>
                                  )}

                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1 text-center"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editstartDateInput"
                                      >
                                        <DatePicker
                                          dateFormat="MM/dd/yyyy"
                                          selected={formattedStartDate}
                                          onChange={(date) => {
                                            let localId = item.id;

                                            if (addFlow) {
                                              localId = item.localPracticeId;
                                            }

                                            // endDateFn(date, localId);
                                            startDateFn(date, localId);
                                          }}
                                          placeholderText="MM/DD/YYYY"
                                          data-testid="editproviderStartDate"
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item?.startDate
                                        ? moment(item?.startDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                  {locationItem?.locationNumber === 1 ? (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      <div
                                        className="info"
                                        data-testid="editendDateInput"
                                      >
                                        <DatePicker
                                          disabled={item.isProviderTrue}
                                          dateFormat="MM/dd/yyyy"
                                          data-testid="editproviderEndDate"
                                          selected={formattedTermDate}
                                          placeholderText="MM/DD/YYYY"
                                          onChange={(date) => {
                                            let localId = item.id;

                                            if (addFlow) {
                                              localId = item.localPracticeId;
                                            }

                                            endDateFn(date, localId);
                                            //startDateFn(date, localId);
                                          }}
                                          required
                                          minDate={formattedStartDate}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td
                                      className={
                                        locationItem?.locationNumber === 1
                                          ? "Primary1"
                                          : "Secondary1"
                                      }
                                    >
                                      {item?.termDate
                                        ? moment(item?.termDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "--"}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="add-location-footer">
                    {errorMessage && (
                      <p className="Error-message">{errorMessage}</p>
                    )}
                    <div className="d-flex justify-content-end">
                      {addFlow ? (
                        <button
                          className="blue-btn"
                          type="submit"
                          data-testid="EditConfirmButton"
                        >
                          {isLoading ? "Saving..." : "Save"}
                        </button>
                      ) : (
                        <button
                          className="blue-btn"
                          type="submit"
                          disabled={isLoading}
                          data-testid="EditConfirmButton"
                        >
                          {isLoading ? "Saving..." : "Save"}
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
        {hideData && viewProviderId && (
          <ExistingProvider
            practiceManagerId={decodeToken?.id || practiceManagerId}
            id={id}
            selectedPracticeId={selectedPracticeId}
            practiceTitle={practiceTitle}
            personRoleName={personRoleName}
          />
        )}
      </Container>
    </div>
  );
};

export default ExistingProvider;
