/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AddPractice } from "./AddPractice";
import { useFetch } from "Components/Hooks/Fetch";
import { Alert } from "react-bootstrap";
import labels from "../../../../Static/Dictionary.json";
import moment from "moment";
import closeIcon from "../images/close-icon.svg";
import updatedIcon from "../images/updatedIcon.svg";
import cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { convertBoolean } from "utils";
const AddPracticeInfo = ({practiceInformationData,setPracticeInformationData, selectedPracticeIdByAdmin }) => {
  const [editMode, setEditMode] = useState(true);

  const [success, setSuccess] = useState(false);
  const { data } = useFetch(`/api/v1/practices/${selectedPracticeIdByAdmin}`);

  const { data: markets } = useFetch(
    "/api/v1/contact/GetRSOData"
  );

  const token = cookies.get("access");
  const decodeToken1 = jwt_decode(token);

  useEffect(() => {
    if (data) {
      setPracticeInformationData(data);
    }
  }, [data]);


  useEffect(() => {
    if (!editMode) {
      setPracticeInformationData(updatedPracticeInfo);
    }
  }, [editMode]);

  const setSpecialtyDropdown = (specialtyId) => {

    if (
      practiceInformationData?.specialtySpecialtyType &&
      practiceInformationData?.specialtySpecialtyType.length > 0
    ) {
      const specialty = practiceInformationData.specialtySpecialtyType.filter(
        (item) => item.specialtyTypeId === specialtyId
      );
      if (specialty.length > 0) {
        return specialty[0]?.specialty?.specialtyName;
      }
    }
    return "";
  };


  const getRegionNameById = (id) => {
    if(markets?.length > 0 && id){
      let region = markets.filter((item) => {
        return item.id == id;
    });
    if(region?.length > 0){
      return region[0].rso;
    }
    return "";
    }
    return "";
  
  
  }
  let initialValues = {
    practiceGroupId: practiceInformationData?.practiceGroupId || "",
    emRid: practiceInformationData?.emRid || "",
    practiceNameLegal: practiceInformationData?.practiceNameLegal || "",
    reportingGroupId: practiceInformationData?.reportingGroupId || "",
    primaryNpi: practiceInformationData?.primaryNpi || "",
    secondaryNpi: practiceInformationData?.secondaryNpi || "",
    isSqcn: practiceInformationData?.isSqcn || false,
    isPrimaryCare:
    convertBoolean(practiceInformationData?.isPrimaryCare) || false,
    isAco: convertBoolean(practiceInformationData?.isAco)  || false,
    isAffiliated:
    convertBoolean(practiceInformationData?.isAffiliated)  || false,
    practiceManagementSystem:
      practiceInformationData?.practiceManagementSystem || "",
    claimsClearingHouse: practiceInformationData?.claimsClearingHouse || "",
    takingNewPatients:
    convertBoolean(practiceInformationData?.takingNewPatients)  || false,
    takingMedicaidPatients:
    convertBoolean(practiceInformationData?.takingMedicaidPatients)  || false,
    lowerAgeLimit: practiceInformationData?.lowerAgeLimit || "",
    directMessageAddress: practiceInformationData?.directMessageAddress || "",
    telehealth: practiceInformationData?.telehealth || "",
    telehealthOption: practiceInformationData?.telehealthOption || "",
    hasEpicCareLinkAccess:
    convertBoolean(practiceInformationData?.hasEpicCareLinkAccess)  || false,
    uses837: convertBoolean(practiceInformationData?.uses837)  || false,
    adt: convertBoolean(practiceInformationData?.adt)  || false,
    ccda: convertBoolean(practiceInformationData?.ccda)   || false,
    oru: convertBoolean(practiceInformationData?.oru)  || false,
    additionalCommentsForAudits:
      practiceInformationData?.additionalCommentsForAudits || "",
    payments: practiceInformationData?.payments || "",
    emr2015cert: practiceInformationData?.emr2015cert || "",
    tin: practiceInformationData?.tin || "",
    emr: practiceInformationData?.emr?.emr || "",
    emrLocal:practiceInformationData?.emrLocal || "",
    emrOption: practiceInformationData?.emrOption || "",
    startDate: practiceInformationData?.startDate && new Date(practiceInformationData?.startDate) || "",
    termDate: practiceInformationData?.termDate && new Date(practiceInformationData?.termDate) || "",
    aetnaBROnly:
    convertBoolean(practiceInformationData?.aetnaBROnly)  || false,
    johnHopkinsUSFamilyHealthPlan:
    convertBoolean(practiceInformationData?.johnHopkinsUSFamilyHealthPlan)  || false,
    optimaCommercial:
    convertBoolean(practiceInformationData?.optimaCommercial)  || false,
    optimaMedicaid:
    convertBoolean(practiceInformationData?.optimaMedicaid)  || false,
    humanaMA:
    convertBoolean(practiceInformationData?.humanaMA)  || false,
    optimaMedicareAdvantage:
      practiceInformationData?.optimaMedicareAdvantage,
    specialty: setSpecialtyDropdown(3),
    specialty1: setSpecialtyDropdown(4),
    specialty2: setSpecialtyDropdown(5),
    specialty3: setSpecialtyDropdown(6),
    rsoId: practiceInformationData?.rsoId,
    rsoName: getRegionNameById(practiceInformationData?.rsoId) || "",
    superGroup: practiceInformationData?.superGroup || "",
    superGroupId: practiceInformationData?.supergroupId || "",
    location1: practiceInformationData?.location?.length > 0 ? practiceInformationData?.location[0]?.locationName : "" ,
    InnovaccerDesignatedName: practiceInformationData?.practiceGroup?.innovaccerTinname || "",
    notesRegardingTermDates: practiceInformationData.notesRegardingTermDates || "",
    practiceNameBeforeEditing:practiceInformationData.practiceNameBeforeEditing || "",

    // practiceGroup: practiceInformationData.practiceGroup,
    practiceGroup: {
      tin: practiceInformationData?.tin || "",
      supergroupId: practiceInformationData?.supergroupId || "",
      practiceGroupName: practiceInformationData?.practiceGroupName || "",
    },

    tinOption: practiceInformationData?.tinOption || "",
    careManagers: [],

  };

  const handleEditClick = () => {
    setEditMode(true);
  };


  let updatedPracticeInfo = { ...initialValues, ...practiceInformationData };
  

  return (
    <>

{success && (
        <div>
          <Alert variant="success" data-testid="successProvider">
            <img
              src={updatedIcon}
              alt="Updated Icon"
              className="updated-icon"
            />
            Practice information updated successfully
            <button
              onClick={() => setSuccess(false)}
              className="close-icon-button"
              data-testid="providerProfileupdatedclose"
            >
              <img
                src={closeIcon}
                alt="Updated Icon"
                className="updated-icon"
              />
            </button>
          </Alert>
        </div>
      )}
      <React.Fragment>
        {editMode ? (
          <div>
            <AddPractice
              selectedPracticeIdByAdmin={selectedPracticeIdByAdmin}
              initialValues={updatedPracticeInfo}
              practiceInformationData={practiceInformationData}
              setEditMode={setEditMode}
              setSuccess={setSuccess}
              careManagers={practiceInformationData.careManagers}
              setPracticeInformationData={setPracticeInformationData}
              updateUser={decodeToken1.id}
            />
          </div>
        ) : (
          <div>
            <div>
              <Row className="mb-4">
                <Col md={10}></Col>
                <Col md={2}>
                  <button
                    className="edit-contact-button"
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                </Col>
              </Row>
              <div>
                <div className="main-block">
                  <div className="block">
                    <div className="title" data-testid="title">
                      {labels.Practice_ID}
                    </div>
                    {updatedPracticeInfo?.id ? (
                      <div className="info" data-testid="NameData">
                        {updatedPracticeInfo?.id}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoNameData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Practice_Reporting_ID} </div>
                    {practiceInformationData?.reportingGroupId ? (
                      <div className="info" data-testid="TinData">
                        {practiceInformationData.reportingGroupId}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoTinData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Start_Date}</div>
                    {updatedPracticeInfo?.startDate ? (
                      <div className="info" data-testid="NpiData">
                        {moment(updatedPracticeInfo.startDate).format(
                          "MM-DD-YYYY"
                        )}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoNpiData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Term_Date}</div>
                    {updatedPracticeInfo?.termDate ? (
                      <div className="info" data-testid="termDate">
                        {moment(updatedPracticeInfo.termDate).format(
                          "MM-DD-YYYY"
                        )}
                      </div>
                    ) : (
                      <div className="info" data-testid="termDate">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.SQCN}</div>

                    <div className="info" data-testid="EmrData">
                      {updatedPracticeInfo.isSqcn === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.ACO}</div>

                    <div className="info" data-testid="TelehealthData">
                      {updatedPracticeInfo.isAco === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Affiliated} </div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo.isAffiliated === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Market_Region} </div>
                    {updatedPracticeInfo.rsoName ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.rsoName}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Supergroup}</div>
                    {updatedPracticeInfo.superGroup ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.superGroup}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.TIN}</div>
                    {updatedPracticeInfo?.tin ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.tin}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">
                      {labels.Innovaccer_Designated_Name}
                    </div>
                    {updatedPracticeInfo?.InnovaccerDesignatedName ? (
                      <div
                        className="info"
                        data-testid="InnovaccerDesignatedName"
                      >
                        {updatedPracticeInfo?.InnovaccerDesignatedName}
                      </div>
                    ) : (
                      <div
                        className="info"
                        data-testid="InnovaccerDesignatedName"
                      >
                        --
                      </div>
                    )}
                  </div>

               

                  <div className="block">
                    <div className="title">{labels.Practice_Name_Legal}</div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo?.practiceNameLegal}
                    </div>
                  </div>
              
             
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">
                      {labels.Practice_Name_Location1}
                    </div>
                    {updatedPracticeInfo?.location1 ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo.location1}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Primary_NPI}</div>

                    <div className="info" data-testid="SpecialtyData">
                      {updatedPracticeInfo?.primaryNpi}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Secondary_NPI}</div>

                    <div className="info" data-testid="secondaryNpi">
                      {updatedPracticeInfo?.secondaryNpi}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.EMR_Vendor}</div>
                    {updatedPracticeInfo?.emrLocal ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.emrLocal}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>
        
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">{labels.TeleHealth}</div>
                    {updatedPracticeInfo?.telehealth ? (
                      <div className="info" data-testid="TeleHealth">
                        {updatedPracticeInfo?.telehealth}
                      </div>
                    ) : (
                      <div className="info" data-testid="TeleHealth">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Management_System}
                    </div>
                    {updatedPracticeInfo?.practiceManagementSystem ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.practiceManagementSystem}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Practice_Specialty}</div>
                    {updatedPracticeInfo?.specialty ? (
                      <div className="info" data-testid="secondaryNpi">
                        {updatedPracticeInfo?.specialty}
                      </div>
                    ) : (
                      <div className="info" data-testid="secondaryNpi">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty1}
                    </div>
                    {updatedPracticeInfo?.specialty1 ? (
                      <div className="info" data-testid="location1">
                        {updatedPracticeInfo?.specialty1}
                      </div>
                    ) : (
                      <div className="info" data-testid="location1">
                        --
                      </div>
                    )}
                  </div>

                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty2}
                    </div>
                    {updatedPracticeInfo?.specialty2 ? (
                      <div className="info" data-testid="specialty2">
                        {updatedPracticeInfo.specialty2}
                      </div>
                    ) : (
                      <div className="info" data-testid="specialty2">
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Practice_Sub_Specialty3}
                    </div>
                    {updatedPracticeInfo?.specialty3 ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.specialty3}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">{labels.Primary_Care}</div>

                    <div className="info" data-testid="secondaryNpi">
                      {updatedPracticeInfo?.isPrimaryCare === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Previous_Practice_Name}</div>
                    {updatedPracticeInfo?.previousPracticeName ? (
                      <div className="info" data-testid="previousPracticeName">
                        {updatedPracticeInfo?.previousPracticeName}
                      </div>
                    ) : (
                      <div className="info" data-testid="previousPracticeName">
                        --
                      </div>
                    )}
                  </div>
     
                </div>

                <div className="main-block">
                <div className="block">
                    <div className="title">{labels.Accepting_New_Patients}</div>

                    <div className="info" data-testid="takingNewPatients">
                      {updatedPracticeInfo?.takingNewPatients === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">
                      {labels.Accepting_New_Medicaid_Patients}
                    </div>
                    
                      <div className="info" data-testid="SpecialtyData">
                       {updatedPracticeInfo?.takingMedicaidPatients === true
                        ? "Yes"
                        : "No"}
                      </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Lower_Age_Limit}</div>
                    {updatedPracticeInfo?.lowerAgeLimit ? (
                      <div className="info" data-testid="lowerAgeLimit">
                        {updatedPracticeInfo?.lowerAgeLimit}
                      </div>
                    ) : (
                      <div className="info" data-testid="lowerAgeLimit">
                        --
                      </div>
                    )}
                  </div>
            
            

                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Payments}
                    </div>
                    {practiceInformationData?.payments ? (
                      <div className="info" data-testid="payments">
                        {updatedPracticeInfo?.payments}
                      </div>
                    ) : (
                      <div className="info" data-testid="takingNewPatients">
                        --
                      </div>
                    )}
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">
                      {labels.Additional_Comments_For_Audits}
                    </div>
                    {updatedPracticeInfo?.additionalCommentsForAudits ? (
                      <div className="info" data-testid="SpecialtyData">
                        {updatedPracticeInfo?.additionalCommentsForAudits}
                      </div>
                    ) : (
                      <div className="info" data-testid="NoSpecialtyData">
                        --
                      </div>
                    )}
                  </div>

                  <div className="block">
                    <div className="title">
                      {labels.Notes_Regarding_Term_Dates}
                    </div>
                    {updatedPracticeInfo?.notesRegardingTermDates ? (
                      <div
                        className="info"
                        data-testid="notesRegardingTermDates"
                      >
                        {updatedPracticeInfo?.notesRegardingTermDates}
                      </div>
                    ) : (
                      <div
                        className="info"
                        data-testid="notesRegardingTermDates"
                      >
                        --
                      </div>
                    )}
                  </div>
                  <div className="block">
                    <div className="title">{labels.Claims_Clearing_House}</div>
                    {updatedPracticeInfo?.claimsClearingHouse ? (
                      <div className="info" data-testid="claimsClearingHouse">
                        {updatedPracticeInfo?.claimsClearingHouse}
                      </div>
                    ) : (
                      <div className="info" data-testid="claimsClearingHouse">
                        --
                      </div>
                    )}
                  </div>
                </div>
                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Care_Manager_Name}</div>


                   {updatedPracticeInfo?.careManagers?.length > 0?<>


<p className="info" data-testid="CareManagerName"> {updatedPracticeInfo?.careManagers.map(item => {return `${item.firstName} ${item.lastName}`}).join()}</p>
  </>: <p className="info" data-testid="NoCareManagerName">
                      --
                    </p>
                   
                   }
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels._837}</div>

                    <div className="info" data-testid="claimsClearingHouse">
                      {updatedPracticeInfo?.uses837 === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.CCDA}</div>

                    <div className="info" data-testid="ccda">
                      {updatedPracticeInfo?.ccda === true ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="block"/>
                  <div className="block"/>
                  <div className="block">
                    </div>
                    </div>

                  <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Aetna_BR_Only}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.aetnaBROnly === true ? "Yes" : "No"}
                    </div>
                    </div>
                 

                  <div className="block">
                    <div className="title">{labels.Humana_MA}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.humanaMA === true ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.John_Hopkins_US}</div>

                    <div className="info" data-testid="aetnaBROnly">
                      {updatedPracticeInfo?.johnHopkinsUSFamilyHealthPlan ===
                      true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>

                <div className="main-block">
                  <div className="block">
                    <div className="title">{labels.Optima_Commercial}</div>

                    <div className="info" data-testid="claimsClearingHouse">
                      {updatedPracticeInfo?.optimaCommercial === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>

                  <div className="block">
                    <div className="title">{labels.Optima_Medicaid}</div>

                    <div className="info" data-testid="optimaMedicaid">
                      {updatedPracticeInfo?.optimaMedicaid === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="block">
                    <div className="title">{labels.Optima_MA}</div>

                    <div className="info" data-testid="optimaMedicareAdvantage">
                      {updatedPracticeInfo?.optimaMedicareAdvantage === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </>
  );
};

export default AddPracticeInfo;
