/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, OverlayTrigger, Form, Alert, Container } from "react-bootstrap";
import cookies from "js-cookie";
import { findByPracticeRoleType } from "utils";
import labels from "Static/Dictionary.json";
import ReadonlyIcon from "./readonly-Icon.svg";
import tooltipIcon from "./tooltipIcon.svg";
import userRemoveIcon from "./images/icon-remove.svg";
import ErrorServer from "Components/Hooks/Error404";
import { Formik } from "formik";
import * as Yup from "yup";
import("./_style.scss");
import icon from "../ProviderList/Icon.svg";

function ContactInformation({
  data,
  selectedPracticeId,
  contactInfoUpdate,
  isContactServerError,
  setEditModeBox,
  popover,
  popover1,
  popover2,
  popover3,
  popover4,
  contactInfoGetUpdate
}) {
  const [contactInfoError, setContactInfoError] = useState("");
  const [pmData, setPMData] = useState();
  const [managerData, setManagerData] = useState();
  const [additionalPoc, setAdditionalPoc] = useState([]);
  const [adData, setAdData] = useState();
  const [directorData, setDirectorData] = useState([]);
  const [directFirstData, setDirectFirstData] = useState();
  const [pcData, setPcData] = useState([]);
  const [physcData, setPhyscData] = useState();
  const [validationError, setValidationError] = useState({});
  const [reInitialize, setReInitialize] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const errorStyle = {
    color: "#000",
  };
  const base_url = process.env.REACT_APP_SERVER_URL;
  const subscriptionKey = process.env.REACT_APP_TOKEN;
  const token = cookies.get("access");

  const [practicePhysicianChampion] = findByPracticeRoleType(
    data,
    "Practice Physician Champion"
  );
  const [practiceManager] = findByPracticeRoleType(data, "Practice Manager");
  const [director] = findByPracticeRoleType(data, "Director");
  const [AdditionalPoint] = findByPracticeRoleType(
    data,
    "Additional Practice Contact"
  );
  const [executive] = findByPracticeRoleType(
    data,
    "Executive/Leadership Contact"
  );
  const primarySqcnByRoleTypeId = pmData?.practiceAssignment?.find(
    (item) => item?.roleTypeId === 5
  );
  const addprimarySqcnByRoleTypeId = adData?.practiceAssignment?.find(
    (item) => item?.roleTypeId === 5
  );
  const [additionalPocValuesData, setAdditionalPocValuesData] = useState({
    additionalID: additionalPoc[0]?.id || AdditionalPoint?.id || null,
    additionalFName: "",
    additionalLName: additionalPoc[0]?.lastName || adData?.lastName || "",
    additionalEmail: additionalPoc[0]?.email?.[0]?.emailAddress || adData?.email?.[0]?.emailAddress || "",
    additionalPhNo: additionalPoc[0]?.phoneNumber?.[0]?.number || adData?.phoneNumber?.[0]?.number || "",
    additionalPhNoExt: additionalPoc[0]?.phoneNumber?.[0]?.extention || adData?.phoneNumber?.[0]?.extention || "",
    additionalMdId: additionalPoc[0]?.mdofficeId || adData?.mdofficeId || "",
    additionalSqcnY: (additionalPoc[0]?.practiceAssignment &&
      additionalPoc[0]?.practiceAssignment.find(item => item?.roleTypeId === 5)
      ? "Yes"
      : false) || (addprimarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
    additionalSqcnN: addprimarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
  });
  const [directorvalueData, setDirectorValueData] = useState({
    directorID: directorData && directorData[0]?.id && directorData[0]?.id ||
      director && director?.id && director?.id || null,
    directorFName: directorData && directorData[0]?.firstName && directorData[0]?.firstName ||
      directFirstData?.firstName && directFirstData?.firstName !== " " && directFirstData?.firstName || "",
    directorLName: directorData && directorData[0]?.lastName && directorData[0]?.lastName ||
      directFirstData?.lastName && directFirstData?.lastName !== " " && directFirstData?.lastName || "",
    directorEmail:
      (directorData && directorData[0]?.email && directorData[0].email[0]?.emailAddress) ||
      (directFirstData && directFirstData.email && directFirstData.email[0]?.emailAddress) ||
      "",
    directorPhNo:
      (directorData && directorData[0]?.phoneNumber && directorData[0].phoneNumber[0]?.number) ||
      (directFirstData && directFirstData.phoneNumber && directFirstData.phoneNumber[0]?.number) ||
      "",
    directorPhNoExt:
      (directorData && directorData[0]?.phoneNumber && directorData[0]?.phoneNumber[0]?.extention) ||
      (directFirstData && directFirstData?.phoneNumber && directFirstData?.phoneNumber[0]?.extention) ||
      "",
    directorMdId: directorData && directorData[0]?.mdofficeId && directorData[0]?.mdofficeId ||
      directFirstData?.mdofficeId && directFirstData?.mdofficeId !== " " && directFirstData?.mdofficeId || "",
    directorSqcnY: (directorData &&
      directorData[0]?.practiceAssignment &&
      directorData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
      ? "Yes"
      : false) ||
      (directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
    directorSqcnN: directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
  });
  const [physicianValueData, setPhsicianvalueData] = useState({
    PhysicianID: pcData && pcData[0]?.id && pcData[0]?.id ||
      practicePhysicianChampion && practicePhysicianChampion?.id && practicePhysicianChampion?.id || null,
    PhysicianFName: pcData && pcData[0]?.firstName && pcData[0]?.firstName || physcData?.firstName || "",
    PhysicianLName: pcData && pcData[0]?.lastName && pcData[0]?.lastName || physcData?.lastName || "",
    PhysicianEmail:
      (pcData && pcData[0]?.email && pcData[0].email[0]?.emailAddress) ||
      (physcData && physcData.email && physcData.email[0]?.emailAddress) ||
      "",
    physicianPhNo:
      (pcData && pcData[0]?.phoneNumber && pcData[0].phoneNumber[0]?.number) ||
      (physcData && physcData.phoneNumber && physcData.phoneNumber[0]?.number) ||
      "",
    physicianPhNoExt:
      (pcData && pcData[0]?.phoneNumber && pcData[0]?.phoneNumber[0]?.extention) ||
      (physcData && physcData?.phoneNumber && physcData?.phoneNumber[0]?.extention) ||
      "",
    PhysicianMdId: pcData && pcData[0]?.mdofficeId && pcData[0]?.mdofficeId ||
      physcData?.mdofficeId || "",
    physicianSqcnY: (pcData &&
      pcData[0]?.practiceAssignment &&
      pcData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
      ? "Yes"
      : false) ||
      (physcData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
    physicianSqcnN: physcData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
  });
  const prevSelectedPracticeId = useRef(selectedPracticeId);
  useEffect(() => {
    if (prevSelectedPracticeId.current !== selectedPracticeId) {
      setEditModeBox(false);
    }
    prevSelectedPracticeId.current = selectedPracticeId;
  }, [selectedPracticeId, setEditModeBox]);

  useEffect(() => {
    setPMData({ ...pmData, ...practiceManager });
    setAdData({ ...adData, ...AdditionalPoint });
    setDirectFirstData({ ...directFirstData, ...director });
    setPhyscData({ ...physcData, ...practicePhysicianChampion });
  }, [practiceManager, AdditionalPoint, director, practicePhysicianChampion]);

  const handleMdOfficeIdChange = (e, role, roleId) => {
    console.log("roleId", roleId);
    const { value } = e.target;
    if (value.trim() !== "") {
      fetchContactRecords(value, role, roleId);
    } else {
      switch (role) {
        case "manager":
          setManagerData({});
          break;
        case "additionalPoc":
          setAdditionalPoc({});
          break;
        case "director":
          setDirectorData({});
          break;
        case "pc":
          setPcData({});
          break;
        default:
          break;
      }
    }
  };

  const fetchContactRecords = (mdOfficeId, role, roleId) => {
    if(mdOfficeId?.length > 4) {
    fetch(
      base_url +
      `/api/v1/practices/ContactInfoByMdofficeIdAndPracticeId?practiceid=${selectedPracticeId}&mdOfficeId=${mdOfficeId}&roleTypId=${roleId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": subscriptionKey,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("API Request Failed");
        }
      })
      .then((data) => {
        switch (role) {
          case "manager":
            setManagerData({ ...managerData, ...data });
            setPMData({});
            break;
          case "additionalPoc":
            setAdditionalPoc({ ...additionalPoc, ...data });
            setAdData({});
            setReInitialize(true);
            break;
          case "director":
            setDirectorData({ ...directorData, ...data });
            setDirectFirstData({});
            setReInitialize(true);
            break;
          case "pc":
            setPcData({ ...pcData, ...data });
            setPhyscData({});
            setReInitialize(true);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
    }
  };
  const handleRemoveData = (role) => {
    switch (role) {
      case "manager":
        setManagerData({});
        setPMData({});
        break;
      case "additionalPoc":
        setAdditionalPoc({});
        setAdData({});
        break;
      case "director":
        setDirectorData({});
        setDirectFirstData({});
        break;
      case "pc":
        setPcData({});
        setPhyscData({});
        break;
      default:
        break;
    }
  }
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setAdditionalPocValuesData((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    
    try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  const handledirInputChange = (e) => {
    const { id, value } = e.target;
    setDirectorValueData((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
     try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  const handlephyInputChange = (e) => {
    const { id, value } = e.target;
    setPhsicianvalueData((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    try {
      if (!value.trim()) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        contactInformationSchema.fields[id].validateSync(value, {
          abortEarly: false,
        });
  
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    } catch (error) {
      if (error && error?.errors && Array.isArray(error?.errors) && error?.errors?.length > 0) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: error?.errors[0],
        }));
      } else {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      }
    }
  };

  React.useEffect(() => {
    setAdditionalPocValuesData({
      additionalID: additionalPoc[0]?.id || AdditionalPoint?.id || null,
      additionalFName: additionalPoc[0]?.firstName || adData?.firstName || "",
      additionalLName: additionalPoc[0]?.lastName || adData?.lastName || "",
      additionalEmail: additionalPoc[0]?.email?.[0]?.emailAddress || adData?.email?.[0]?.emailAddress || "",
      additionalPhNo: additionalPoc[0]?.phoneNumber?.[0]?.number || adData?.phoneNumber?.[0]?.number || "",
      additionalPhNoExt: additionalPoc[0]?.phoneNumber?.[0]?.extention || adData?.phoneNumber?.[0]?.extention || "",
      additionalMdId: additionalPoc[0]?.mdofficeId || adData?.mdofficeId || "",
      additionalSqcnY: (additionalPoc[0]?.practiceAssignment &&
        additionalPoc[0]?.practiceAssignment.find(item => item?.roleTypeId === 5)
        ? "Yes"
        : false) || (addprimarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
      additionalSqcnN: addprimarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
    });
  }, [additionalPoc, adData]);

  React.useEffect(() => {
    setDirectorValueData({
      directorID: directorData && directorData[0]?.id && directorData[0]?.id ||
        director && director?.id && director?.id || null,
      directorFName: directorData && directorData[0]?.firstName && directorData[0]?.firstName ||
        directFirstData?.firstName && directFirstData?.firstName !== " " && directFirstData?.firstName || "",
      directorLName: directorData && directorData[0]?.lastName && directorData[0]?.lastName ||
        directFirstData?.lastName && directFirstData?.lastName !== " " && directFirstData?.lastName || "",
      directorEmail:
        (directorData && directorData[0]?.email && directorData[0].email[0]?.emailAddress) ||
        (directFirstData && directFirstData.email && directFirstData.email[0]?.emailAddress) ||
        "",
      directorPhNo:
        (directorData && directorData[0]?.phoneNumber && directorData[0].phoneNumber[0]?.number) ||
        (directFirstData && directFirstData.phoneNumber && directFirstData.phoneNumber[0]?.number) ||
        "",
      directorPhNoExt:
        (directorData && directorData[0]?.phoneNumber && directorData[0]?.phoneNumber[0]?.extention) ||
        (directFirstData && directFirstData?.phoneNumber && directFirstData?.phoneNumber[0]?.extention) ||
        "",
      directorMdId: directorData && directorData[0]?.mdofficeId && directorData[0]?.mdofficeId ||
        directFirstData?.mdofficeId && directFirstData?.mdofficeId !== " " && directFirstData?.mdofficeId || "",
      directorSqcnY: (directorData &&
        directorData[0]?.practiceAssignment &&
        directorData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
        (directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
      directorSqcnN: directFirstData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
    });
  }, [directorData, directFirstData]);

  React.useEffect(() => {
    setPhsicianvalueData({
      PhysicianID: pcData && pcData[0]?.id && pcData[0]?.id ||
        practicePhysicianChampion && practicePhysicianChampion?.id && practicePhysicianChampion?.id || null,
      PhysicianFName: pcData && pcData[0]?.firstName && pcData[0]?.firstName || physcData?.firstName || "",
      PhysicianLName: pcData && pcData[0]?.lastName && pcData[0]?.lastName || physcData?.lastName || "",
      PhysicianEmail:
        (pcData && pcData[0]?.email && pcData[0].email[0]?.emailAddress) ||
        (physcData && physcData.email && physcData.email[0]?.emailAddress) ||
        "",
      physicianPhNo:
        (pcData && pcData[0]?.phoneNumber && pcData[0].phoneNumber[0]?.number) ||
        (physcData && physcData.phoneNumber && physcData.phoneNumber[0]?.number) ||
        "",
      physicianPhNoExt:
        (pcData && pcData[0]?.phoneNumber && pcData[0]?.phoneNumber[0]?.extention) ||
        (physcData && physcData?.phoneNumber && physcData?.phoneNumber[0]?.extention) ||
        "",
      PhysicianMdId: pcData && pcData[0]?.mdofficeId && pcData[0]?.mdofficeId ||
        physcData?.mdofficeId || "",
      physicianSqcnY: (pcData &&
        pcData[0]?.practiceAssignment &&
        pcData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
        (physcData?.practiceAssignment?.find((item) => item?.roleTypeId === 5) ? "Yes" : "No"),
      physicianSqcnN: physcData?.practiceAssignment?.find((item) => item?.roleTypeId !== 5) ? "No" : "Yes" || true
    });
  }, [pcData, physcData]);

  const initialValues = {
    practiceManagerID: managerData && managerData[0]?.id && managerData[0]?.id ||
      practiceManager && practiceManager?.id && practiceManager?.id || null,
    practiceManagerName: managerData && managerData[0]?.firstName && managerData[0]?.firstName ||
      pmData?.firstName || "",
    practiceManagerLName: managerData && managerData[0]?.lastName && managerData[0]?.lastName ||
      pmData?.lastName || "",
    practiceManagerEmail:
      (managerData && managerData[0]?.email && managerData[0].email[0]?.emailAddress) ||
      (pmData && pmData.email && pmData.email[0]?.emailAddress) ||
      "",
    practiceManagerPhNo:
      (managerData && managerData[0]?.phoneNumber && managerData[0].phoneNumber[0]?.number) ||
      (pmData && pmData.phoneNumber && pmData.phoneNumber[0]?.number) ||
      "",
    practiceManagerPhNoExt:
      (managerData && managerData[0]?.phoneNumber && managerData[0]?.phoneNumber[0]?.extention) ||
      (pmData && pmData?.phoneNumber && pmData?.phoneNumber[0]?.extention) ||
      "",
    practiceManagerMdId: managerData && managerData[0]?.mdofficeId && managerData[0]?.mdofficeId ||
      pmData?.mdofficeId || "",
    practiceManagerSqcnY:
      (managerData &&
        managerData[0]?.practiceAssignment &&
        managerData[0]?.practiceAssignment.find((item) => item?.roleTypeId === 5)
        ? "Yes"
        : false) ||
      (primarySqcnByRoleTypeId?.roleTypeId === 5 ? "Yes" : "No"),
    practiceManagerSqcnN: primarySqcnByRoleTypeId?.roleTypeId !== 5 ? "No" : "Yes" || true
  };

  const contactInformationSchema = Yup.object().shape({
    practiceManagerName: Yup.string()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    practiceManagerLName: Yup.string()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    practiceManagerEmail: Yup.string()
      .email("Invalid Email address"),
    practiceManagerPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    practiceManagerMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    additionalFName: Yup.string()
      .notRequired()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    additionalLName: Yup.string()
      .notRequired()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    additionalEmail: Yup.string().notRequired().email("Invalid Email address"),
    additionalPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    additionalMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    directorFName: Yup.string()
      .notRequired()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    directorLName: Yup.string()
      .notRequired()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    directorEmail: Yup.string().notRequired().email("Invalid Email address"),
    directorPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX"),
    directorMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
      PhysicianMdId: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Invalid MD Office ID")
      .notRequired(),
    PhysicianFName: Yup.string()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    PhysicianLName: Yup.string()
      .matches(/^[a-zA-Z'`".@:,;*!#$%&()?_-]+$/, "Invalid Name"),
    PhysicianEmail: Yup.string()
      .email("Invalid Email address"),
    physicianPhNo: Yup.string()
      .max(12, "Phone Number should be 10 digits")
      .matches(/^[0-9-]+$/, "Must be only digits")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Please use the format XXX-XXX-XXXX")
  });
  const onSubmitFun = async (values) => {
    console.log(values);

    let objData = {};
    console.log(data);
    if(values.practiceManagerSqcnY === "Yes" || additionalPocValuesData.additionalSqcnY === "Yes" || directorvalueData.directorSqcnY === "Yes" || physicianValueData.physicianSqcnY === "Yes"){
      const pm = {
        personId: values.practiceManagerID,
        mdofficeId: values.practiceManagerMdId,
        firstName: values.practiceManagerName,
        lastName: values.practiceManagerLName,
        phone: values.practiceManagerPhNo,
        ext: values.practiceManagerPhNoExt,
        email: values.practiceManagerEmail,
        psc: values.practiceManagerSqcnY === "Yes" ? 1 : 0,
      };
      objData = { ...objData, pm };

      const apc = {
        personId: additionalPocValuesData.additionalID,
        mdofficeId: additionalPocValuesData.additionalMdId || " ",
        firstName: additionalPocValuesData.additionalFName || " ",
        lastName: additionalPocValuesData.additionalLName || " ",
        phone: additionalPocValuesData.additionalPhNo || " ",
        ext: additionalPocValuesData.additionalPhNoExt || " ",
        email: additionalPocValuesData.additionalEmail || " ",
        psc: additionalPocValuesData.additionalSqcnY === "Yes" ? 1 : 0,
      };
      objData = { ...objData, apc };

      const director = {
        personId: directorvalueData.directorID,
        mdofficeId: directorvalueData.directorMdId || " ",
        firstName: directorvalueData.directorFName || " ",
        lastName: directorvalueData.directorLName || " ",
        phone: directorvalueData.directorPhNo || " ",
        ext: directorvalueData.directorPhNoExt || " ",
        email: directorvalueData.directorEmail || " ",
        psc: directorvalueData.directorSqcnY === "Yes" ? 1 : 0,
      };
      objData = { ...objData, director };

      const pc = {
        personId: physicianValueData.PhysicianID,
        mdofficeId: physicianValueData.PhysicianMdId,
        firstName: physicianValueData.PhysicianFName,
        lastName: physicianValueData.PhysicianLName,
        email: physicianValueData.PhysicianEmail,
        phone: physicianValueData.physicianPhNo,
        ext: physicianValueData.physicianPhNoExt,
        psc: physicianValueData.physicianSqcnY === "Yes" ? 1 : 0,
      };
      objData = { ...objData, pc };

      console.log(objData);
      const payLoad = {
        ...objData,
      };

      console.log("pay", payLoad);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "access-control-allow-credentials": "true",
          "ocp-apim-subscription-key": subscriptionKey,
          "api-supported-versions": "1.0",
        },
        body: JSON.stringify(payLoad),
      };
      let status = "";
      fetch(
        base_url + `/api/v1/practices/${selectedPracticeId}/practiceinsertupdate`,
        requestOptions
      )
        .then((response) => {
          status = response?.status;
          return response.json();
        })
        .then((res) => {
          if (Number(status) === 200) {
            contactInfoUpdate(true);
            contactInfoGetUpdate();
            setEditModeBox(false);
            setContactInfoError();
            setErrorMessage("");
          } else {
            setContactInfoError(res?.message);
          }
        });
    } else {
    setErrorMessage("One primary SQCN contact is required");
  }
  };
  return (
    <React.Fragment>
      {isContactServerError && <ErrorServer />}
      {!isContactServerError && (
        <div className="contact-information">
          <Formik
            initialValues={initialValues}
            validationSchema={contactInformationSchema}
            onSubmit={onSubmitFun}
            enableReinitialize={reInitialize}
          >
            {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <h6 className="contact-tilte">Practice Manager</h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">
                        {labels.MDOFFICE_ID}{" "}
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="right"
                          overlay={popover}
                        >
                          <img
                            src={tooltipIcon}
                            alt="Tooltip Icon"
                            className="tooltip-icon"
                          />
                        </OverlayTrigger>
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagerMdId"
                        autoComplete="off"
                        value={values.practiceManagerMdId}
                        defaultValue={values.practiceManagerMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "manager", 1);
                          setReInitialize(true);
                          handleChange(e);
                        }}
                        isInvalid={
                          !!errors.practiceManagerMdId ||
                          values?.practiceManagerName && !values?.practiceManagerMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerMdId ||
                          values?.practiceManagerName && !values?.practiceManagerMdId && (
                            <p>MDOffice ID cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerName"
                            autoComplete="off"
                            value={values.practiceManagerName}
                            defaultValue={values.practiceManagerName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceManagerName ||
                              values?.practiceManagerMdId && !values?.practiceManagerName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceManagerName ||
                              values?.practiceManagerMdId && !values?.practiceManagerName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={6}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="practiceManagerLName"
                            autoComplete="off"
                            value={values.practiceManagerLName}
                            defaultValue={values.practiceManagerLName}
                            className="nb-input"
                            onChange={handleChange}
                            isInvalid={!!errors.practiceManagerLName ||
                              values?.practiceManagerMdId &&
                              !values?.practiceManagerLName ||
                              values?.practiceManagerName &&
                              !values?.practiceManagerLName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.practiceManagerLName ||
                              (values?.practiceManagerMdId &&
                                !values?.practiceManagerLName) ||
                              (values?.practiceManagerName &&
                                !values?.practiceManagerLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="practiceManagerEmail"
                        autoComplete="off"
                        value={values.practiceManagerEmail}
                        defaultValue={values.practiceManagerEmail}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={
                          !!errors.practiceManagerEmail ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerEmail ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="practiceManagerPhNo"
                        value={values.practiceManagerPhNo}
                        defaultValue={values.practiceManagerPhNo}
                        className="nb-input"
                        onChange={handleChange}
                        isInvalid={!!errors.practiceManagerPhNo ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.practiceManagerPhNo ||
                          values?.practiceManagerMdId &&
                          !values?.practiceManagerPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="practiceManagerPhNoExt"
                        value={values.practiceManagerPhNoExt}
                        defaultValue={values.practiceManagerPhNoExt}
                        className="nb-input"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="practiceManagerSqcnY"
                        className="contactradio"
                        data-testid="practiceManagerSqcnY"
                        disabled={!values?.practiceManagerName}
                        value="Yes"
                        checked={values?.practiceManagerSqcnY === "Yes"}
                        defaultChecked={values?.practiceManagerSqcnY === "Yes"}
                        onChange={(e)=>{
                          handleChange(e);
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});
                          setPhsicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="practiceManagerSqcnY"
                        data-testid="practiceManagerSqcnY"
                        checked={values?.practiceManagerSqcnY === "No"}
                        defaultChecked={values?.practiceManagerSqcnY === "No"}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("manager"),
                            setFieldValue("practiceManagerMdId", "");
                          setFieldValue("practiceManagerName", "");
                          setFieldValue("practiceManagerLName", "");
                          setFieldValue("practiceManagerEmail", "");
                          setFieldValue("practiceManagerPhNo", "");
                          setFieldValue("practiceManagerPhNoExt", "");
                          setFieldValue("practiceManagerSqcnY", "No");
                          setReInitialize(false);
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove Practice Manager</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte">
                  Additional Point Of Contact
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover1}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="additionalMdId"
                        autoComplete="off"
                        value={additionalPocValuesData?.additionalMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "additionalPoc", 4);
                          setReInitialize(true);
                          handleInputChange(e);
                        }}
                        isInvalid={!!validationError?.additionalMdId ||
                          additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalMdId ||
                          additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId && (
                            <p>MDOffice ID cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="additionalFName"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalFName}
                            className="nb-input"
                            onChange={handleInputChange}
                            isInvalid={
                              !!validationError?.additionalFName ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.additionalFName ||
                              additionalPocValuesData?.additionalMdId &&
                              !additionalPocValuesData?.additionalFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={6}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="additionalLName"
                            autoComplete="off"
                            value={additionalPocValuesData?.additionalLName}
                            className="nb-input"
                            onChange={handleInputChange}
                            isInvalid={!!validationError?.additionalLName ||
                              (additionalPocValuesData?.additionalMdId &&
                                !additionalPocValuesData?.additionalLName) ||
                              (additionalPocValuesData?.additionalFName &&
                                !additionalPocValuesData?.additionalLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.additionalLName ||
                              (additionalPocValuesData?.additionalMdId &&
                                !additionalPocValuesData?.additionalLName) ||
                              (additionalPocValuesData?.additionalFName &&
                                !additionalPocValuesData?.additionalLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="additionalEmail"
                        autoComplete="off"
                        value={additionalPocValuesData?.additionalEmail}
                        className="nb-input"
                        onChange={handleInputChange}
                        isInvalid={
                          !!validationError?.additionalEmail ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalEmail ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="additionalPhNo"
                        value={additionalPocValuesData?.additionalPhNo}
                        className="nb-input"
                        onChange={handleInputChange}
                        isInvalid={
                          !!validationError?.additionalPhNo ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.additionalPhNo ||
                          additionalPocValuesData?.additionalMdId &&
                          !additionalPocValuesData?.additionalPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="additionalPhNoExt"
                        value={additionalPocValuesData?.additionalPhNoExt}
                        className="nb-input"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="additionalSqcnY"
                        className="contactradio"
                        data-testid="additionalSqcnY"
                        disabled={!additionalPocValuesData?.additionalFName}
                        value="Yes"
                        checked={additionalPocValuesData?.additionalSqcnY === "Yes"}
                        defaultChecked={additionalPocValuesData?.additionalSqcnY === "Yes"}
                        onChange={(e)=>{
                          handleInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});
                          setPhsicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="additionalSqcnY"
                        data-testid="additionalSqcnY"
                        checked={additionalPocValuesData?.additionalSqcnY === "No"}
                        defaultChecked={additionalPocValuesData?.additionalSqcnY === "No"}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("additionalPoc"),
                            setReInitialize(false),
                            setFieldValue("additionalMdId", "");
                          setFieldValue("additionalFName", "");
                          setFieldValue("additionalLName", "");
                          setFieldValue("additionalEmail", "");
                          setFieldValue("additionalPhNo", "");
                          setFieldValue("additionalPhNoExt", "");
                          setFieldValue("additionalSqcnY", "No");
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Addtional Point Of Contact</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte">
                  Director{" "}
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover2}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="directorMdId"
                        autoComplete="off"
                        value={directorvalueData?.directorMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "director", 3);
                          setReInitialize(true);
                          handledirInputChange(e);
                        }}
                        isInvalid={
                          !!validationError?.directorMdId ||
                          directorvalueData?.directorFName &&
                          !directorvalueData?.directorMdId
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorMdId ||
                          directorvalueData?.directorFName &&
                          !directorvalueData?.directorMdId && (
                            <p>MDOffice ID  cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="directorFName"
                            autoComplete="off"
                            value={directorvalueData?.directorFName}
                            className="nb-input"
                            onChange={handledirInputChange}
                            isInvalid={
                              !!validationError?.directorFName ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.directorFName ||
                              directorvalueData?.directorMdId &&
                              !directorvalueData?.directorFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={6}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="directorLName"
                            autoComplete="off"
                            value={directorvalueData?.directorLName}
                            className="nb-input"
                            onChange={handledirInputChange}
                            isInvalid={
                              !!validationError?.directorLName ||
                              (directorvalueData?.directorMdId &&
                                !directorvalueData?.directorLName) ||
                              (directorvalueData?.directorFName &&
                                !directorvalueData?.directorLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.directorLName ||
                              (directorvalueData?.directorMdId &&
                                !directorvalueData?.directorLName) ||
                              (directorvalueData?.directorFName &&
                                !directorvalueData?.directorLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="directorEmail"
                        autoComplete="off"
                        value={directorvalueData?.directorEmail}
                        className="nb-input"
                        onChange={handledirInputChange}
                        isInvalid={
                          !!validationError?.directorEmail ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorEmail ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="directorPhNo"
                        value={directorvalueData?.directorPhNo}
                        className="nb-input"
                        onChange={handledirInputChange}
                        isInvalid={
                          !!validationError?.directorPhNo ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.directorPhNo ||
                          directorvalueData?.directorMdId &&
                          !directorvalueData?.directorPhNo && (
                            <p>Phone Number cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="directorPhNoExt"
                        value={directorvalueData?.directorPhNoExt}
                        className="nb-input"
                        onChange={handledirInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="directorSqcnY"
                        className="contactradio"
                        data-testid="directorSqcnY"
                        disabled={!directorvalueData?.directorFName}
                        value="Yes"
                        checked={directorvalueData?.directorSqcnY === "Yes"}
                        defaultChecked={directorvalueData?.directorSqcnY === "Yes"}
                        onChange={(e)=>{
                          handledirInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setPhsicianvalueData({...physicianValueData,physicianSqcnY:"No",physicianSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="directorSqcnY"
                        data-testid="directorSqcnY"
                        checked={directorvalueData?.directorSqcnY === "No"}
                        defaultChecked={directorvalueData?.directorSqcnY === "No"}
                        onChange={handledirInputChange}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("director"),
                            setFieldValue("directorMdId", "");
                          setFieldValue("directorFName", "");
                          setFieldValue("directorLName", "");
                          setFieldValue("directorEmail", "");
                          setFieldValue("directorPhNo", "");
                          setFieldValue("directorPhNoExt", "");
                          setFieldValue("directorSqcnY", "No");
                          setReInitialize(false)
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Director</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6 className="contact-tilte" style={{ opacity: "0.2" }}>
                  Executive/Leadership Point of Contact{" "}
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="right"
                    overlay={popover3}
                  >
                    <img
                      src={tooltipIcon}
                      alt="Tooltip Icon"
                      className="tooltip-icon"
                    />
                  </OverlayTrigger>
                </h6>
                <div className="contact-section" style={{ backgroundColor: "#fff" }}>
                  <div>
                    <Row style={{ opacity: "0.2" }}>
                      <Col md={4}>
                        <p className="details-label">
                          {labels.NAME}{" "}
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            placement="bottom"
                            overlay={popover4}
                          >
                            <img
                              src={ReadonlyIcon}
                              alt="Tooltip Icon"
                              className="tooltip-icon"
                            />
                          </OverlayTrigger>
                        </p>
                        {executive?.firstName ? (
                          <p
                            className="contact-details"
                            data-testid="ExecutiveName"
                          >
                            {executive?.firstName}&nbsp;
                            {executive?.lastName}
                          </p>
                        ) : (
                          <p
                            className="contact-details"
                            data-testid="noExecutiveName"
                          >
                            --
                          </p>
                        )}
                      </Col>
                      <Col md={4}>
                        <p className="details-label">
                          {labels.EMAIL_ADDRESS}{" "}
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            placement="bottom"
                            overlay={popover4}
                          >
                            <img
                              src={ReadonlyIcon}
                              alt="Tooltip Icon"
                              className="tooltip-icon"
                            />
                          </OverlayTrigger>
                        </p>
                        {executive?.email[0]?.emailAddress ? (
                          <p
                            className="contact-details"
                            data-testid="ExecutiveEmail"
                          >
                            {executive?.email[0]?.emailAddress}
                          </p>
                        ) : (
                          <p
                            className="contact-details"
                            data-testid="noExecutiveEmail"
                          >
                            --
                          </p>
                        )}
                      </Col>
                      <Col md={3}>
                        <p className="details-label">
                          {labels.DIRECT_PHONE_NUMBER}{" "}
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            placement="bottom"
                            overlay={popover4}
                          >
                            <img
                              src={ReadonlyIcon}
                              alt="Tooltip Icon"
                              className="tooltip-icon"
                            />
                          </OverlayTrigger>
                        </p>
                        {executive?.phoneNumber[0]?.number ? (
                          <p
                            className="contact-details"
                            data-testid="ExecutivePhone"
                          >
                            {executive?.phoneNumber[0]?.number}
                          </p>
                        ) : (
                          <p
                            className="contact-details"
                            data-testid="noExecutivePhone"
                          >
                            --
                          </p>
                        )}
                      </Col>
                      <Col md={1}>
                        <p className="details-label">
                          {labels.EXT}{" "}
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            placement="bottom"
                            overlay={popover4}
                          >
                            <img
                              src={ReadonlyIcon}
                              alt="Tooltip Icon"
                              className="tooltip-icon"
                            />
                          </OverlayTrigger>
                        </p>
                        {executive?.phoneNumber[0]?.Extension ? (
                          <p
                            className="contact-details"
                            data-testid="ExecutivePhone"
                          >
                            {executive?.phoneNumber[0]?.Extension}
                          </p>
                        ) : (
                          <p
                            className="contact-details"
                            data-testid="noExecutivePhone"
                          >
                            --
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                <h6 className="contact-tilte">Physician Champion</h6>
                <div className="contact-section">
                  <Row>
                    <Col md={4}>
                      <p className="details-label">{labels.MDOFFICE_ID}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="PhysicianMdId"
                        autoComplete="off"
                        value={physicianValueData?.PhysicianMdId}
                        className="nb-input"
                        onChange={(e) => {
                          handleMdOfficeIdChange(e, "pc", 8);
                          setReInitialize(true);
                          handlephyInputChange(e);
                        }}
                        isInvalid={!!validationError?.PhysicianMdId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.PhysicianMdId}
                      </Form.Control.Feedback>
                      {physicianValueData?.PhysicianFName && !physicianValueData?.PhysicianMdId && (
                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                          MDOffice ID cannot be blank
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <p className="details-label">{labels.FIRST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="PhysicianFName"
                            autoComplete="off"
                            value={physicianValueData?.PhysicianFName}
                            className="nb-input"
                            onChange={handlephyInputChange}
                            isInvalid={
                              !!validationError?.PhysicianFName ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianFName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.PhysicianFName ||
                              physicianValueData?.PhysicianMdId &&
                              !physicianValueData?.PhysicianFName && (
                                <p>First Name cannot be blank</p>
                              )}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={6}>
                          <p className="details-label">{labels.LAST_NAME}</p>
                          <Form.Control
                            type="text"
                            placeholder="---"
                            id="PhysicianLName"
                            autoComplete="off"
                            value={physicianValueData.PhysicianLName}
                            defaultValue={values.PhysicianLName}
                            className="nb-input"
                            onChange={handlephyInputChange}
                            isInvalid={
                              !!validationError?.PhysicianLName ||
                              (physicianValueData?.PhysicianMdId &&
                                !physicianValueData?.PhysicianLName) ||
                              (physicianValueData?.PhysicianFName &&
                                !physicianValueData?.PhysicianLName)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationError?.PhysicianLName ||
                              (physicianValueData?.PhysicianMdId &&
                                !physicianValueData?.PhysicianLName) ||
                              (physicianValueData?.PhysicianFName &&
                                !physicianValueData?.PhysicianLName) ? (
                              <p>Last Name cannot be blank</p>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <p className="details-label">{labels.EMAIL_ADDRESS}</p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        id="PhysicianEmail"
                        autoComplete="off"
                        value={physicianValueData?.PhysicianEmail}
                        className="nb-input"
                        onChange={handlephyInputChange}
                        isInvalid={
                          !!validationError?.PhysicianEmail ||
                          physicianValueData?.PhysicianMdId &&
                          !physicianValueData?.PhysicianEmail
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.PhysicianEmail ||
                          physicianValueData?.PhysicianMdId &&
                          !physicianValueData?.PhysicianEmail && (
                            <p>Email cannot be blank</p>
                          )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <p className="details-label">
                        {labels.DIRECT_PHONE_NUMBER}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="physicianPhNo"
                        value={physicianValueData?.physicianPhNo}
                        className="nb-input"
                        onChange={handlephyInputChange}
                        isInvalid={
                          !!validationError?.physicianPhNo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError?.physicianPhNo && (
                            <p>{validationError?.physicianPhNo}</p>
                        )}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={1}>
                      <p className="details-label">
                        {labels.EXT}
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="---"
                        autoComplete="off"
                        id="physicianPhNoExt"
                        value={physicianValueData?.physicianPhNoExt}
                        className="nb-input"
                        onChange={handlephyInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <p className="details-label mb-radio">
                        {labels.PRIMARY_SQCN_CONTACT}
                      </p>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        id="physicianSqcnY"
                        className="contactradio"
                        data-testid="physicianSqcnY"
                        disabled={!physicianValueData?.PhysicianFName}
                        value="Yes"
                        checked={physicianValueData?.physicianSqcnY === "Yes"}
                        defaultChecked={physicianValueData?.physicianSqcnY === "Yes"}
                        onChange={(e)=>{
                          handlephyInputChange(e);
                          setFieldValue("practiceManagerSqcnY", "No");
                          setAdditionalPocValuesData({...additionalPocValuesData,additionalSqcnY:"No",additionalSqcnN:"Yes"})
                          setDirectorValueData({...directorvalueData,directorSqcnY:"No",directorSqcnN:"Yes"});
                        }}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="No"
                        id="physicianSqcnY"
                        data-testid="additionalSqcnY"
                        checked={physicianValueData?.physicianSqcnY === "No"}
                        defaultChecked={physicianValueData?.physicianSqcnY === "No"}
                        onChange={handlephyInputChange}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <button
                        type="button"
                        className="Remove_data_button"
                        onClick={() => {
                          handleRemoveData("pc"),
                            setFieldValue("PhysicianMdId", "");
                          setFieldValue("PhysicianFName", "");
                          setFieldValue("PhysicianLName", "");
                          setFieldValue("PhysicianEmail", "");
                          setFieldValue("physicianPhNo", "");
                          setFieldValue("physicianPhNoExt", "");
                          setFieldValue("physicianSqcnY", "No");
                          setReInitialize(false)
                        }}
                      >
                        <img src={userRemoveIcon} alt="Remove Icon" />
                        <span className="text_remove">Remove  Physician Champion</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                  {errorMessage!=="" && (
                      <div>
                        <Alert variant="danger" data-testid="error-server">
                          <Container>
                            <p className="mb-0" style={errorStyle}>
                              <img src={icon} alt="error-icon" className="error-icon"></img>{" "}
                              {errorMessage}
                            </p>
                          </Container>
                        </Alert>
                      </div>
                  )}
                <div className="add-location-footer">
                  {contactInfoError && (
                    <p className="Error-message">
                      {contactInfoError}
                    </p>
                  )}
                  <p data-testid="providerStartDateconfirmText">
                    Please confirm that all information is correct
                  </p>
                  <div className="add-location-buttons">
                    <button
                      className="white-btn"
                      data-testid="EditCancelButton"
                      onClick={() => setEditModeBox(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="blue-btn"
                      type="submit"
                      data-testid="EditConfirmButton"
                      value="submit"
                      disabled={
                        values?.practiceManagerName && !values?.practiceManagerMdId ||
                        values?.practiceManagerMdId && !values?.practiceManagerName ||
                        values?.practiceManagerMdId && !values?.practiceManagerLName ||
                        values?.practiceManagerMdId && !values?.practiceManagerEmail ||
                        values?.practiceManagerMdId && !values?.practiceManagerPhNo ||
                        additionalPocValuesData?.additionalFName && !additionalPocValuesData?.additionalMdId ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalFName ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalLName ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalEmail ||
                        additionalPocValuesData?.additionalMdId && !additionalPocValuesData?.additionalPhNo ||
                        directorvalueData?.directorFName && !directorvalueData?.directorMdId ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorFName ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorLName ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorEmail ||
                        directorvalueData?.directorMdId && !directorvalueData?.directorPhNo ||
                        physicianValueData?.PhysicianFName && !physicianValueData?.PhysicianMdId ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianFName ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianLName ||
                        physicianValueData?.PhysicianMdId && !physicianValueData?.PhysicianEmail
                      }
                    >
                      Confirm and Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )
      }
    </React.Fragment >
  );
}

export default ContactInformation;
